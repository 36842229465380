import React, { useEffect, useState, useCallback } from "react";
import { FooterDesktop } from "../../../desktopcomponents/FooterDesktopMain/FooterDesktop";
import { NavbarDesktop } from "../../../desktopcomponents/NavBarDesktopMain";
import "./style.css";

export const LoyaltyDesktop = () => {
  const [mediaPlayed, setMediaPlayed] = useState(false); // <-- Define mediaPlayed using useState


  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);

  useEffect(() => {
    // Array of class names for the videos
    const videoClassNames = [".frame-4", ".group-9", ".overlap-7", ".component-3", ".group-2", ".frame-lock", ".component-4"];

    videoClassNames.forEach(className => {
      const videoPlayer = document.querySelector(className);

      function loadMedia() {
        if (mediaPlayed) return;
        videoPlayer.style.display = "inline";
        videoPlayer.play();
        setMediaPlayed(true);
      }

      videoPlayer.oncanplay = loadMedia;

      videoPlayer.addEventListener('ended', function () {
        // Handle what happens when the video ends, if needed
      });
    });

  }, [mediaPlayed]);


  return (
    <div className="Loyalty-d">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/desktophero.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarDesktop onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="overlap-6">
          <div className="text-block">
            <div className="HT">
              <div className="a-SIMPLE">OFFER</div>
              <div className="FREE-SIMPLE">LIFETIME DISCOUNTS</div>
              <p className="payment-solution-you">
                <span className="a-SIMPLE">TO RETAIN CUSTOMERS &{"  "}</span>
                <span className="a-SIMPLE-2">PREDICT REVENUE</span>
              </p>
            </div>
            <p className="p">
              Customers love once off discounts, which is good for a quick sale but it invites a lot of short term
              customers that ramps up voluntary churn. It’s time to offer compounded discounts to customers that need
              your subscription products or services, to build long term customer relationships.
            </p>
          </div>
          <img className="element-of" alt="Element of" src="/img/TP5.webp" />
        </div>
      </div>

      <div className="section">
        <div className="TEXT-BLOCK">
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">ONE</div>
              <p className="text-wrapper-16">ELEVATE LOYALTY INSTANTLY - INTEGRATE WITH 1 CLICK</p>
            </div>
            <p className="text-wrapper-17">
              Elevate customers' lifetime value, retention, and renewals effortlessly. Activate a dynamic loyalty program on any recurring payment(subscription plan).
              Extend lifetime discounts that compound with each billing, motivating customers to remain subscribed for amplified discounts over time. As they continue, their savings grow.
              The power of loyalty, simplified.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">TWO</div>
              <p className="text-wrapper-16">A WIN-WIN FOR CUSTOMERS & MERCHANTS</p>
            </div>
            <p className="text-wrapper-17">
              Empower substantial savings with an innovative loyalty program. The discount calculator showcases daily, weekly, monthly, or yearly savings for customers' clarity.
              Nurture loyalty through increasing discounts, resulting in amplified revenue and decreased acquisition expenses.
              Embark on a journey towards elevated success and customer loyalty today!
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">THREE</div>
              <p className="text-wrapper-16">ENHANCE REVENUE FORECASTING - DISCOUNT CALCULATOR UNLEASHED</p>
            </div>
            <p className="text-wrapper-17">
              Experience enhanced revenue forecasting through the Xion Pay discount calculator.
              Configure subscription discounts to elevate revenue and effortlessly handle customer payments.
              Amplify visibility into committed and potential revenue streams, elevate cash flow management, and eliminate forecasting complexities.
              Elevate financial foresight with ease.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">FOUR</div>
              <div className="text-wrapper-16">ELEVATE SAVINGS AT EVERY LEVEL - SUBSCRIPTION DISCOUNT TIERS</div>
            </div>
            <p className="text-wrapper-17">
              Elevate the savings journey with six dynamic discount tiers. The more XGT held in a customers wallet, the larger the discounts on recurring billing payments.
              Secure a path to continuous compounded subscription discounts for customers by allowing them to maintain an ample XGT balance with more currencies coming soon.
              Let customers' experience a realm of savings today.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">FIVE</div>
              <p className="text-wrapper-16">AMPLIFY YOUR CUSTOMERS' SAVINGS ON EVERY RECURRING BILL</p>
            </div>
            <p className="div-8">
              <span className="text-wrapper-17">
                Here's how it enhances the customer journey:
                <br />
                <br />
                Direct customers to the “Loyalty Program” section within at Xion Pay.
                <br />
                Empower them to tailor the recurring billing charge discounts to their preferences.
                <br />
                Encourage customers' to take action by “
              </span>
              <span className="text-wrapper-119">Obtaining XGT with USDT</span>
              <span className="text-wrapper-17">
                ” and activate the selected loyalty program tier.
                <br />
                Guide customers to maintain a steady XGT balance for a continuous cascade of growing discounts.
              </span>
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">SIX</div>
              <div className="text-wrapper-16">SAVINGS MADE EFFORTLESS - ACTIVATE XGT FOR ONGOING DISCOUNTS</div>
            </div>
            <p className="text-wrapper-17">
              Witness the magic of effortless savings through activated XGT levels. Your customers' journey to discounts begins upon activation.
              As recurring payments occur, XGT will automatically flow, triggering compounded discounts.
              Embrace a system that simplifies savings and drives loyalty effortlessly.
            </p>
          </div>
        </div>
        <video className="frame-4" autoplay muted loop src="/img/Flow13w.webm" type="video/webm">
        </video>

        <div className="group-9">
          <video className="group-2" autoplay muted loop src="/img/jarv2.webm" type="video/webm">
          </video>
        </div>
        <div className="overlap-wrapper">
          <video className="overlap-7" autoplay muted loop src="/img/calculatorv2.webm" type="video/webm">
          </video>
        </div>

        <video className="frame-lock" autoplay muted loop src="/img/Flow16w.webm" type="video/webm">
        </video>
        <div className="component-instance">
          <video className="component-4" autoplay muted loop src="/img/Flow17w.webm" type="video/webm">
          </video>
        </div>
        <video className="component-3" autoplay muted loop src="/img/Flow18w.webm" type="video/webm">
        </video>
      </div>
      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">Explore guides and integration examples in Xion </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")}
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">We’re here to help! If you have technical issues, </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://intercom.help/xionpay/en/")}
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-4" alt="Line" src="/img/desktop-line-23.svg" />
      </div>
      <FooterDesktop onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
