import React, { useState, useCallback } from "react";
import { NavbarIpad } from "../../../ipadcomponents/NavBarIpadMain";
import "./style.css";

export const ErrorPageIpad = () => {
  const [mediaPlayed, setMediaPlayed] = useState(false); // <-- Define mediaPlayed using useState

  const handleGoBackClick = () => {
    window.history.back(); // Go back to the previous page
  };

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);


  return (
    <div className="error-i">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/404-iPad.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarIpad onGetStartedClick={handleGetStartedClick} />
      <div className="main-3">
        <div className="overlap-12">
          <div className="group-4">
            <div className="gummy-rayman-wrapper">
              <img
                className="gummy-rayman"
                alt="Gummy rayman"
                src="/img/gummy-rayman-adventures-sebastien-camrrubi-removebg-preview-2.webp"
              />
            </div>
          </div>
          <div className="ABOUT-US-3">OOPS!!!</div>
          <div className="go-back-instance" onClick={handleGoBackClick}>
            <div className="go-back-2-text">GO BACK</div>
          </div>
          <p className="text-wrapper-31">The page you are looking for seems to have been lost.</p>
          <div className="text-wrapper-32">404 ERROR</div>
        </div>
      </div>
    </div>
  );
};
