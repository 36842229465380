import React, { useCallback } from "react";
import { FooterMobile } from "../../../mobilecomponents/FooterMobileMain";
import { NavbarMobile } from "../../../mobilecomponents/NavBarMobileMain";
import "./style.css";

export const CheckoutBM = () => {

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);

  const handlePaymentLinksClick = () => {
    // Add the logic to perform when the button is clicked
    // For example, you can use window.location.href to navigate to a URL
    window.location.href = '/payment-links'; // Replace with the actual URL
  };

  const handleHostedCheckoutClick = () => {
    // Add the logic to perform when the button is clicked
    // For example, you can use window.location.href to navigate to a URL
    window.location.href = '/hosted-checkout'; // Replace with the actual URL
  };


  return (
    <div className="CheckoutB-m">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/mobilehero.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer-1.png" />
      <NavbarMobile onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="text-block">
          <div className="HT">
          <div className="a-SIMPLE">CREATE A</div>
            <div className="FREE-SIMPLE" > CHECKOUT BUTTON</div>
            <div className="payment-solution-you">AND SELL ANYWHERE</div>
          </div>
          <p className="p">
              Integrate Xion Global's Checkout Buttons to offer a smooth crypto payment experience directly on your
              online store or app. With just two lines of code, you can start accepting a variety of cryptocurrencies,
              enhancing your global reach.
          </p>
        </div>
      </div>
      <div className="section">
        <div className="TEXT-BLOCK">
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-16">ACCEPT PAYMENTS IN MINUTES, YOUR WAY GLOBALLY</div>
            </div>
            <p className="text-wrapper-17">

            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">CHECKOUT BUTTON</div>
              <p className="text-wrapper-16">FEATURES</p>
            </div>
            <div>
            <p className="text-wrapper-17">
                Embed checkout buttons with just 2 lines of code.<br />
                Tailor the look and feel of the buttons to match your brand.<br />
                Benefit from Xion's robust security measures, ensuring safe and reliable transactions.<br />
                Accept a wide range of cryptocurrencies, including USDT (Polygon), XGT, ETH, BTC, and more.
              </p>
</div>
</div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">CHECKOUT BUTTON</div>
              <div className="text-wrapper-16">GETTING STARTED</div>
            </div>
            <p className="text-wrapper-17">
                Create your product on Xion's platform.<br />
                Find your product on the checkout button page.<br />
                Integrate the checkout buttons onto your online store.<br />
                Start accepting crypto payments seamlessly!
              </p>
</div>
          <div className="TEXT-a">
            <div className="frame-3">
              <div className="text-wrapper-15">PICK YOUR PATH</div>
              <p className="text-wrapper-16">OTHER OPTIONS TO START ACCEPTING PAYMENTS</p>
            </div>
            <p className="text-wrapper-17-n">
              All of Xion's payment UIs give you instant access to over 27+ networks and 100+ tokens.
              Choose the integration that best suits your business.
            </p>
          </div>
          </div>
          </div>

        <img className="PL" alt="pl" src="/img/paymentlinks.png" />
        <div className="text-containera">
          <div className="TEXTa">
            <div className="frame-3a">
              <div className="text-wrapper-15a">CHECKOUT</div>
              <div className="text-wrapper-16a">Hosted Checkout</div>
              <div className="text-wrapper-44">A pre-built checkout optimised for conversions </div>
            </div>
            <div className="text-wrapper-17a">
              <ul className="bullet-list">
              <li>Xion-hosted checkout page for payments & subscriptions</li>
                <li>Pre-built features to maximize revenue on your website</li>
                <li>Available globally, no limits</li>
              </ul>
            </div>
          </div>
</div>
        <img className="HCC" alt="hcc" src="/img/hostedcheckout.png" />
        <div className="text-container-b">
          <div className="TEXT-b">
            <div className="frame-3b">
              <div className="text-wrapper-15b">PAYMENT LINKS</div>
              <div className="text-wrapper-16b">Shareable payment links</div>
              <div className="text-wrapper-444">The fastest way to accept payments - no code required</div>

            </div>
            <div className="text-wrapper-17b">
              <ul className="bullet-list">
              <li>Share links over email, SMS or any other channel</li>
    <li>Accept one-time, recurring, or pay-what-you-want payments</li>
    <li>Turn your link into a QR code or an embeddable buy button</li>
              </ul>
            </div>
          </div>
</div>
        <div className="GS-button-2" onClick={handlePaymentLinksClick}>
          <div className="GS-button-2-text">Explore Links</div>
        </div>
        <div className="GS-button" onClick={handleHostedCheckoutClick}>
          <div className="GS-button-text">Explore Checkout</div>
        </div>
      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">Explore guides and integration examples in Xion </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")}
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">We’re here to help! If you have technical issues, </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://intercom.help/xionpay/en/")}
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-2" alt="Line" src="/img/line-23-mobile.svg" />
      </div>
      <FooterMobile onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
