import React, { useEffect, useState, useCallback } from "react";
import { FooterIpad } from "../../../ipadcomponents/FooterIpadMain";
import { NavbarIpad } from "../../../ipadcomponents/NavBarIpadMain";
import "./style.css";

export const PWCI = () => {

  const images = [
    "/img/imagetakealot.png",
    "/img/imageshell.png",
    "/img/imagesnapscan.png",
    "/img/imagecheckers.png",
    "/img/imageclicks.png",
    "/img/imageshoprite.png",
    "/img/imagemakro.png",
    "/img/imageengen.png",
    "/img/imagepnppng.png",
    "/img/imagebidvest.png",
    "/img/imagehowler.png",
    "/img/imagenespresso.png",
  ];

  // State for the starting index of the displayed images
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartIndex(prevIndex => (prevIndex + 3) % images.length);
    }, 3000); // Change group of images every 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [images.length]);

  // Function to get the class name based on position
  const getImageClassName = (position) => {
    if (position === 0) return "imagetakealot";
    if (position === 1) return "imageshell";
    if (position === 2) return "imagesnapscan";
  };

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);

  // Define the onClick event handler function
  const handleDownloadClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xion.global/Xion_Global_Pay_With_Crypto.pdf", "_blank");
  }, []);

  return (
    <div className="PWC-i">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/ipadhero.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarIpad onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="overlap-6">
          <div className="text-block">
            <div className="HT">
              <div className="a-SIMPLE">PAY WITH CRYPTO</div>
              <div className="FREE-SIMPLE" >AT OVER 600k+ MERCHANTS</div>
              <div className="payment-solution-you"></div>
            </div>
            <p className="p">
              Experience the future of transactions with Xion's cutting-edge Web3 payments infrastructure.
              Leveraging the robustness of blockchain technology, we offer a seamless, secure, and transparent payments experience through selected partners.
              <br />
              Enjoy substantially lower transaction fees compared to conventional payment methods, allowing both users and merchants to maximize savings and efficiency.
            </p>
          </div>
          <div className="GS-button-3" onClick={handleDownloadClick}>
            <div className="GS-button-3-text">Download The PDF</div>
          </div>
          <img className="element-of" alt="Element of" src="/img/pay-crypto-mobileimage.png" />
        </div>
      </div>
      <div className="section">
        <div className="TEXT-BLOCK">
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">WHY CRYPTO</div>
              <div className="text-wrapper-16">WHY CRYPTO PAYMENTS?</div>
            </div>
            <p className="text-wrapper-17">
              Faster, lower fees, enhanced security & more incentives.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">DOWNLOAD A WALLET</div>
              <p className="text-wrapper-16">HOW TO DOWNLOAD METAMASK</p>
            </div>
            <p className="text-wrapper-17">
              1. Go to <a href="https://metamask.io/" target="_blank" className="metamask-link">Metamask.io</a>.
              <br />
              2. Choose your device and click Download.
              <br />
              3. Follow on-screen instructions to add the wallet.
              <br />
              4. Open app, create a new wallet and secure it with a password.
              <br />
              <br />
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">GETTING USDT</div>
              <div className="text-wrapper-16">SWAPPING & BUYING CRYPTO FOR USDT ON POLYGON</div>
            </div>
            <p className="text-wrapper-17">
              <span className="text-wrapper-5">
                1. In Metamask, switch the network to Polygon.
                <br />
                2. Deposit ETH or MATIC to your Metamask.
                <br />
                3. Use a DEX like{" "}
              </span>
              <a href="https://xion.finance/" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-6">Xion Finance</span>
              </a>
              <span className="text-wrapper-5"> to exchange for </span>
                <span className="text-wrapper-6">USDT</span>

                <br />
                <br />
                <span className="text-wrapper-5">
                1. Head to Xion Finance <a href="https://www.xion.finance/" rel="noopener noreferrer" target="_blank">"BUY"</a>
                <br />
                2. Fill amount in USD value and complete steps.
                <br />
                3. Provide wallet address to recieve USDT on Polygon.
              </span>

            </p>
          </div>

          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">PAY WITH CRYPTO</div>
              <p className="text-wrapper-16">MAKE PAYMENTS WITH CALLPAY</p>
            </div>
            <p className="text-wrapper-17">
               1. Select the crypto payment option at any supported <a href="https://www.callpay.com/" target="_blank" className="scan-to-pay-link">Callpay</a> checkouts.
              <br />
              2. Connect your web3 wallet
              <br />
              3. Click “Enable USDt” and approve the USDt transaction in your web3 wallet
              <br />
              4. Click “Pay” and purchase successful
              <br />
              <br />
              Future USDt payments will now be 1-click.
              <br/><br/>
              <span className="text-wrapper-9s">
              NB: You need to have MetaMask or any compatible web3 wallet downloaded on your desktop or mobile phone in order to use the crypto payment option.
              </span>
              <span className="text-wrapper-4"></span>
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">PAY WITH CRYPTO</div>
              <p className="text-wrapper-16">MAKE PAYMENTS WITH SCAN TO PAY</p>
            </div>
            <p className="text-wrapper-17">
              1. Scan a QR code at any supported <a href="https://www.scantopay.io/" target="_blank" className="scan-to-pay-link">Scan to Pay</a> merchants and select the crypto payment option.
              <br />
              2. Connect your web3 wallet 
              <br />
              3. Click “Enable USDt” and approve the USDt transaction in your web3 wallet
              <br />
              4. Confirm the payment amount in the app.
              <br />
              5. Click “Pay” and purchase successful
              <br />
              <br />
               Future USDt payments will now be 1-click.
               <br/><br/>
              <span className="text-wrapper-9s">
              NB: You need to have both the Scan to Pay and MetaMask app (any compatible web3 wallet) downloaded on your mobile phone in order to use the crypto payment option.
              </span>
              <span className="text-wrapper-4"></span>
            </p>
          </div>
          <img className="frame-4" alt="frame4" src="/img/pwc_metamask_logo.png" />
          <img className="group-9" alt="Asset" src="/img/pwc_tether&polygonlogo.png" />
          <div className="overlap-wrapper">
            <img className="overlap-7" alt="Asset" src="/img/pwc_scantopaylogo.png" />
          </div>
          <img className="overlap-77" alt="Asset" src="/img/callpay.png" />
        </div>

        <div className="section-2s">
          <div className="overlap-2s">
            <p className="text-wrapper-8s">
              {/* <a href="https://xion.global/Scan_to_Pay_Merchants.pdf" target="_blank" className="full-list-link">VIEW THE FULL LIST HERE</a> */}
            </p>

            {images.slice(startIndex, startIndex + 3).map((src, index) => (
        <img key={src} className={getImageClassName(index)} alt={`Slide ${index}`} src={src} />
      ))}
      
          </div>
          {/* <img className="roadshow" alt="Asset" src="/img/roadshow.png" /> */}

          <p className="FREE-SIMPLE-2s">SUPPORTED MERCHANTS</p>
        </div>
        <div className="section-3s">
          <p className="what-is-scan-to-pay">
            <span className="text-wrapper-9s">
            What is Xion Global?
              <br />
            </span>
            <span className="text-wrapper-10s">
            Xion Global is cutting-edge Web3 payments infrastructure that leverages blockchain technology to provide a seamless, secure, and transparent payments experience. 
            Our technology allows for substantially lower transaction fees and maximizes savings and efficiency for both users and merchants.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
            How can I start using Xion Global for payments?
              <br />
            </span>
            <span className="text-wrapper-10s">
            To start, simply download a compatible Web3 wallet, such as MetaMask, and fund it with cryptocurrency. 
            Connect your wallet at checkout to enjoy secure and efficient transactions across our vast network of merchants.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
              What cryptocurrencies are supported?
              <br />
            </span>
            <span className="text-wrapper-10s">
            Initially, we support USDT on the Polygon network, with plans to expand our offerings. Stay tuned for updates on additional supported cryptocurrencies.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
              How secure are these transactions?
              <br />
            </span>
            <span className="text-wrapper-10s">
            Yes, Xion Global employs multiple layers of security including AML, KYC, KYB, and KYT to ensure that all transactions are safe and secure.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
              What are the phases of implementation?
              <br />
            </span>
            <span className="text-wrapper-10s">
              The rollout will happen in three phases, each introducing new features and payment options.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
              How do I set up Metamask?
              <br />
            </span>
            <span className="text-wrapper-10s">
              Metamask can be downloaded from its official website and set up within minutes.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
              What are the transaction fees?
              <br />
            </span>
            <span className="text-wrapper-10s">
              Transaction fees are minimal and can vary, they are clearly stated within checkout.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
              Is customer support available?
              <br />
            </span>
            <span className="text-wrapper-10s">
            We offer 24/7 customer support to assist with any questions or issues you might have. Our team is dedicated to providing swift and comprehensive assistance.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
              Are there any rewards or loyalty programs?
              <br />
            </span>
            <span className="text-wrapper-10s">
              Yes, future phases will introduce cashback rewards and loyalty programs.
              <br />
              <br />
            </span>
            <span className="text-wrapper-9s">
            How can I integrate Xion Global's services into my business?
              <br />
            </span>
            <span className="text-wrapper-10s">For businesses looking to integrate Xion Global, please refer to our <a href="https://www.apimatic.io/api-docs/xionglobal" target="_blank" rel="noopener noreferrer">documentation</a> for guides and examples. 
            Our technical support team is also available to assist with integration and any queries you may have.
            </span>
          </p>
          <div className="FREE-SIMPLE-3s">FAQ’S</div>
        </div>

      </div>
      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">Explore guides and integration examples in Xion </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")}
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">We’re here to help! If you have technical issues, </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://intercom.help/xionpay/en/")}
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-4" alt="Line" src="/img/line-23-ipad.svg" />
      </div>
      <FooterIpad onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
