import React from "react";
import { useCallback } from "react";
import { CsButton } from "../CsButtonIpad";
import { GsButton } from "../GsButtonIpad";
import { Telegram } from "../../screens/IpadMenuMain/components/Telegram";
import { Twitter } from "../../screens/IpadMenuMain/components/Twitter";
import { Medium } from "../../screens/IpadMenuMain/components/Medium";
import { Linkedin } from "../../screens/IpadMenuMain/components/Linkedin";
import "./style.css"; // You can include any necessary styling here

export const FooterIpad = ({ onGetStartedClick }) => {
  // Define the URL for the "Platform" menu item
  const platformURL = "https://xionpay.app";
  const helpguideURL = "https://intercom.help/xionpay/en/";
  const contactsalesURL = "https://calendly.com/xionglobal";
  const documentationURL = "https://www.apimatic.io/api-docs/xionglobal";
  const blogURL = "https://xionglobal.medium.com/";

  // Define the onClick event handler function for the "Platform" menu item
  const handleHelpGuideClick = useCallback(() => {
    window.open(helpguideURL, "_blank");
  }, [helpguideURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handlePlatformClick = useCallback(() => {
    window.open(platformURL, "_blank");
  }, [platformURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handleContactSalesClick = useCallback(() => {
    window.open(contactsalesURL, "_blank");
  }, [contactsalesURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handleDocumentationClick = useCallback(() => {
    window.open(documentationURL, "_blank");
  }, [documentationURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handleBlogClick = useCallback(() => {
    window.open(blogURL, "_blank");
  }, [blogURL]);

  const handleSocialMediaClick = (url) => {
    console.log("handleSocialMediaClick called with URL:", url);
    window.open(url, "_blank");
  };

  return (
    <div className="if-container">
      <div className="start-if">
        <div className="overlap-group-2-if">
          <img
            className="rectangle-if"
            alt="Rectangle"
            src="/img/rectangle-1960-1.png"
          />
          <img
            className="gradient-if"
            alt="Gradient"
            src="/img/gradient-3.webp"
          />
          <img className="mouse-if" alt="Mouse" src="/img/mouse-2.png" />
        </div>
        <div>
          <div className="start-section-if">
            <div className="overlap-18-if">
              <img
                className="start-image-if"
                alt="Start image"
                src="/img/start-image-1.webp"
              />
              <div className="CTA-buttons-2-if">
                <GsButton property1="default" />
                <CsButton property1="default" onClick={onGetStartedClick} />
              </div>
              <div className="text-block-5-if">
                <div className="overlap-group-9-if">
                  <div className="text-wrapper-50-if">START</div>
                  <div className="text-wrapper-51-if">FREE TODAY!</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section-if">
          <img className="footer-if" alt="Footer if" src="/img/footer-1.png" />
          <div className="frame-6-if">
            <img
              className="XG-logo-if"
              alt="Xg logo if"
              src="/img/xg-logo-1.png"
            />
            <div className="sections-if">
              <div className="div-2-if">
                <div className="text-wrapper-52-if">PRODUCTS</div>
                <a
                  href="#Platform"
                  className="text-wrapper-53-if"
                  onClick={handlePlatformClick}
                >
                  Platform
                </a>
                <a href="/payment-links" className="text-wrapper-53-if">
                  Payment Links
                </a>
                <a href="/checkout-buttons" className="text-wrapper-53-if">
                  Checkout Buttons
                </a>
                <a href="/hosted-checkout" className="text-wrapper-53-if">
                  Hosted Checkout
                </a>
                <a href="/cashback-rewards" className="text-wrapper-53-if">
                  Cashback Rewards
                </a>
                <a href="/loyalty" className="text-wrapper-53-if">
                  Loyalty
                </a>
                <a href="/pay-with-crypto" className="text-wrapper-53-if">
                  Pay With Crypto
                </a>
              </div>
              <div className="div-2-if">
                <div className="text-wrapper-52-if">COMPANY</div>
                <a href="/about-us" className="text-wrapper-53-if">
                  About Us
                </a>
                <a href="/become-a-partner" className="text-wrapper-53-if">
                  Become a Partner
                </a>
              </div>
              <div className="div-2-if">
                <div className="text-wrapper-52-if">RESOURCES</div>
                <a
                  href="#Blog"
                  className="text-wrapper-53-if"
                  onClick={handleBlogClick}
                >
                  Blog
                </a>
                <a
                  href="#HelpGuide"
                  className="text-wrapper-53-if"
                  onClick={handleHelpGuideClick}
                >
                  Help Guide
                </a>
                <a
                  href="#Documentation"
                  className="text-wrapper-53-if"
                  onClick={handleDocumentationClick}
                >
                  Documentation
                </a>
                <a href="/contact-us" className="text-wrapper-53-if">
                  Contact Us
                </a>
                <a
                  href="#ContactSales"
                  className="text-wrapper-53-if"
                  onClick={handleContactSalesClick}
                >
                  Contact Sales
                </a>
                <a href="/privacy-terms" className="text-wrapper-53-if">
                  Privacy &amp; Terms
                </a>
                <a
                  href="/sitemap.xml"
                  className="text-wrapper-53-if"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sitemap
                </a>
              </div>
              <div className="follow-us-if">
                <div className="follow-us-2-if">
                  <div className="address-wrapper-if">
                    <div className="address-if">
                      20a Tanjong Pagar Road
                      <br />
                      Singapore
                      <br />
                      088443
                    </div>
                    <div className="address-if">
                      69 Spencer Avenue, Chartwell
                      <br />
                      Johannesburg, 2191
                      <br />
                      South Africa
                    </div>
                  </div>
                  <a
                    href="mailto:contact@xion.global"
                    className="text-wrapper-54-if"
                  >
                    contact@xion.global
                  </a>

                  <div className="social-if">
                    <Telegram
                      className="telegram-footer telegram-footer-instance-if"
                      property1="default"
                      propertyDefault="/img/telegramwhite.png"
                      onClick={() =>
                        handleSocialMediaClick("https://t.me/xionglobal")
                      }
                    />
                    <Twitter
                      className="twitter-footer twitter-footer-instance-if"
                      property1="default"
                      propertyDefault="/img/property-1-variant2-6.png"
                      onClick={() =>
                        handleSocialMediaClick("https://twitter.com/xionglobal")
                      }
                    />
                    <Medium
                      className="medium-footer medium-footer-instance-if"
                      property1="default"
                      propertyDefault="/img/property-1-variant2-5.png"
                      onClick={() =>
                        handleSocialMediaClick("https://xionglobal.medium.com")
                      }
                    />
                    <Linkedin
                      className="linkedin-footer linkedin-footer-instance-if"
                      property1="default"
                      propertyDefault="/img/property-1-variant2-3.png"
                      onClick={() =>
                        handleSocialMediaClick(
                          "https://www.linkedin.com/company/xionglobal"
                        )
                      }
                    />
                  </div>
                  <div className="text-wrapper-55-if">FOLLOW US</div>
                </div>
                <div className="t-cs-if">
                  <div className="overlap-group-10-if">
                    <div className="text-wrapper-56-if">©2023 Xion Global</div>
                    <div className="all-rights-reserved-if">
                      All Rights Reserved
                    </div>
                    <div className="license-if">
                      <a
                        href="https://www.fsca.co.za/Fais/Search_FSP.htm"
                        target="_blank"
                      >
                        Xion Global PTY LTD has applied for a Financial Service
                        Provider (FSP) & Crypto Asset Service Provider (CASP)
                        12/12/2023 FSP 53735
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
