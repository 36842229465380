import React from "react";
import { useCallback } from "react";
import { CsButton } from "../../desktopcomponents/CsButtonDesktop";
import { GsButton } from "../../desktopcomponents/GsButtonDesktop";
import { Telegram } from "../../screens/DesktopMenuMain/components/Telegram";
import { Twitter } from "../../screens/DesktopMenuMain/components/Twitter";
import { Medium } from "../../screens/DesktopMenuMain/components/Medium";
import { Linkedin } from "../../screens/DesktopMenuMain/components/Linkedin";
import "./style.css"; // You can include any necessary styling here

export const FooterDesktop = ({ onGetStartedClick }) => {
  // Define the URL for the "Platform" menu item
  const platformURL = "https://xionpay.app";
  const helpguideURL = "https://intercom.help/xionpay/en/";
  const contactsalesURL = "https://calendly.com/xionglobal";
  const documentationURL = "https://www.apimatic.io/api-docs/xionglobal";
  const blogURL = "https://xionglobal.medium.com/";

  // Define the onClick event handler function for the "Platform" menu item
  const handleHelpGuideClick = useCallback(() => {
    window.open(helpguideURL, "_blank");
  }, [helpguideURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handlePlatformClick = useCallback(() => {
    window.open(platformURL, "_blank");
  }, [platformURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handleContactSalesClick = useCallback(() => {
    window.open(contactsalesURL, "_blank");
  }, [contactsalesURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handleDocumentationClick = useCallback(() => {
    window.open(documentationURL, "_blank");
  }, [documentationURL]);

  // Define the onClick event handler function for the "Platform" menu item
  const handleBlogClick = useCallback(() => {
    window.open(blogURL, "_blank");
  }, [blogURL]);

  const handleSocialMediaClick = (url) => {
    console.log("handleSocialMediaClick called with URL:", url);
    window.open(url, "_blank");
  };
  return (
    <div className="df-container">
      <div className="start-df">
        <div className="overlap-group-2-df">
          <img
            className="rectangle-df"
            alt="Rectangle"
            src="/img/rectangle-1960.png"
          />
          <img
            className="gradient-df"
            alt="Gradient"
            src="/img/gradient.webp"
          />
          <img className="mouse-df" alt="Mouse" src="/img/mouse.png" />
        </div>
        <div>
          <div className="start-section-df">
            <img
              className="start-image-df"
              alt="Start image"
              src="/img/start-image.webp"
            />
            <div className="CTA-buttons-2-df">
              <GsButton property1="default" />
              <CsButton property1="default" onClick={onGetStartedClick} />
            </div>
            <div className="text-block-3-df">
              <div className="overlap-group-10-df">
                <div className="text-wrapper-49-df">START</div>
                <div className="text-wrapper-50-df">FREE TODAY!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section-df">
        <img className="footer-df" alt="Footer df" src="/img/footer.png" />
        <div className="frame-7-df">
          <img className="XG-logo-df" alt="Xg logo df" src="/img/xg-logo.png" />
          <div className="sections-df">
            <div className="div-4-df">
              <div className="text-wrapper-51-df">PRODUCTS</div>
              <a
                href="#Platform"
                className="text-wrapper-52-df"
                onClick={handlePlatformClick}
              >
                Platform
              </a>
              <a href="/payment-links" className="text-wrapper-52-df">
                Payment Links
              </a>
              <a href="/checkout-buttons" className="text-wrapper-52-df">
                Checkout Buttons
              </a>
              <a href="/hosted-checkout" className="text-wrapper-52-df">
                Hosted Checkout
              </a>
              <a href="/cashback-rewards" className="text-wrapper-52-df">
                Cashback Rewards
              </a>
              <a href="/loyalty" className="text-wrapper-52-df">
                Loyalty
              </a>
              <a href="/pay-with-crypto" className="text-wrapper-52-df">
                Pay With Crypto
              </a>
            </div>
            <div className="div-4-df">
              <div className="text-wrapper-51-df">COMPANY</div>
              <a href="/about-us" className="text-wrapper-52-df">
                About Us
              </a>
              <a href="/become-a-partner" className="text-wrapper-52-df">
                Become a Partner
              </a>
            </div>
            <div className="div-4-df">
              <div className="text-wrapper-51-df">RESOURCES</div>
              <a
                href="#Blog"
                className="text-wrapper-52-df"
                onClick={handleBlogClick}
              >
                {" "}
                Blog
              </a>
              <a
                href="#HelpGuide"
                className="text-wrapper-52-df"
                onClick={handleHelpGuideClick}
              >
                {" "}
                Help Guide
              </a>
              <a
                href="#Documentation"
                className="text-wrapper-52-df"
                onClick={handleDocumentationClick}
              >
                {" "}
                Documentation
              </a>
              <a href="/contact-us" className="text-wrapper-52-df">
                Contact Us
              </a>
              <a
                href="#ContactSales"
                className="text-wrapper-52-df"
                onClick={handleContactSalesClick}
              >
                {" "}
                Contact Sales
              </a>
              <a href="/privacy-terms" className="text-wrapper-52-df">
                Privacy &amp; Terms
              </a>
              <a
                href="/sitemap.xml"
                className="text-wrapper-52-df"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sitemap
              </a>
            </div>
            <div className="follow-us-df">
              <div className="follow-us-2-df">
                <div className="address-wrapper-df">
                  <div className="address-df">
                    20a Tanjong Pagar Road
                    <br />
                    Singapore
                    <br />
                    088443
                  </div>
                  <div className="address-df">
                    69 Spencer Avenue, Chartwell
                    <br />
                    Johannesburg, 2191
                    <br />
                    South Africa
                  </div>
                </div>
                <a
                  href="mailto:contact@xion.global"
                  className="text-wrapper-53-df"
                >
                  contact@xion.global
                </a>

                <div className="social-df">
                  <Telegram
                    className="telegram-footer telegram-footer-instance-df"
                    property1="default"
                    propertyDefault="/img/telegramwhite.png"
                    onClick={() =>
                      handleSocialMediaClick("https://t.me/xionglobal")
                    }
                  />
                  <Twitter
                    className="twitter-footer twitter-footer-instance-df"
                    property1="default"
                    propertyDefault="/img/property-1-variant2-6.png"
                    onClick={() =>
                      handleSocialMediaClick("https://twitter.com/xionglobal")
                    }
                  />
                  <Medium
                    className="medium-footer medium-footer-instance-df"
                    property1="default"
                    propertyDefault="/img/property-1-variant2-5.png"
                    onClick={() =>
                      handleSocialMediaClick("https://xionglobal.medium.com")
                    }
                  />
                  <Linkedin
                    className="linkedin-footer linkedin-footer-instance-df"
                    property1="default"
                    propertyDefault="/img/property-1-variant2-3.png"
                    onClick={() =>
                      handleSocialMediaClick(
                        "https://www.linkedin.com/company/xionglobal"
                      )
                    }
                  />
                </div>
                <div className="text-wrapper-54-df">FOLLOW US</div>
              </div>
              <div className="t-cs-df">
                <div className="overlap-group-11-df">
                  <div className="text-wrapper-55-df">©2023 Xion Global.</div>
                  <div className="all-rights-reserved-df">
                    All Rights Reserved
                  </div>
                  <div className="license-df">
                    <a
                      href="https://www.fsca.co.za/Fais/Search_FSP.htm"
                      target="_blank"
                    >
                      Xion Global PTY LTD has applied for a Financial Service
                      Provider (FSP) & Crypto Asset Service Provider (CASP)
                      12/12/2023 FSP 53735
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
