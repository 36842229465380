import React, { useState, useCallback } from "react";
import { FooterIpad } from "../../../ipadcomponents/FooterIpadMain";
import { NavbarIpad } from "../../../ipadcomponents/NavBarIpadMain";
import "./style.css";

export const LegalI = () => {
  const [mediaPlayed, setMediaPlayed] = useState(false); // <-- Define mediaPlayed using useState

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);


  return (
    <div className="legal-i">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/legal-i.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarIpad onGetStartedClick={handleGetStartedClick} />
      <div className="main-4">
        <div className="ABOUT-US-4">LEGAL INFO</div>
      </div>
      <div className="section-8">
        <div className="frame-22">
          <div className="text-wrapper-33">TERMS OF SERVICE</div>
          <p className="last-updated-2">
            LAST UPDATED: SEPTEMBER 22, 2021
            <br />
            <br />
            PURPLE ORIGIN LTD. (“PURPLE ORIGIN LTD” OR “WE” OR “US” OR “OUR”) MAKES AVAILABLE TO USERS THAT ARE
            MERCHANTS CERTAIN SOFTWARE SERVICES ACCESSIBLE VIA A MOBILE DEVICE OR WEB APPLICATION, INCLUDING COMMERCE
            (COMMONLY KNOWN AS XION GLOBAL) (THE “PAYMENT APPLICATION” OR “APP”). THE PAYMENT APPLICATION ENABLES USERS
            TO (I) ACCEPT CRYPTOCURRENCY AS A PAYMENT METHOD FOR GOODS AND SERVICES; (II) SELF CUSTODY DIGITAL ASSETS;
            (III) ACCESS REPORTING AND INVOICING CAPABILITIES; AND (IV) ADDITIONAL FUNCTIONALITY AS PURPLE ORIGIN LTD
            MAY ADD TO THE APP FROM TIME TO TIME (COLLECTIVELY THE “SERVICES”). PURPLE ORIGIN LTD DEVELOPED THESE TERMS
            OF SERVICE (THESE “TERMS”) TO DESCRIBE THE TERMS THAT GOVERN YOUR USE OF ALL VERSIONS OF THE PAYMENT
            APPLICATION. THESE TERMS AND ADDITIONAL INFORMATION ABOUT THE PAYMENT APPLICATION CAN BE FOUND ON THE XION
            GLOBAL WEBSITE LOCATED AT HTTPS://XION.APP/ASSETS/TERMS-OF-USE.PDF (THE “SITE”).
            <br />
            <br />
            AGREEMENT TO TERMS
            <br />
            BY CLICKING “I AGREE” OR BY ACCESSING THE PAYMENT APPLICATION OR USING ANY OR ALL OF THE SERVICES, YOU AGREE
            TO BE BOUND BY THESE TERMS. IF YOU DON’T AGREE TO BE BOUND BY THESE TERMS, YOU MAY NOT ACCESS OR USE THE
            SERVICES.
            <br />
            <br />
            PRIVACY POLICY
            <br />
            PLEASE REFER TO OUR XION GLOBAL PRIVACY POLICY FOR INFORMATION ON HOW WE COLLECT, USE AND DISCLOSE
            INFORMATION FROM OUR USERS. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICES IS SUBJECT TO, AND THAT
            WE CAN COLLECT, USE AND/OR DISCLOSE YOUR INFORMATION (INCLUDING ANY PERSONAL DATA YOU PROVIDE TO US) IN
            ACCORDANCE WITH OUR PRIVACY POLICY.
            <br />
            <br />
            CHANGES TO TERMS OR SERVICES
            <br />
            WE MAY MODIFY THE TERMS AT ANY TIME AT OUR SOLE DISCRETION. IF WE DO SO, WE’LL LET YOU KNOW EITHER BY
            POSTING THE MODIFIED TERMS ON THE SITE, BY PROVIDING YOU A NOTICE THROUGH THE APP, OR THROUGH OTHER METHODS
            OF COMMUNICATION WHICH WE DEEM REASONABLE. THE MODIFIED TERMS WILL BE EFFECTIVE AT THE TIME THEY ARE EITHER
            POSTED ON THE SITE, PROVIDED AS A NOTICE THROUGH THE APP, OR THROUGH OTHER METHODS OF COMMUNICATION WHICH WE
            DEEM REASONABLE, WHICHEVER IS EARLIEST, UNLESS WE INDICATE OTHERWISE. IT’S IMPORTANT THAT YOU REVIEW THE
            TERMS WHENEVER WE MODIFY THEM BECAUSE IF YOU CONTINUE TO USE THE SERVICES AFTER WE HAVE MODIFIED THE TERMS,
            YOU ARE AGREEING TO BE BOUND BY THE MODIFIED TERMS. IF YOU DON’T AGREE TO BE BOUND BY THE MODIFIED TERMS,
            THEN YOU MAY NOT USE THE SERVICES. BECAUSE OUR SERVICES ARE EVOLVING OVER TIME WE MAY CHANGE OR DISCONTINUE
            ALL OR ANY PART OF THE SERVICES, AT ANY TIME AND WITHOUT NOTICE, AT OUR SOLE DISCRETION. ARBITRATION NOTICE:
            THESE TERMS CONTAIN AN ARBITRATION CLAUSE FOR USERS IN CERTAIN JURISDICTIONS. IF YOU ARE A USER LOCATED IN
            SINGAPORE OR APPLICABLE, YOU AGREE THAT DISPUTES BETWEEN YOU AND PURPLE ORIGIN LTD WILL BE RESOLVED BY
            BINDING, INDIVIDUAL ARBITRATION IN SINGAPORE OR AS APPLICABLE, AND YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY
            JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE
            PROCEEDING.
            <br />
            <br />
            WHO MAY USE THE SERVICES
            <br />
            ELIGIBILITY – YOU MAY ONLY USE THE SERVICES IF YOU ARE 18 YEARS OR OLDER AND ARE NOT BARRED FROM USING THE
            SERVICES UNDER APPLICABLE LAW.
            <br />
            REGISTRATION AND YOUR INFORMATION – IF YOU WANT TO USE THE SERVICES YOU’LL HAVE TO CREATE AN ACCOUNT
            (“ACCOUNT”) VIA THE APP BY CONNECTING YOUR WEB 3 WALLET. YOU AGREE THAT YOU WON’T DISCLOSE YOUR ACCOUNT
            CREDENTIALS TO ANYONE AND YOU’LL NOTIFY US IMMEDIATELY OF ANY UNAUTHORIZED USE OF YOUR ACCOUNT. YOU’RE
            RESPONSIBLE FOR ALL ACTIVITIES THAT OCCUR UNDER YOUR ACCOUNT, OR ARE OTHERWISE REFERABLE TO YOUR ACCOUNT
            CREDENTIALS, WHETHER OR NOT YOU KNOW ABOUT THEM. WE RESERVE THE RIGHT TO SUSPEND OR TERMINATE YOUR ACCOUNT
            IF YOU PROVIDE INACCURATE, UNTRUE, OR INCOMPLETE INFORMATION, OR IF YOU FAIL TO COMPLY WITH THE ACCOUNT
            REGISTRATION REQUIREMENTS OR THESE TERMS. YOU ARE SOLELY RESPONSIBLE FOR THE RETENTION AND SECURITY OF YOUR
            TWELVE WORD RECOVERY PHRASE (“RECOVERY PHRASE”) AND/OR PRIVATE KEY (“PRIVATE KEY”). YOUR RECOVERY PHRASE
            AND/OR PRIVATE KEY IS THE ONLY WAY TO ACCESS THE CRYPTOCURRENCY ASSOCIATED WITH YOUR ACCOUNT. ANYONE THAT
            HAS ACCESS TO YOUR RECOVERY PHRASE AND/OR PRIVATE KEY CAN ACCESS YOUR CRYPTOCURRENCY. IF YOU LOSE YOUR
            RECOVERY PHRASE AND/OR PRIVATE KEY, YOU WILL NOT BE ABLE TO ACCESS YOUR CRYPTOCURRENCY. YOU ACKNOWLEDGE THAT
            PURPLE ORIGIN LTD DOES NOT STORE AND IS NOT RESPONSIBLE IN ANY WAY FOR THE SECURITY OF YOUR RECOVERY PHRASE
            AND/OR PRIVATE KEY AND YOU AGREE TO HOLD PURPLE ORIGIN LTD AND/OR XION GLOBAL HARMLESS AND THAT PURPLE
            ORIGIN LTD AND/OR XION GLOBAL SHALL NOT BE LIABLE IN ANY WAY IN THE EVENT YOU LOSE YOUR RECOVERY PHRASE
            AND/OR PRIVATE KEY AND CANNOT ACCESS YOUR CRYPTOCURRENCY.
            <br />
            <br />
            FEEDBACK
            <br />
            WE WELCOME FEEDBACK, COMMENTS, IDEAS, AND SUGGESTIONS FOR IMPROVEMENTS TO THE SERVICES (“FEEDBACK”). YOU CAN
            SUBMIT FEEDBACK BY CONTACTING US AT HTTPS://WWW.XION.GLOBAL/CONTACT_US/. YOU GRANT TO US A NON-EXCLUSIVE,
            WORLDWIDE, PERPETUAL, IRREVOCABLE, FULLY-PAID, ROYALTY-FREE, SUBLICENSABLE AND TRANSFERABLE LICENSE UNDER
            ANY AND ALL INTELLECTUAL PROPERTY RIGHTS THAT YOU OWN OR CONTROL TO USE, COPY, MODIFY, CREATE DERIVATIVE
            WORKS BASED UPON AND TO OTHERWISE EXPLOIT THE FEEDBACK FOR ANY PURPOSE.
            <br />
            <br />
            CONTENT OWNERSHIP, RESPONSIBILITY AND REMOVAL
            <br />
            FOR PURPOSES OF THESE TERMS: (I) “CONTENT” MEANS TEXT, GRAPHICS, IMAGES, MUSIC, SOFTWARE, AUDIO, VIDEO,
            WORKS OF AUTHORSHIP OF ANY KIND, AND INFORMATION OR OTHER MATERIALS THAT ARE POSTED, GENERATED, PROVIDED OR
            OTHERWISE MADE AVAILABLE THROUGH THE SERVICES; AND (II) “USER CONTENT” MEANS ANY CONTENT THAT ACCOUNT
            HOLDERS (INCLUDING YOU) MAKE AVAILABLE THROUGH THE SERVICES.
            <br />
            WE DO NOT CLAIM ANY OWNERSHIP RIGHTS IN ANY USER CONTENT AND NOTHING IN THESE TERMS WILL BE DEEMED TO
            RESTRICT ANY RIGHTS THAT YOU MAY HAVE TO USE AND EXPLOIT YOUR USER CONTENT UNLESS EXPLICITLY INDICATED IN
            THE TERMS. SUBJECT TO THE FOREGOING, PURPLE ORIGIN LTD AND ITS LICENSORS EXCLUSIVELY OWN ALL RIGHT, TITLE
            AND INTEREST IN AND TO THE SERVICES AND CONTENT, INCLUDING ALL ASSOCIATED INTELLECTUAL PROPERTY RIGHTS. YOU
            ACKNOWLEDGE THAT THE SERVICES AND CONTENT ARE PROTECTED BY COPYRIGHT, TRADEMARK, AND OTHER LAWS OF SINGAPORE
            AND FOREIGN COUNTRIES. YOU AGREE NOT TO REMOVE, ALTER OR OBSCURE ANY COPYRIGHT, TRADEMARK, SERVICE MARK OR
            OTHER PROPRIETARY RIGHTS NOTICES INCORPORATED IN OR ACCOMPANYING THE SERVICES OR CONTENT. RIGHTS IN USER
            CONTENT GRANTED BY YOU – IN ORDER TO OPERATE AND PROVIDE OUR SERVICES, YOU GRANT US A WORLDWIDE,
            NON-EXCLUSIVE, ROYALTY-FREE, SUBLICENSABLE, AND TRANSFERABLE LICENSE TO USE, COPY, DISTRIBUTE, CREATE
            DERIVATIVE WORKS OF, DISPLAY, AND PERFORM THE USER CONTENT THAT YOU UPLOAD, SUBMIT, STORE, SEND, OR RECEIVE
            ON THE APP OR THROUGH OUR SERVICES. THE RIGHTS YOU GRANT IN THIS LICENSE ARE FOR THE LIMITED PURPOSE OF
            OPERATING AND PROVIDING OUR SERVICES. ADDITIONAL INFORMATION ABOUT YOUR PRIVACY AND HOW WE USE USER CONTENT
            IS AVAILABLE IN THE PRIVACY POLICY. YOU WARRANT AND REPRESENT THAT YOU HAVE THE RIGHT AND AUTHORITY TO
            SUBMIT YOUR USER CONTENT AND THAT THE USER CONTENT OR ANY PART THEREOF DOES NOT INFRINGE THE INTELLECTUAL
            PROPERTY RIGHTS OR ANY OTHER RIGHTS OF ANY THIRD PARTY. YOU ACKNOWLEDGE THAT, IN CERTAIN INSTANCES, WHERE
            YOU HAVE REMOVED YOUR USER CONTENT BY SPECIFICALLY DELETING IT, SOME OF YOUR USER CONTENT (SUCH AS POSTS OR
            COMMENTS YOU MAKE) MAY NOT BE COMPLETELY REMOVED AND COPIES OF YOUR USER CONTENT MAY CONTINUE TO EXIST ON
            THE SERVICES. WE ARE NOT RESPONSIBLE OR LIABLE FOR THE REMOVAL OR DELETION OF (OR THE FAILURE TO REMOVE OR
            DELETE) ANY OF YOUR USER CONTENT. RIGHTS IN CONTENT GRANTED BY PURPLE ORIGIN LTD – SUBJECT TO YOUR
            COMPLIANCE WITH THESE TERMS, WE GRANT YOU A LIMITED, NON-EXCLUSIVE, NON- TRANSFERABLE, NON-SUBLICENSABLE
            LICENSE TO DOWNLOAD, VIEW, COPY, DISPLAY AND PRINT THE CONTENT SOLELY IN CONNECTION WITH YOUR PERMITTED USE
            OF THE SERVICES.
            <br />
            <br />
            RIGHTS IN APP, SITE AND SERVICES GRANTED BY PURPLE ORIGIN LTD
            <br />
            THE APP, SITE AND SERVICES ARE PROPRIETARY TO PURPLE ORIGIN LTD AND ITS LICENSORS AND MUST NOT BE USED OTHER
            THAN STRICTLY IN ACCORDANCE WITH THESE TERMS. PURPLE ORIGIN LTD GRANTS TO YOU A LIMITED, NON-EXCLUSIVE,
            NON-TRANSFERABLE, NON-SUBLICENSABLE RIGHT TO USE THE APP AND SITE FOR THE PURPOSES OF ACCESSING AND USING
            THE SERVICES IN ACCORDANCE WITH THESE TERMS.
            <br />
            <br />
            FEES
            <br />
            WE MAY CHARGE FEE(S) FOR SOME OR PART OF THE SERVICES WE MAKE AVAILABLE TO YOU. ALL APPLICABLE TRANSACTION
            FEE(S) ARE SET FORTH IN THE APPLICABLE FEE SCHEDULE SET FORTH IN EXHIBIT A. WE RESERVE THE RIGHT TO CHANGE
            THOSE FEE(S) AT OUR DISCRETION WITH NOTICE. WE ALSO RESERVE THE RIGHT TO VARY OR WAIVE THE AMOUNT OF AND
            TERMS FOR FEE(S) THAT APPLY TO USER(S) OF THE SERVICES AT OUR DISCRETION IN ACCORDANCE WITH THE TERMS OF OUR
            AGREEMENT(S) WITH THEM. IN ADDITION TO THE FEE(S) SPECIFIED IN EXHIBIT A, YOU MAY INCUR CHARGES FROM THIRD
            PARTIES FOR USE OF LINKED SERVICES. THIRD PARTY FEE(S) ARE NOT CHARGED BY PURPLE ORIGIN LTD AND ARE NOT PAID
            TO PURPLE ORIGIN LTD. PLEASE NOTE THAT WE SHALL NOT REFUND ANY TRANSACTION FEE THAT ARISES AS A RESULT OF
            YOU REFUNDING TRANSACTIONS (AS SUCH TERMS ARE DEFINED IN EXHIBIT A).
            <br />
            <br />
            ACCEPTABLE USE AND PURPLE ORIGIN LTD ENFORCEMENT RIGHTS
            <br />
            YOU AGREE NOT TO USE THE SERVICES (OR ALLOW YOUR CUSTOMERS TO USE THE SERVICES) IN ANY WAY OR IN CONNECTION
            WITH ANY TRANSACTION THAT WOULD (OR POTENTIALLY WOULD):
            <br />
            <br />
            BE CONSIDERED “PROHIBITED USES” OR “PROHIBITED BUSINESSES”, AS FURTHER DESCRIBED AND SET FORTH IN EXHIBIT B,
            ATTACHED HERETO;
            <br />
            VIOLATE, MISAPPROPRIATE, OR INFRINGE THE RIGHTS OF PURPLE ORIGIN LTD, OUR USERS, THIRD PARTIES, OR OTHERS,
            INCLUDING PRIVACY, PUBLICITY, INTELLECTUAL PROPERTY, OR OTHER PROPRIETARY RIGHTS;
            <br />
            ARE ILLEGAL, DEFAMATORY, THREATENING, INTIMIDATING, HARASSING, , OR INSTIGATE OR ENCOURAGE CONDUCT THAT
            WOULD BE ILLEGAL, INCLUDING PROMOTING VIOLENT CRIMES;
            <br />
            INVOLVE IMPERSONATING SOMEONE;
            <br />
            BREACH ANY DUTY TOWARD OR RIGHTS OF ANY PERSON OR ENTITY, INCLUDING RIGHTS OF PUBLICITY, PRIVACY, OR
            TRADEMARK;
            <br />
            INVOLVE SENDING ILLEGAL OR IMPERMISSIBLE COMMUNICATIONS SUCH AS BULK MESSAGING, AUTO-MESSAGING,
            AUTO-DIALING, AND THE LIKE;
            <br />
            AVOID, BYPASS, REMOVE, DEACTIVATE, IMPAIR, DESCRAMBLE OR OTHERWISE CIRCUMVENT ANY TECHNOLOGICAL MEASURE
            IMPLEMENTED BY US OR ANY OF OUR SERVICE PROVIDERS OR ANY OTHER THIRD PARTY (INCLUDING ANOTHER USER) TO
            PROTECT THE SERVICES OR CONTENT;
            <br />
            DISGUISE YOUR LOCATION THROUGH IP PROXYING OR OTHER METHODS; INTERFERE WITH, OR ATTEMPT TO INTERFERE WITH,
            THE ACCESS OF ANY USER, HOST OR NETWORK, INCLUDING, WITHOUT LIMITATION, SENDING A VIRUS, OVERLOADING,
            FLOODING, SPAMMING, OR MAIL-BOMBING THE SERVICES;
            <br />
            INVOLVE ANY UNFAIR, DECEPTIVE OR ABUSIVE ACT OR PRACTICE;
            <br />
            VIOLATE ANY APPLICABLE LAW OR REGULATION; OR
            <br />
            KNOWINGLY ENCOURAGE OR ENABLE ANY OTHER INDIVIDUAL TO DO ANY OF THE FOREGOING.
            <br />
            <br />
            ALTHOUGH WE HAVE NO OBLIGATION TO MONITOR ANY USER CONTENT, WE HAVE ABSOLUTE DISCRETION TO REMOVE USER
            CONTENT AT ANY TIME AND FOR ANY REASON WITHOUT NOTICE. YOU UNDERSTAND THAT BY USING THE SERVICES, YOU MAY BE
            EXPOSED TO USER CONTENT THAT IS OFFENSIVE, INDECENT, OR OBJECTIONABLE. WE TAKE NO RESPONSIBILITY AND ASSUME
            NO LIABILITY FOR ANY USER CONTENT, INCLUDING ANY LOSS OR DAMAGE TO ANY OF YOUR USER CONTENT.
            <br />
            YOU AGREE TO COMPLY WITH ALL APPLICABLE SINGAPORE, AND OTHER EXPORT CONTROL AND TRADE SANCTIONS LAWS
            (“EXPORT LAWS”). WITHOUT LIMITING THE FOREGOING, YOU MAY NOT DOWNLOAD THE APP OR USE THE SERVICES IF (1) YOU
            ARE IN, UNDER THE CONTROL OF, OR A NATIONAL OR RESIDENT OF CUBA, IRAN, NORTH KOREA, SUDAN, OR SYRIA OR ANY
            OTHER COUNTRY SUBJECT TO UNITED STATES EMBARGO, UN SECURITY COUNCIL RESOLUTIONS (“UNSCR”), HM TREASURY’S
            FINANCIAL SANCTIONS REGIME, OR IF YOU ARE ON THE U.S. TREASURY DEPARTMENT’S SPECIALLY DESIGNATED NATIONALS
            LIST OR THE U.S. COMMERCE DEPARTMENT’S DENIED PERSONS LIST, UNVERIFIED LIST, ENTITY LIST HM TREASURY’S
            FINANCIAL SANCTIONS REGIME; OR (2) YOU INTEND TO SUPPLY ANY SERVICES TO CUBA, IRAN, NORTH KOREA, SUDAN OR
            SYRIA OR ANY OTHER COUNTRY SUBJECT TO UNITED STATES EMBARGO OR HM TREASURY’S FINANCIAL SANCTIONS REGIME (OR
            A NATIONAL OR RESIDENT OF ONE OF THESE COUNTRIES), OR TO A PERSON ON THE SPECIALLY DESIGNATED NATIONALS
            LIST, DENIED PERSONS LIST, UNVERIFIED LIST, ENTITY LIST, OR HM TREASURY’S FINANCIAL SANCTIONS REGIME.
            <br />
            <br />
            TERMINATION
            <br />
            WE MAY TERMINATE YOUR ACCESS TO AND USE OF THE SERVICES WITH IMMEDIATE EFFECT, AT OUR SOLE DISCRETION FOR
            ANY REASON, AT ANY TIME AND WITHOUT NOTICE TO YOU. YOU MAY CANCEL YOUR ACCOUNT AT ANY TIME BY REQUEST. UPON
            ANY TERMINATION, DISCONTINUATION OR CANCELLATION OF SERVICES OR YOUR ACCOUNT, (I) ALL RIGHTS AND/OR LICENSES
            GRANTED TO YOU UNDER THESE TERMS SHALL IMMEDIATELY CEASE AND TERMINATE AND YOU SHALL FORTHWITH CEASE THE USE
            AND/OR ACCESS OF THE APP, SITE, SERVICES AND CONTENT IN ANY WAY WHATSOEVER; AND (II) NOTWITHSTANDING THE
            FOREGOING, THE FOLLOWING PROVISIONS OF THESE TERMS WILL SURVIVE:
            <br />
            FEEDBACK, CONTENT OWNERSHIP, RESPONSIBILITY AND REMOVAL (SAVE FOR THE SUBSECTION “RIGHTS IN CONTENT GRANTED
            BY PURPLE ORIGIN LTD ), TERMINATION, WARRANTY DISCLAIMERS, INDEMNITY, LIMITATION OF LIABILITY, DISPUTE
            RESOLUTION, AND GENERAL TERMS.
            <br />
            <br />
            WARRANTY DISCLAIMERS
            <br />
            YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICES (INCLUDING ANY PRIVATE KEY STORAGE SERVICE
            OFFERED AS PART OF THE SERVICES, WHETHER CLOUD OR HARDWARE-BASED) AND CONTENT IS AT YOUR SOLE RISK AND THAT
            THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. THE APP, SITE AND
            SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER
            EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, PURPLE ORIGIN LTD
            SPECIFICALLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE AND/OR NON-INFRINGEMENT. PURPLE ORIGIN LTD DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT
            ACCESS TO THE SERVICES OR ANY OF THE MATERIALS CONTAINED THEREIN WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY,
            OR ERROR-FREE. USE OF ANY PRIVATE KEY STORAGE SERVICE INCLUDED AS PART OF THE SERVICES IS OFFERED TO YOU AS
            A CONVENIENCE, SUBJECT TO THE LIMITATIONS ABOVE. TO BE SAFE, YOU SHOULD ALWAYS BACKUP YOUR PRIVATE ACCESS
            KEY VIA SECONDARY MEANS.
            <br />
            <br />
            INDEMNITY
            <br />
            YOU WILL INDEMNIFY AND HOLD HARMLESS PURPLE ORIGIN LTD AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS
            FROM AND AGAINST ANY CLAIMS, DISPUTES, DEMANDS, LIABILITIES, DAMAGES, LOSSES, AND COSTS AND EXPENSES,
            INCLUDING, WITHOUT LIMITATION, REASONABLE LEGAL AND ACCOUNTING FEES ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH (I) YOUR ACCESS TO OR USE OF THE SERVICES OR CONTENT, (II) YOUR USER CONTENT, (III) THIRD PARTY
            MATERIALS, OR (IV) YOUR VIOLATION OF THESE TERMS.
            <br />
            <br />
            LIMITATION OF LIABILITY
            <br />
            TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW, PURPLE ORIGIN LTD SHALL NOT BE LIABLE FOR DAMAGES OF ANY TYPE,
            WHETHER DIRECT OR INDIRECT, ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OR INABILITY TO USE THE
            SERVICES, INCLUDING BUT NOT LIMITED TO DAMAGES ALLEGEDLY ARISING FROM THE COMPROMISE OR LOSS OF YOUR LOGIN
            CREDENTIALS OR FUNDS, OR LOSS OF OR INABILITY TO RESTORE ACCESS FROM YOUR BACKUP PHRASE, OR FOR MISTAKES,
            OMISSIONS, INTERRUPTIONS, DELAYS, DEFECTS AND/OR ERRORS IN THE TRANSMISSION OF TRANSACTIONS OR MESSAGES TO
            THE ETHEREUM, XDAI, BINANCE SMART CHAIN, OR ANY ASSOCIATED NETWORK, OR THE FAILURE OF ANY MESSAGE TO SEND TO
            OR BE RECEIVED BY THE INTENDED RECIPIENT IN THE INTENDED FORM, OR FOR DIMINUTION OF VALUE OF ETHER OR ANY
            OTHER DIGITAL TOKEN OR DIGITAL ASSET ON THE ETHEREUM OR ANY ASSOCIATED NETWORK. PURPLE ORIGIN LTD SHALL NOT
            BE LIABLE UNDER ANY CIRCUMSTANCES FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR
            CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING
            OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE SERVICES, EVEN IF AN AUTHORIZED
            REPRESENTATIVE OF PURPLE ORIGIN LTD HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF
            SUCH DAMAGES. PURPLE ORIGIN LTD SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN
            ANY WAY RELATED TO SOFTWARE, PRODUCTS, SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED BY THIRD-PARTIES AND
            ACCESSED THROUGH THE APP, SITE OR SERVICES.
            <br />
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL PURPLE ORIGIN LTD TOTAL
            LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING
            PERSONAL INJURY) EXCEED THE AMOUNT OF ONE HUNDRED U.S. DOLLARS ($USD100.00) OR ITS EQUIVALENT IN THE LOCAL
            CURRENCY OF THE APPLICABLE JURISDICTION.
            <br />
            <br />
            DISPUTE RESOLUTION
            <br />
            GOVERNING LAW, FORUM AND VENUE – NOTWITHSTANDING YOUR AGREEING TO COMPLY WITH ALL APPLICABLE EXPORT LAWS,
            THESE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED BY THE LAWS OF THE SINGAPORE, WITHOUT REGARD TO
            ITS CONFLICT OF LAWS PROVISIONS, IF YOU ARE A USER LOCATED IN THE SINGAPORE OR ANY APPLICABLE JURISDICTION,
            THE TERMS IN THE “SPECIAL ARBITRATION PROVISION FOR THE SINGAPORE” SECTION BELOW APPLY TO YOU.
            <br />
            IF YOU ARE NOT LOCATED IN THE SINGAPORE, YOU AGREE THAT YOU WILL RESOLVE ANY CLAIM YOU HAVE WITH US RELATING
            TO, ARISING OUT OF, OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES (EACH, A “DISPUTE,” AND
            TOGETHER, “DISPUTES”) EXCLUSIVELY IN THE STATE COURTS LOCATED IN THE SINGAPORE, OR FEDERAL COURT FOR THE
            SINGAPORE, AND YOU AGREE TO SUBMIT TO THE PERSONAL JURISDICTION OF SUCH COURTS FOR THE PURPOSE OF LITIGATING
            ALL SUCH DISPUTES. SPECIAL ARBITRATION PROVISION FOR SINGAPORE – IF YOU ARE A USER LOCATED IN THE SINGAPORE,
            YOU AND PURPLE ORIGIN LTD AGREE THAT ANY DISPUTE SHALL BE FINALLY SETTLED IN BINDING ARBITRATION, ON AN
            INDIVIDUAL BASIS, IN ACCORDANCE WITH THE SINGAPORE ARBITRATION ASSOCIATION’S RULES FOR ARBITRATION OF
            CONSUMER-RELATED DISPUTES AND YOU AND PURPLE ORIGIN LTD HEREBY EXPRESSLY WAIVE TRIAL BY JURY AND RIGHT TO
            PARTICIPATE IN A CLASS ACTION LAWSUIT, PRIVATE ATTORNEY GENERAL ACTIONS, OR CLASS-WIDE ARBITRATION, EXCEPT
            THAT EACH PARTY RETAINS THE RIGHT: (I) TO BRING AN INDIVIDUAL ACTION IN SMALL CLAIMS COURT AND (II) TO SEEK
            INJUNCTIVE OR OTHER EQUITABLE RELIEF IN A COURT OF COMPETENT JURISDICTION TO PREVENT THE ACTUAL OR
            THREATENED INFRINGEMENT, MISAPPROPRIATION OR VIOLATION OF A PARTY’S COPYRIGHTS, TRADEMARKS, TRADE SECRETS,
            PATENTS OR OTHER INTELLECTUAL PROPERTY RIGHTS (THE ACTION DESCRIBED IN THE FOREGOING CLAUSE (II), AN “IP
            PROTECTION ACTION”). THE EXCLUSIVE JURISDICTION OF AN IP PROTECTION ACTION SHALL BE THE COURTS OF SINGAPORE,
            AND YOU AGREE TO SUBMIT TO THE PERSONAL JURISDICTION OF SUCH COURTS FOR THE PURPOSE OF LITIGATING IP
            PROTECTION ACTIONS. THE ARBITRATION WILL BE CONDUCTED BY A SINGLE, NEUTRAL ARBITRATOR AND SHALL TAKE PLACE
            IN THE COUNTY OR, IF APPLICABLE, PARISH IN WHICH YOU RESIDE, OR ANOTHER MUTUALLY AGREEABLE LOCATION, IN THE
            ENGLISH LANGUAGE. THE ARBITRATOR MAY AWARD ANY RELIEF THAT A COURT OF COMPETENT JURISDICTION COULD AWARD,
            INCLUDING ATTORNEYS’ FEES WHEN AUTHORIZED BY LAW, AND THE ARBITRAL DECISION MAY BE ENTERED AS A JUDGMENT AND
            ENFORCED IN ANY COURT OF LAW. AT YOUR REQUEST, HEARINGS MAY BE CONDUCTED IN PERSON OR BY TELEPHONE AND THE
            ARBITRATOR MAY PROVIDE FOR SUBMITTING AND DETERMINING MOTIONS ON BRIEFS, WITHOUT ORAL HEARINGS. THE
            PREVAILING PARTY IN ANY ACTION OR PROCEEDING TO ENFORCE THIS AGREEMENT SHALL BE ENTITLED TO COSTS AND
            ATTORNEYS’ FEES. IF THE ARBITRATOR(S) OR ARBITRATION ADMINISTRATOR WOULD IMPOSE FILING FEES OR OTHER
            ADMINISTRATIVE COSTS ON YOU, WE WILL REIMBURSE YOU, UPON REQUEST, TO THE EXTENT SUCH FEES OR COSTS WOULD
            EXCEED THOSE THAT YOU WOULD OTHERWISE HAVE TO PAY IF YOU WERE PROCEEDING INSTEAD IN A COURT. WE WILL ALSO
            PAY ADDITIONAL FEES OR COSTS IF REQUIRED TO DO SO BY THE ARBITRATION ADMINISTRATOR’S RULES OR APPLICABLE
            LAW. APART FROM THE FOREGOING, EACH PARTY WILL BE RESPONSIBLE FOR ANY OTHER FEES OR COSTS, SUCH AS ATTORNEY
            FEES THAT THE PARTY MAY INCUR. IF A COURT DECIDES THAT ANY PROVISION OF THIS SPECIAL ARBITRATION PROVISION
            IS INVALID OR UNENFORCEABLE, THAT PROVISION SHALL BE SEVERED AND THE OTHER PARTS OF THIS SPECIAL ARBITRATION
            PROVISION SHALL STILL APPLY. IN ANY CASE, THE REMAINDER OF THIS USER AGREEMENT WILL CONTINUE TO APPLY.
            <br />
            <br />
            GENERAL TERMS
            <br />
            THESE TERMS CONSTITUTE THE ENTIRE AND EXCLUSIVE UNDERSTANDING AND AGREEMENT BETWEEN PURPLE ORIGIN LTD AND
            YOU REGARDING THE SERVICES AND CONTENT, AND THESE TERMS SUPERSEDE AND REPLACE ANY AND ALL PRIOR ORAL OR
            WRITTEN UNDERSTANDINGS OR AGREEMENTS BETWEEN PURPLE ORIGIN LTD AND YOU REGARDING THE SERVICES AND CONTENT.
            IF ANY PROVISION OF THESE TERMS IS HELD INVALID OR UNENFORCEABLE (EITHER BY AN ARBITRATOR APPOINTED PURSUANT
            TO THE TERMS OF THE “SPECIAL ARBITRATION PROVISION FOR SINGAPORE USERS” SECTION ABOVE OR BY A COURT OF
            COMPETENT JURISDICTION, THAT PROVISION WILL BE ENFORCED TO THE MAXIMUM EXTENT PERMISSIBLE AND THE OTHER
            PROVISIONS OF THESE TERMS WILL REMAIN IN FULL FORCE AND EFFECT. YOU MAY NOT ASSIGN OR TRANSFER THESE TERMS,
            BY OPERATION OF LAW OR OTHERWISE, WITHOUT OUR PRIOR WRITTEN CONSENT. ANY ATTEMPT BY YOU TO ASSIGN OR
            TRANSFER THESE TERMS, WITHOUT SUCH CONSENT, WILL BE NULL. WE MAY FREELY ASSIGN OR TRANSFER THESE TERMS
            WITHOUT RESTRICTION. SUBJECT TO THE FOREGOING, THESE TERMS WILL BIND AND INURE TO THE BENEFIT OF THE
            PARTIES, THEIR SUCCESSORS AND PERMITTED ASSIGNS. ANY NOTICES OR OTHER COMMUNICATIONS PROVIDED BY US UNDER
            THESE TERMS, INCLUDING THOSE REGARDING MODIFICATIONS TO THESE TERMS, WILL BE GIVEN BY POSTING TO THE
            SERVICES AND/OR THROUGH OTHER ELECTRONIC COMMUNICATION. YOU AGREE AND CONSENT TO RECEIVE ELECTRONICALLY ALL
            COMMUNICATIONS, AGREEMENTS, DOCUMENTS, NOTICES AND DISCLOSURES (COLLECTIVELY, “COMMUNICATIONS”) THAT WE
            PROVIDE IN CONNECTION WITH YOUR ACCOUNT AND YOUR USE OF THE SERVICES. OUR FAILURE TO ENFORCE ANY RIGHT OR
            PROVISION OF THESE TERMS WILL NOT BE CONSIDERED A WAIVER OF SUCH RIGHT OR PROVISION. THE WAIVER OF ANY SUCH
            RIGHT OR PROVISION WILL BE EFFECTIVE ONLY IF IN WRITING AND SIGNED BY A DULY AUTHORIZED REPRESENTATIVE OF
            PURPLE ORIGIN LTD. EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS, THE EXERCISE BY EITHER PARTY OF ANY OF ITS
            REMEDIES UNDER THESE TERMS WILL BE WITHOUT PREJUDICE TO ITS OTHER REMEDIES UNDER THESE TERMS OR OTHERWISE.
            <br />
            THESE TERMS ARE WRITTEN IN ENGLISH (U.S.). ANY TRANSLATED VERSION IS PROVIDED SOLELY FOR YOUR CONVENIENCE.
            TO THE EXTENT ANY TRANSLATED VERSION OF OUR TERMS CONFLICTS WITH THE ENGLISH VERSION, THE ENGLISH VERSION
            CONTROLS. UNLESS OTHERWISE PROVIDED IN THESE TERMS, A PERSON OR ENTITY WHO IS NOT A PARTY TO THESE TERMS
            SHALL HAVE NO RIGHT TO ENFORCE ANY TERM OF THESE TERMS, REGARDLESS OF WHETHER SUCH PERSON OR ENTITY HAS BEEN
            IDENTIFIED BY NAME, AS A MEMBER OF A CLASS OR AS ANSWERING A PARTICULAR DESCRIPTION. FOR THE AVOIDANCE OF
            DOUBT, NOTHING IN THIS PROVISION SHALL AFFECT THE RIGHTS OF ANY PERMITTED ASSIGNEE OR TRANSFEREE OF THESE
            TERMS.
            <br />
            <br />
            CONTACT INFORMATION
            <br />
            IF YOU HAVE ANY QUESTIONS ABOUT THESE TERMS OR THE SERVICES, PLEASE CONTACT US AT CONTACT@XION.GLOBAL.
            <br />
            <br />
            EXHIBIT A - FEE SCHEDULE
            <br />
            YOU SHALL PAY PURPLE ORIGIN LTD A ONE PERCENT (1%) FEE (“TRANSACTION FEE”), ALONG WITH ANY APPLICABLE VAT OR
            SIMILAR TAXES ON ALL TRANSACTIONS THAT OCCUR USING THE PAYMENT APPLICATION THROUGH WHICH YOU ACCEPT CERTAIN
            BLOCKCHAIN-BASED DIGITAL CURRENCY, APP COINS, PROTOCOL TOKENS, OR OTHER DIGITAL CURRENCY FROM YOUR CUSTOMERS
            (SUCH TRANSACTIONS BEING “TRANSACTIONS”). THE PRICING TERMS SET FORTH HEREIN ARE CONFIDENTIAL AND SHALL NOT
            BE SHARED WITH ANY THIRD PARTIES.
            <br />
            <br />
            EXHIBIT B - PROHIBITED USE AND PROHIBITED BUSINESSES
            <br />
            THIS POLICY SETS FORTH THE LIMITATIONS CONCERNING YOUR USE OF THE SERVICES.
            <br />
            PROHIBITED USE. YOU MAY NOT USE THE SERVICES TO ENGAGE IN THE FOLLOWING CATEGORIES OF ACTIVITY (“PROHIBITED
            USES”). THE PROHIBITED USES EXTEND TO ANY THIRD PARTY THAT GAINS ACCESS TO THE SERVICES THROUGH YOUR ACCOUNT
            OR OTHERWISE, REGARDLESS OF WHETHER SUCH THIRD PARTY WAS AUTHORIZED OR UNAUTHORIZED BY YOU TO USE THE
            SERVICES. THE SPECIFIC TYPES OF USES LISTED BELOW ARE REPRESENTATIVE, BUT NOT EXHAUSTIVE, OF PROHIBITED
            USES. BY USING THE SERVICES, YOU CONFIRM THAT YOU WILL NOT USE THE SERVICES TO DO ANY OF THE FOLLOWING:
            <br />
            <br />
            UNLAWFUL ACTIVITY: ACTIVITY WHICH WOULD VIOLATE, OR ASSIST IN VIOLATION OF, ANY LAW, STATUTE, ORDINANCE, OR
            REGULATION, SANCTIONS PROGRAMS ADMINISTERED IN THE COUNTRIES WHERE PURPLE ORIGIN LTD CONDUCTS BUSINESS,
            INCLUDING, BUT NOT LIMITED TO, THE SINGAPORE, OR WHICH WOULD INVOLVE PROCEEDS OF ANY UNLAWFUL ACTIVITY;
            PUBLISH, DISTRIBUTE OR DISSEMINATE ANY UNLAWFUL MATERIAL OR INFORMATION.
            <br />
            ABUSIVE ACTIVITY: ACTIONS WHICH IMPOSE AN UNREASONABLE OR DISPROPORTIONATELY LARGE LOAD ON OUR
            INFRASTRUCTURE, OR DETRIMENTALLY INTERFERE WITH, INTERCEPT, OR EXPROPRIATE ANY SYSTEM, DATA, OR INFORMATION;
            TRANSMIT OR UPLOAD ANY MATERIAL TO THE SITE OR APP THAT CONTAINS VIRUSES, TROJAN HORSES, WORMS, OR ANY OTHER
            HARMFUL OR DELETERIOUS PROGRAMS; ATTEMPT TO GAIN UNAUTHORIZED ACCESS TO THE SITE OR APP, OTHER ACCOUNTS,
            COMPUTER SYSTEMS OR NETWORKS CONNECTED TO THE SITE OR APP, THROUGH PASSWORD MINING OR ANY OTHER MEANS; USE
            ACCOUNT INFORMATION OF ANOTHER PARTY TO ACCESS OR USE THE SITE OR APP; OR TRANSFER YOUR ACCOUNT ACCESS OR
            RIGHTS TO YOUR ACCOUNT TO A THIRD PARTY, UNLESS BY OPERATION OF LAW OR WITH THE EXPRESS PERMISSION OF PURPLE
            ORIGIN LTD.
            <br />
            ABUSE OTHER USERS: INTERFERE WITH ANOTHER OF OUR USER’S ACCESS TO OR USE OF ANY SERVICES; DEFAME, ABUSE,
            EXTORT, HARASS, STALK, THREATEN OR OTHERWISE VIOLATE OR INFRINGE THE LEGAL RIGHTS (SUCH AS, BUT NOT LIMITED
            TO, RIGHTS OF PRIVACY, PUBLICITY AND INTELLECTUAL PROPERTY) OF OTHERS; HARVEST OR OTHERWISE COLLECT
            INFORMATION FROM THE SITE OR APP ABOUT OTHERS, INCLUDING, WITHOUT LIMITATION, EMAIL ADDRESSES, WITHOUT
            PROPER CONSENT.
            <br />
            FRAUD: ACTIVITY WHICH OPERATES TO DEFRAUD PURPLE ORIGIN LTD, PURPLE ORIGIN LTD USERS, OR ANY OTHER PERSON;
            PROVIDE ANY FALSE, INACCURATE, OR MISLEADING INFORMATION TO PURPLE ORIGIN LTD.
            <br />
            INTELLECTUAL PROPERTY INFRINGEMENT: ENGAGE IN TRANSACTIONS INVOLVING ITEMS THAT INFRINGE OR VIOLATE ANY
            COPYRIGHT, TRADEMARK, RIGHT OF PUBLICITY OR PRIVACY OR ANY OTHER PROPRIETARY RIGHT UNDER THE LAW, INCLUDING
            BUT NOT LIMITED TO SALES, DISTRIBUTION, OR ACCESS TO COUNTERFEIT MUSIC, MOVIES, SOFTWARE, OR OTHER LICENSED
            MATERIALS WITHOUT THE APPROPRIATE AUTHORIZATION FROM THE RIGHTS HOLDER; USE OF OUR OR PURPLE ORIGIN LTD
            INTELLECTUAL PROPERTY, NAME, OR LOGO, INCLUDING USE OF OUR OR PURPLE ORIGIN LTD, TRADE OR SERVICE MARKS,
            WITHOUT EXPRESS CONSENT FROM PURPLE ORIGIN LTD RESPECTIVELY, OR IN A MANNER THAT OTHERWISE HARMS PURPLE
            ORIGIN LTD BRAND; ANY ACTION THAT IMPLIES AN UNTRUE ENDORSEMENT BY OR AFFILIATION WITH PURPLE ORIGIN LTD.
            <br />
            WRITTEN POLICIES: YOU MAY NOT USE THE SERVICES IN A MANNER THAT VIOLATES, OR IS OTHERWISE INCONSISTENT WITH,
            ANY OPERATING INSTRUCTIONS PROMULGATED BY PURPLE ORIGIN LTD.
            <br />
            <br />
            PROHIBITED BUSINESSES. THE FOLLOWING CATEGORIES OF BUSINESSES, BUSINESS PRACTICES, AND SALE ITEMS ARE BARRED
            FROM THE SERVICES (“PROHIBITED BUSINESSES”). THE SPECIFIC TYPES OF USE LISTED BELOW ARE REPRESENTATIVE, BUT
            NOT EXHAUSTIVE, OF PROHIBITED BUSINESSES. IF YOU ARE UNCERTAIN AS TO WHETHER OR NOT YOUR USE OF THE SERVICES
            INVOLVES A PROHIBITED BUSINESS, OR HAVE QUESTIONS ABOUT HOW THESE REQUIREMENTS APPLY TO YOU OR YOUR
            CUSTOMERS, PLEASE CONTACT US AT CONTACT@XION.GLOBAL. BY OPENING AN ACCOUNT, YOU CONFIRM THAT YOU OR YOUR
            CUSTOMERS WILL NOT USE THE SERVICES IN CONNECTION WITH ANY OF FOLLOWING BUSINESSES, ACTIVITIES, PRACTICES,
            OR ITEMS:
            <br />
            <br />
            RESTRICTED FINANCIAL SERVICES: CHECK CASHING, BAIL BONDS, COLLECTIONS AGENCIES.
            <br />
            INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS INFRINGEMENT: SALES, DISTRIBUTION, OR ACCESS TO COUNTERFEIT
            MUSIC, MOVIES, SOFTWARE, OR OTHER LICENSED MATERIALS WITHOUT THE APPROPRIATE AUTHORIZATION FROM THE RIGHTS
            HOLDER.
            <br />
            COUNTERFEIT OR UNAUTHORIZED GOODS: UNAUTHORIZED SALE OR RESALE OF BRAND NAME OR DESIGNER PRODUCTS OR
            SERVICES; SALE OF GOODS OR SERVICES THAT ARE ILLEGALLY IMPORTED OR EXPORTED OR WHICH ARE STOLEN. REGULATED
            PRODUCTS AND SERVICES: MARIJUANA DISPENSARIES AND RELATED BUSINESSES; SALE OF TOBACCO, E- CIGARETTES, AND
            E-LIQUID; ONLINE PRESCRIPTION OR PHARMACEUTICAL SERVICES; AGE-RESTRICTED GOODS OR SERVICES; WEAPONS AND
            MUNITIONS; GUNPOWDER AND OTHER EXPLOSIVES; FIREWORKS AND RELATED GOODS; TOXIC, FLAMMABLE, AND RADIOACTIVE
            MATERIALS; PRODUCTS AND SERVICES WITH VARYING LEGAL STATUS ON A STATE-BY-STATE BASIS.
            <br />
            DRUGS AND DRUG PARAPHERNALIA: SALE OF NARCOTICS, CONTROLLED SUBSTANCES, AND ANY EQUIPMENT DESIGNED FOR
            MAKING OR USING DRUGS, SUCH AS BONGS, VAPORIZERS, AND HOOKAHS.
            <br />
            PSEUDO-PHARMACEUTICALS: PHARMACEUTICALS AND OTHER PRODUCTS THAT MAKE HEALTH CLAIMS THAT HAVE NOT BEEN
            APPROVED OR VERIFIED BY THE APPLICABLE LOCAL AND/OR NATIONAL REGULATORY BODY.
            <br />
            SUBSTANCES DESIGNED TO MIMIC ILLEGAL DRUGS: SALE OF A LEGAL SUBSTANCE THAT PROVIDES THE SAME EFFECT AS AN
            ILLEGAL DRUG (E.G., SALVIA, KRATOM).
            <br />
            ADULT CONTENT AND SERVICES: PORNOGRAPHY (INCLUDING LITERATURE, IMAGERY AND OTHER MEDIA); SITES OFFERING ANY
            SEXUALLY-RELATED SERVICES SUCH AS PROSTITUTION, ESCORTS, PAY-PER VIEW, OR ADULT LIVE CHAT FEATURES.
            <br />
            MULTI-LEVEL MARKETING: PYRAMID SCHEMES, NETWORK MARKETING, AND REFERRAL MARKETING PROGRAMS. UNFAIR,
            PREDATORY OR DECEPTIVE PRACTICES: INVESTMENT OPPORTUNITIES OR OTHER SERVICES THAT PROMISE HIGH REWARDS; SALE
            OR RESALE OF A SERVICE WITHOUT ADDED BENEFIT TO THE BUYER; RESALE OF GOVERNMENT OFFERINGS WITHOUT
            AUTHORIZATION OR ADDED VALUE; SITES THAT WE DETERMINE IN OUR SOLE DISCRETION TO BE UNFAIR, DECEPTIVE, OR
            PREDATORY TOWARDS CONSUMERS.
            <br />
            WEAPONS MANUFACTURERS/VENDORS.
            <br />
            CROWDFUNDING.
            <br />
            RELIGIOUS/SPIRITUAL ORGANIZATIONS: OPERATION OF A FOR- PROFIT RELIGIOUS OR SPIRITUAL ORGANIZATION.
          </p>
        </div>
      </div>
      <div className="section-9">
        <div className="frame-23">
          <div className="text-wrapper-33">PRIVACY POLICY</div>
          <p className="last-updated-3">
            LAST UPDATED: SEPTEMBER 22, 2021
            <br />
            <br />
            XION GLOBAL IS A SERVICE THAT SIMPLIFIES THE ACCEPTANCE OF CRYPTOCURRENCIES AS PAYMENT FOR GOODS AND
            SERVICES PROVIDED BY PURPLE ORIGIN LTD. (“XION GLOBAL” OR “XION” OR “WE” OR “US” OR “OUR”). THIS PRIVACY
            POLICY (THE “POLICY”) HELPS EXPLAIN HOW WE COLLECT, USE, STORE, AND PROTECT YOUR INFORMATION WHEN YOU USE
            OUR WEBSITE AT HTTPS://XION.GLOBAL AND HTTPS://XION.APP , THE XION GLOBAL SERVICE OR ANY XION GLOBAL API OR
            THIRD PARTY APPLICATIONS RELYING ON SUCH API, OR ANY OTHER WEBSITES, PAGES, FEATURES, OR CONTENT RELATED TO
            THE XION GLOBAL SERVICE (COLLECTIVELY THE “SERVICES”). PLEASE READ THIS POLICY CAREFULLY ALONG WITH THE XION
            GLOBAL TERMS OF SERVICE (THE “TERMS”), WHICH DESCRIBE THE TERMS UNDER WHICH YOU USE THE SERVICES. IF YOU
            HAVE ANY QUESTIONS, PLEASE CONTACT US AT CONTACT@XION.GLOBAL.
            <br />
            <br />
            ACCEPTANCE OF PRIVACY POLICY
            <br />
            BY ACCESSING AND USING OUR SERVICES, YOU SIGNIFY YOUR ACCEPTANCE TO THE TERMS OF THIS POLICY. IF YOU DO NOT
            AGREE WITH OR YOU ARE NOT COMFORTABLE WITH ANY ASPECT OF THIS POLICY, YOU SHOULD IMMEDIATELY DISCONTINUE
            ACCESS OR USE OF OUR SERVICES.
            <br />
            <br />
            CHANGES TO THIS POLICY
            <br />
            WE RESERVE THE RIGHT TO MODIFY THIS POLICY AT ANY TIME, AND WHEN REQUIRED BY LAW, WE WILL NOTIFY YOU OF
            CHANGES TO THIS POLICY. IF WE MAKE ANY MATERIAL CHANGES WE WILL NOTIFY YOU VIA THE EMAIL CURRENT ON YOUR
            ACCOUNT WITH US OR BY MEANS OF A NOTICE ON OUR SERVICES PRIOR TO THE CHANGE BECOMING EFFECTIVE. XION GLOBAL
            MAY PROVIDE ADDITIONAL “JUST-IN-TIME” DISCLOSURES OR ADDITIONAL INFORMATION ABOUT THE DATA COLLECTION, USE
            AND SHARING PRACTICES OF SPECIFIC SERVICES. THESE NOTICES MAY SUPPLEMENT OR CLARIFY OUR PRIVACY PRACTICES OR
            MAY PROVIDE YOU WITH ADDITIONAL CHOICES ABOUT HOW WE PROCESS YOUR PERSONAL INFORMATION.
            <br />
            <br />
            OUR RELATIONSHIP TO YOU
            <br />
            FOR YOU TO UNDERSTAND OUR DATA PROTECTION OBLIGATIONS AND YOUR RIGHTS TO YOUR PERSONAL INFORMATION UNDER
            THIS POLICY, IT IS IMPORTANT THAT YOU IDENTIFY WHICH RELATIONSHIP(S) YOU HAVE WITH US. PURPLE ORIGIN LTD.
            (“XION GLOBAL”) HAS THE FOLLOWING RELATIONSHIPS:
            <br />
            <br />A “USER” IS AN INDIVIDUAL PROVIDING PERSONAL INFORMATION TO US VIA OUR WEBSITE OR OTHER SERVICES,
            PRODUCTS OR PLATFORMS, SUCH AS BY SIGNING UP FOR OUR NEWSLETTER OR MAKING AN ACCOUNT BY CONNECTING THEIR WEB
            3 WALLET AND POSTING ON THE FORUMS AND PROCESSING PAYMENTS. XION GLOBAL IS A “DATA CONTROLLER” IN
            RELATIONSHIP TO ITS USERS.
            <br />A “CUSTOMER” IS A SPECIFIC TYPE OF USER THAT HAS ENGAGED US TO ACT AS AN AGENT (OR, AS A “DATA
            PROCESSOR”) BY OBTAINING OUR SERVICES. XION GLOBAL IS A “DATA PROCESSOR” IN RELATIONSHIP TO CUSTOMERS.
            <br />
            XION GLOBAL DOES NOT HAVE A DIRECT RELATIONSHIP WITH CUSTOMERS’ END USERS. A “CUSTOMER END USER” IS AN
            INDIVIDUAL THAT PROVIDES THEIR PERSONAL INFORMATION TO CUSTOMERS. WE DO NOT CONTROL THE PURPOSES OR THE
            MEANS BY WHICH THIS PERSONAL INFORMATION IS COLLECTED, AND WE ARE NOT IN A DIRECT RELATIONSHIP WITH CUSTOMER
            END USERS.
            <br />
            <br />
            HEREINAFTER WE MAY REFER TO CUSTOMERS AND USERS COLLECTIVELY AS “YOU.”
            <br />
            <br />
            INFORMATION WE COLLECTWE RECEIVE OR COLLECT PERSONAL INFORMATION WHEN WE OPERATE AND PROVIDE OUR SERVICES,
            INCLUDING WHEN YOU INSTALL, ACCESS, OR USE OUR SERVICES. PERSONAL INFORMATION IS DATA THAT CAN BE USED TO
            IDENTIFY YOU DIRECTLY OR INDIRECTLY, OR TO CONTACT YOU. OUR POLICY GOVERNS ALL PERSONAL INFORMATION WE
            COLLECT ABOUT YOU AND OBTAIN FROM THIRD PARTY SOURCES. THIS POLICY DOES NOT APPLY TO ANONYMIZED INFORMATION
            AS IT CANNOT BE USED TO IDENTIFY YOU. GENERALLY, WE COLLECT INFORMATION TO PROVIDE YOU WITH OUR SERVICES.
            EXCEPT AS DESCRIBED IN THIS POLICY, WE WILL NOT SELL, LEASE, RENT OR LOAN YOUR PERSONAL INFORMATION TO ANY
            THIRD PARTY WITHOUT YOUR PRIOR CONSENT.
            <br />
            <br />
            INFORMATION YOU PROVIDE
            <br />
            YOUR ACCOUNT INFORMATION – TO CREATE A XION GLOBAL ACCOUNT, YOU MUST CONNECT YOUR WEB 3 WALLET AND SUBMIT
            YOUR FULL NAME, EMAIL ADDRESS, PHONE NUMBER AND SHIPPING ADDRESS WHERE APPLICABLE.
            <br />
            YOUR PRODUCTS AND SERVICES – WE RETAIN INFORMATION SUBMITTED BY YOU WHEN YOU CREATE EACH PAYMENT BUTTON OR
            PAYMENT LINK, WHICH MAY INCLUDE YOUR PRODUCT OR SERVICE NAME, PRICE AND DESCRIPTION.
            <br />
            YOUR CRYPTOCURRENCY ADDRESSES – WE RETAIN YOUR PUBLIC CRYPTOCURRENCY ADDRESSES GENERATED IN THE COURSE OF
            YOUR USE OF THE SERVICE.
            <br />
            YOUR TRANSACTIONS – WE RETAIN ANY RECORDS, HISTORIES, OR METADATA FOR THE TRANSACTIONS YOU CONDUCT ON THE
            APPLICABLE BLOCKCHAIN. IF YOU REQUEST PERSONAL INFORMATION FROM YOUR CUSTOMERS AS PART OF THE TRANSACTION,
            WE RETAIN A COPY OF THIS INFORMATION. TO MONITOR CRYPTOCURRENCY TRANSACTIONS AND PROVIDE YOU WITH ACCOUNT
            BALANCE INFORMATION, WE STORE THE PUBLIC KEYS ASSOCIATED WITH YOUR PRIVATE KEYS.
            <br />
            CUSTOMER SUPPORT – WE COLLECT ANY ADDITIONAL INFORMATION YOU DISCLOSE TO OUR CUSTOMER SUPPORT TEAM.
            <br />
            <br />
            INFORMATION WE COLLECT FROM OUR AFFILIATES
            <br />
            OUR “FAMILY OF COMPANIES” IS THE GROUP OF COMPANIES RELATED TO US BY COMMON CONTROL OR OWNERSHIP
            (“AFFILIATES”). IN ACCORDANCE WITH APPLICABLE LAW, WE MAY OBTAIN INFORMATION ABOUT YOU FROM OUR AFFILIATES
            IN THE NORMAL COURSE OF BUSINESS TO PROVIDE THE SERVICES AND AFFILIATES’ SERVICES TO YOU (E.G., CONVERT
            CRYPTOCURRENCY INTO OTHER CRYPTOCURRENCIES AND/OR FIAT WITHDRAWALS INTO YOUR BANK ACCOUNT).
            <br />
            <br />
            AUTOMATICALLY COLLECTED INFORMATION
            <br />
            METRICS AND PERFORMANCE DATA – WE COLLECT SERVICE-RELATED, DIAGNOSTIC, AND PERFORMANCE INFORMATION. THIS
            INCLUDES HIGH LEVEL INFORMATION ABOUT YOUR ACTIVITY (SUCH AS HOW YOU USE OUR SERVICES AND HOW YOU INTERACT
            WITH OTHERS USING OUR SERVICES), AND DIAGNOSTIC, CRASH, WEBSITE, AND PERFORMANCE LOGS AND REPORTS.
            <br />
            DEVICE AND CONNECTION INFORMATION – WE COLLECT DEVICE- SPECIFIC INFORMATION WHEN YOU INSTALL, ACCESS, OR USE
            OUR SERVICES, INCLUDING YOUR IP ADDRESS AND YOUR WEB-BROWSER SOFTWARE.
            <br />
            <br />
            HOW WE USE THE INFORMATION WE COLLECT
            <br />
            OUR PRIMARY PURPOSE IN COLLECTING INFORMATION IS TO HELP US OPERATE, PROVIDE, IMPROVE, CUSTOMIZE, SUPPORT,
            AND MARKET OUR SERVICES.WE MAY USE YOUR INFORMATION:
            <br />
            <br />
            TO PROVIDE XION GLOBAL SERVICES – WE PROCESS YOUR PERSONAL INFORMATION TO PROVIDE THE SERVICES. FOR EXAMPLE,
            IF YOU WANT TO USE OUR SERVICES TO ACCEPT DIGITAL CURRENCY ON YOUR WEBSITE, WE REQUIRE THAT YOU COMPLETE AND
            SUCCESSFULLY VERIFY YOUR KYC(KNOW YOUR CUSTOMER), ADDITIONALLY PROVIDE US WITH YOUR FULL NAME, EMAIL
            ADDRESS, PHONE NUMBER, SHIPPING ADDRESS WHERE APPLICABLE. WE CANNOT PROVIDE YOU WITH SERVICES WITHOUT SUCH
            INFORMATION.
            <br />
            <br />
            TO PROVIDE SERVICE COMMUNICATIONS – WE SEND ADMINISTRATIVE OR ACCOUNT-RELATED INFORMATION TO YOU TO KEEP YOU
            UPDATED ABOUT OUR SERVICES, INFORM YOU OF RELEVANT SECURITY ISSUES OR UPDATES, OR PROVIDE OTHER
            TRANSACTION-RELATED INFORMATION. WITHOUT SUCH COMMUNICATIONS, YOU MAY NOT BE AWARE OF IMPORTANT DEVELOPMENTS
            RELATING TO YOUR ACCOUNT THAT MAY AFFECT HOW YOU CAN USE OUR SERVICES.
            <br />
            <br />
            TO PROVIDE CUSTOMER SERVICE – WE PROCESS YOUR PERSONAL INFORMATION WHEN YOU CONTACT US TO RESOLVE ANY
            QUESTIONS, DISPUTES, OR TO TROUBLESHOOT PROBLEMS. WITHOUT PROCESSING YOUR PERSONAL INFORMATION FOR SUCH
            PURPOSES, WE CANNOT RESPOND TO YOUR REQUESTS AND ENSURE YOUR UNINTERRUPTED USE OF THE SERVICES.
            <br />
            <br />
            TO ENSURE QUALITY CONTROL – WE PROCESS YOUR PERSONAL INFORMATION FOR QUALITY CONTROL AND STAFF TRAINING TO
            MAKE SURE WE CONTINUE TO PROVIDE YOU WITH ACCURATE INFORMATION. IF WE DO NOT PROCESS PERSONAL INFORMATION
            FOR QUALITY CONTROL PURPOSES, YOU MAY EXPERIENCE ISSUES ON THE SERVICES SUCH AS INACCURATE TRANSACTION
            RECORDS OR OTHER INTERRUPTIONS. DESIGNATED COUNTRIES: FOR INDIVIDUALS WHO RESIDE IN THE UNITED KINGDOM,
            EUROPEAN ECONOMIC AREA OR SWITZERLAND (COLLECTIVELY THE “DESIGNATED COUNTRIES”), PURSUANT TO EU GENERAL DATA
            PROTECTION REGULATION (“GDPR”) OR ANY EQUIVALENT LEGISLATION (COLLECTIVELY “DC DATA PROTECTION LAWS”), WE
            PROCESS THIS PERSONAL INFORMATION BASED ON OUR CONTRACT WITH YOU.
            <br />
            <br />
            FOR RESEARCH AND DEVELOPMENT PURPOSES – WE PROCESS YOUR PERSONAL INFORMATION TO BETTER UNDERSTAND YOU AND,
            IF APPLICABLE, YOUR END-USERS AND THE WAY YOU USE AND INTERACT WITH OUR SERVICES. IN ADDITION, WE USE SUCH
            INFORMATION TO CUSTOMIZE, MEASURE, AND IMPROVE OUR SERVICES AND THE CONTENT AND LAYOUT OF OUR WEBSITE AND
            APPLICATIONS, AND TO DEVELOP NEW SERVICES. WITHOUT SUCH PROCESSING, WE CANNOT ENSURE YOUR CONTINUED
            ENJOYMENT OF OUR SERVICES. DESIGNATED COUNTRIES: PURSUANT TO DC DATA PROTECTION LAWS, WE PROCESS THIS
            PERSONAL INFORMATION TO SATISFY OUR LEGITIMATE INTERESTS AS DESCRIBED ABOVE.
            <br />
            <br />
            TO ENHANCE YOUR WEBSITE EXPERIENCE – WE PROCESS YOUR PERSONAL INFORMATION TO PROVIDE A PERSONALIZED
            EXPERIENCE, AND IMPLEMENT THE PREFERENCES YOU REQUEST. WITHOUT SUCH PROCESSING, WE MAY NOT BE ABLE TO ENSURE
            YOUR CONTINUED ENJOYMENT OF PART OR ALL OF OUR SERVICES. DESIGNATED COUNTRIES: PURSUANT TO DC DATA
            PROTECTION LAWS, WE PROCESS THIS PERSONAL INFORMATION TO SATISFY OUR LEGITIMATE INTERESTS AS DESCRIBED
            ABOVE.
            <br />
            <br />
            TO FACILITATE CORPORATE ACQUISITIONS, MERGERS, OR TRANSACTIONS – WE MAY PROCESS ANY INFORMATION REGARDING
            YOUR ACCOUNT AND USE OF OUR SERVICES AS IS NECESSARY IN THE CONTEXT OF CORPORATE ACQUISITIONS, MERGERS, OR
            OTHER CORPORATE TRANSACTIONS. YOU HAVE THE OPTION OF CLOSING YOUR ACCOUNT BY REQUEST IF YOU DO NOT WISH TO
            HAVE YOUR PERSONAL INFORMATION PROCESSED FOR SUCH PURPOSES. DESIGNATED COUNTRIES: PURSUANT TO DC DATA
            PROTECTION LAWS, WE PROCESS THIS PERSONAL INFORMATION TO SATISFY OUR LEGITIMATE INTERESTS AS DESCRIBED
            ABOVE.
            <br />
            <br />
            TO ENGAGE IN MARKETING ACTIVITIES – BASED ON YOUR COMMUNICATION PREFERENCES, WE MAY SEND YOU MARKETING
            COMMUNICATIONS TO INFORM YOU ABOUT RELEVANT PRODUCT OFFERS AND SERVICES; TO DELIVER TARGETED MARKETING; AND
            TO PROVIDE YOU WITH PROMOTIONAL OFFERS BASED ON YOUR COMMUNICATION PREFERENCES. WE USE INFORMATION ABOUT
            YOUR USAGE OF OUR SERVICES AND YOUR CONTACT INFORMATION TO PROVIDE MARKETING COMMUNICATIONS. YOU CAN OPT-OUT
            OF OUR MARKETING COMMUNICATIONS AT ANY TIME. DESIGNATED COUNTRIES: PURSUANT TO DC DATA PROTECTION LAWS, WE
            PROCESS THIS PERSONAL INFORMATION BASED ON YOUR CONSENT OR LEGITIMATE INTEREST TO THE EXTENT PERMITTED BY DC
            DATA PROTECTION LAWS. TO THE EXTENT WE CAN RELY ON LEGITIMATE INTEREST UNDER THE APPLICABLE LAW, WE WILL
            ONLY SEND YOU INFORMATION ABOUT OUR SERVICES THAT ARE SIMILAR TO THOSE WHICH WERE THE SUBJECT OF A PREVIOUS
            SALE OR NEGOTIATIONS OF A SALE TO YOU. YOU MAY RAISE SUCH OBJECTIONS WITH REGARD TO INITIAL OR FURTHER
            PROCESSING FOR PURPOSES OF DIRECT MARKETING, AT ANY TIME AND FREE OF CHARGE.
            <br />
            <br />
            WE WILL NOT USE YOUR INFORMATION FOR PURPOSES OTHER THAN THOSE PURPOSES WE HAVE DISCLOSED TO YOU. FROM TIME
            TO TIME WE MAY REQUEST YOUR PERMISSION TO ALLOW US TO SHARE YOUR PERSONAL INFORMATION WITH THIRD PARTIES.
            YOU MAY OPT OUT OF HAVING YOUR PERSONAL INFORMATION SHARED WITH THIRD PARTIES, OR FROM ALLOWING US TO USE
            YOUR PERSONAL INFORMATION FOR ANY PURPOSE THAT IS INCOMPATIBLE WITH THE PURPOSES FOR WHICH WE ORIGINALLY
            COLLECTED IT OR SUBSEQUENTLY OBTAINED YOUR AUTHORIZATION. IF YOU CHOOSE TO LIMIT THE USE OF YOUR PERSONAL
            INFORMATION, CERTAIN FEATURES OR SERVICES MAY NOT BE AVAILABLE TO YOU.
            <br />
            <br />
            COMMUNICATIONS
            <br />
            WE MAY SEND YOU COMMUNICATIONS THAT ARE RELATED TO YOUR ACCOUNT OR WILL BE RELATED TO OUR SERVICES OR
            INFORMATION WE BELIEVE INTERESTS YOU. IN THE EVENT WE SEND ANY COMMUNICATION TO YOU WHICH IS NOT RELATED
            SPECIFICALLY TO YOUR ACCOUNT, WE WILL PROVIDE YOU WITH AN “UNSUBSCRIBE” MECHANISM THROUGH WHICH YOU MAY OPT
            OUT OF RECEIVING OTHER SIMILAR MESSAGES IN THE FUTURE.
            <br />
            <br />
            HOW YOUR INFORMATION IS SHARED WITH CUSTOMER END USERS
            <br />
            IF YOU USE YOUR ACCOUNT IN A TRANSACTION WITH YOUR CUSTOMER END USER, THAT CUSTOMER END USER WILL HAVE
            ACCESS TO AN ADDRESS ASSOCIATED WITH YOUR PUBLIC KEY ALLOWING THEM TO PAY FOR THEIR PURCHASES, ALONGSIDE ANY
            PERSONAL BUSINESS INFORMATION(COMPANY NAME, WEBSITE, ADDRESS, PHONE NUMBER, PRODUCT INFORMATION, TRANSACTION
            DETAILS). HOW WE SHARE INFORMATION WITH AFFILIATES AND THIRD PARTIES
            <br />
            WE WORK WITH OUR AFFILIATES AND THIRD-PARTY PROVIDERS TO HELP US OPERATE, PROVIDE, IMPROVE, CUSTOMIZE,
            SUPPORT, AND MARKET OUR SERVICES. FOR EXAMPLE, WE WORK WITH COMPANIES TO PROVIDE OUR INFRASTRUCTURE,
            DELIVERY, AND OTHER SYSTEMS. THESE PROVIDERS MAY PROVIDE US INFORMATION ABOUT YOU IN CERTAIN CIRCUMSTANCES.
            WE WILL ALSO SHARE INFORMATION WITH OUR AFFILIATES IN THE NORMAL COURSE OF BUSINESS TO PROVIDE THE SERVICES
            AND AFFILIATE SERVICES. WE MAY ALSO SHARE INFORMATION WITH LAW ENFORCEMENT, GOVERNMENT OFFICIALS, AFFILIATES
            OR OTHER THIRD PARTIES WHEN:
            <br />
            <br />
            WE ARE COMPELLED TO DO SO BY A SUBPOENA, COURT ORDER, OR SIMILAR LEGAL PROCEDURE; OR
            <br />
            WE BELIEVE IN GOOD FAITH THAT THE DISCLOSURE OF PERSONAL INFORMATION IS NECESSARY TO PREVENT PHYSICAL HARM
            OR FINANCIAL LOSS, TO REPORT SUSPECTED ILLEGAL ACTIVITY OR TO INVESTIGATE VIOLATIONS OF OUR TERMS.
            <br />
            <br />
            HOW WE USE COOKIES
            <br />
            WHEN YOU ACCESS OUR SERVICES, WE OR COMPANIES WE WORK WITH MAY PLACE COOKIES ON YOUR COMPUTER OR OTHER
            DEVICES. THESE TECHNOLOGIES HELP US BETTER UNDERSTAND USER BEHAVIOR, AND INFORM US ABOUT WHICH PARTS OF OUR
            WEBSITES PEOPLE HAVE VISITED.
            <br />
            <br />
            DO-NOT-TRACK
            <br />
            SOME INTERNET BROWSERS – LIKE INTERNET EXPLORER, FIREFOX, AND SAFARI – INCLUDE THE ABILITY TO TRANSMIT “DO
            NOT TRACK” OR “DNT” SIGNALS. SINCE UNIFORM STANDARDS FOR “DNT” SIGNALS HAVE NOT BEEN ADOPTED, OUR SERVICES
            DO NOT CURRENTLY PROCESS OR RESPOND TO “DNT” SIGNALS.
            <br />
            <br />
            CHILDREN <br />
            WE DO NOT KNOWINGLY REQUEST OR COLLECT PERSONAL INFORMATION FROM ANY PERSON UNDER THE AGE OF 18. IF A USER
            SUBMITTING PERSONAL INFORMATION IS SUSPECTED OF BEING YOUNGER THAN 18 YEARS OF AGE, XION GLOBAL WILL REQUIRE
            THE USER TO CLOSE HIS OR HER ACCOUNT BY REQUEST AND WILL NOT ALLOW CONTINUED USE OF THE SERVICES. WE WILL
            ALSO TAKE STEPS TO DELETE THE INFORMATION AS SOON AS POSSIBLE. PLEASE NOTIFY US IF YOU KNOW OF ANY
            INDIVIDUALS UNDER THE AGE OF 18 USING OUR SERVICES SO WE CAN TAKE ACTION TO PREVENT ACCESS TO OUR SERVICES.
            <br />
            <br />
            SINGAPORE PRIVACY RIGHTS
            <br />
            IF YOU ARE A SINGAPORE RESIDENT, YOU HAVE THE RIGHT TO REQUEST INFORMATION FROM US REGARDING THE MANNER IN
            WHICH WE SHARE CERTAIN CATEGORIES OF YOUR PERSONAL INFORMATION WITH THIRD PARTIES FOR THE THIRD PARTIES’
            DIRECT MARKETING PURPOSES. SINGAPORE LAW PROVIDES THAT YOU HAVE THE RIGHT TO SUBMIT A REQUEST TO US AT OUR
            DESIGNATED ADDRESS AND RECEIVE THE FOLLOWING INFORMATION: (A) THE CATEGORIES OF INFORMATION WE DISCLOSED TO
            THIRD PARTIES FOR THE THIRD PARTIES’ DIRECT MARKETING PURPOSES DURING THE PRECEDING CALENDAR YEAR; AND (B)
            THE NAMES AND ADDRESSES OF THIRD PARTIES THAT RECEIVED SUCH INFORMATION, OR IF THE NATURE OF THEIR BUSINESS
            CANNOT BE DETERMINED FROM THE NAME, THEN EXAMPLES OF THE PRODUCTS OR SERVICES MARKETED. YOU ARE ENTITLED TO
            RECEIVE A COPY OF THIS INFORMATION IN A STANDARDIZED FORMAT AND THE INFORMATION WILL NOT BE SPECIFIC TO YOU
            INDIVIDUALLY. YOU MAY MAKE SUCH A REQUEST BY EMAILING CONTACT@XION.GLOBAL.
            <br />
            <br />
            ACCESS AND MODIFICATION OF YOUR PERSONAL INFORMATION
            <br />
            YOU MAY CONTACT US AT CONTACT@XION.GLOBAL TO ACCESS THE PERSONAL INFORMATION WE HOLD ABOUT YOU. IF ANY OF
            THE INFORMATION IS INACCURATE, YOU MAY RECTIFY SUCH INFORMATION. WE RESERVE THE RIGHT TO LIMIT YOUR ACCESS
            OR MODIFICATIONS IF YOUR REQUESTS ARE TOO FREQUENT AND/OR BURDENSOME. FOR INDIVIDUALS RESIDING IN DESIGNATED
            COUNTRIES, PLEASE REFER TO THE “INTERNATIONAL USERS” SECTION BELOW.
            <br />
            <br />
            SECURITY SAFEGUARDS
            <br />
            WE MAINTAIN, AND REQUIRE OUR SERVICE PROVIDERS TO MAINTAIN, APPROPRIATE PHYSICAL, TECHNICAL AND
            ADMINISTRATIVE SAFEGUARDS TO PROTECT THE SECURITY AND CONFIDENTIALITY OF THE PERSONAL INFORMATION YOU
            ENTRUST TO US. HOWEVER, WE CANNOT GUARANTEE THAT LOSS, MISUSE, UNAUTHORIZED ACQUISITION, OR ALTERATION OF
            YOUR DATA WILL NOT OCCUR. PLEASE RECOGNIZE THAT YOU PLAY A VITAL ROLE IN PROTECTING YOUR OWN PERSONAL
            INFORMATION. WHEN REGISTERING WITH OUR SERVICES, YOU NEED TO KEEP YOUR PARAPHRASE AN/OR PRIVATE KEY STORED
            SECURELY AND IMMEDIATELY NOTIFY US IF YOU BECOME AWARE OF ANY UNAUTHORIZED ACCESS TO OR USE OF YOUR ACCOUNT.
            <br />
            FURTHERMORE, WE CANNOT ENSURE OR WARRANT THE SECURITY OR CONFIDENTIALITY OF INFORMATION YOU TRANSMIT TO US
            OR RECEIVE FROM US BY INTERNET OR WIRELESS CONNECTION, INCLUDING EMAIL, PHONE, OR SMS, SINCE WE HAVE NO WAY
            OF PROTECTING THAT INFORMATION ONCE IT LEAVES AND UNTIL IT REACHES US. IF YOU HAVE REASON TO BELIEVE THAT
            YOUR DATA IS NO LONGER SECURE, PLEASE CONTACT US AT THE EMAIL ADDRESS, MAILING ADDRESS OR TELEPHONE NUMBER
            LISTED AT THE END OF THIS PRIVACY POLICY.
            <br />
            <br />
            RETENTION
            <br />
            WE LIMIT OUR RETENTION OF YOUR PERSONAL INFORMATION FOR AS LONG AS NECESSARY TO FULFILL THE PURPOSES FOR
            WHICH WE COLLECTED IT, INCLUDING FOR THE PURPOSES OF SATISFYING ANY LEGAL, ACCOUNTING, OR REPORTING
            OBLIGATIONS OR TO RESOLVE DISPUTES. WHILE RETENTION REQUIREMENTS VARY BY JURISDICTION, INFORMATION ABOUT OUR
            TYPICAL RETENTION PERIODS FOR DIFFERENT ASPECTS OF YOUR PERSONAL INFORMATION ARE DESCRIBED BELOW.
            <br />
            <br />
            EMAIL ADDRESS FOR MARKETING PURPOSES IS RETAINED ON AN ONGOING BASIS UNTIL YOU UN-SUBSCRIBE. THEREAFTER WE
            WILL ADD YOUR DETAILS TO OUR SUPPRESSION LIST INDEFINITELY.
            <br />
            INFORMATION COLLECTED VIA TECHNICAL MEANS SUCH AS COOKIES, WEBPAGE COUNTERS AND OTHER ANALYTICS TOOLS IS
            KEPT FOR A PERIOD OF UP TO ONE YEAR FROM EXPIRY OF THE COOKIE.
            <br />
            <br />
            PLEASE CONTACT US IF YOU HAVE QUESTIONS ABOUT RETENTION PERIODS FOR A PARTICULAR ASPECT OF YOUR PERSONAL
            INFORMATION WHICH IS NOT DETAILED ABOVE.
            <br />
            <br />
            INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION
            <br />
            AS A GLOBAL ENTITY, XION GLOBAL MAY STORE, TRANSFER, AND OTHERWISE PROCESS YOUR PERSONAL INFORMATION IN
            COUNTRIES OUTSIDE OF THE COUNTRY OF YOUR RESIDENCE, INCLUDING SINGAPORE AND POSSIBLY OTHER COUNTRIES. THE
            DATA PROTECTION LAWS OF SUCH COUNTRIES MAY NOT BE AS PROTECTIVE AS THE LAWS OF THE COUNTRY IN WHICH YOU
            RESIDE. TO ENSURE THAT PERSONAL INFORMATION IS ADEQUATELY PROTECTED WHEN TRANSFERRED OUTSIDE THE DESIGNATED
            COUNTRIES, XION GLOBAL RELIES ON STANDARD CONTRACTUAL CLAUSES APPROVED BY THE EUROPEAN COMMISSION.
            <br />
            <br />
            INTERNATIONAL USERS
            <br />
            THIS SECTION ONLY APPLIES TO INDIVIDUALS RESIDING IN A DESIGNATED COUNTRY.
            <br />
            <br />
            LEGAL BASES FOR PROCESSING PERSONAL INFORMATION. OUR LEGAL BASES FOR PROCESSING ARE DESCRIBED IN THE
            SECTIONS ABOVE ENTITLED “HOW WE USE THE INFORMATION WE COLLECT” AND “INFORMATION WE COLLECT.” WE MAY PROCESS
            YOUR PERSONAL INFORMATION IF YOU CONSENT TO THE PROCESSING, TO SATISFY OUR LEGAL OBLIGATIONS, IF IT IS
            NECESSARY TO CARRY OUT OUR OBLIGATIONS ARISING FROM ANY CONTRACTS WE ENTERED WITH YOU OR TO TAKE STEPS AT
            YOUR REQUEST PRIOR TO ENTERING INTO A CONTRACT WITH YOU, OR FOR OUR LEGITIMATE INTERESTS TO PROTECT OUR
            PROPERTY, RIGHTS OR SAFETY OF XION GLOBAL, OUR CUSTOMERS OR OTHERS.
            <br />
            DIRECT MARKETING. IF YOU ARE LOCATED IN THE DESIGNATED COUNTRIES AND ARE A CURRENT CUSTOMER, WE WILL ONLY
            CONTACT YOU BY ELECTRONIC MEANS (EMAIL OR SMS) WITH INFORMATION ABOUT OUR SERVICES THAT ARE SIMILAR TO THOSE
            WHICH WERE THE SUBJECT OF A PREVIOUS SALE OR NEGOTIATIONS OF A SALE TO YOU. IF YOU ARE A NEW CUSTOMER AND
            LOCATED IN THE DESIGNATED COUNTRIES, WE WILL CONTACT YOU BY ELECTRONIC MEANS FOR MARKETING PURPOSES ONLY IF
            YOU HAVE CONSENTED TO SUCH COMMUNICATION. IF YOU NO LONGER WANT US TO USE YOUR PERSONAL INFORMATION IN THIS
            WAY, OR TO PASS YOUR PERSONAL INFORMATION ON TO THIRD PARTIES FOR MARKETING PURPOSES, PLEASE OPT OUT OF THE
            EMAILS BY CLICKING THE “UNSUBSCRIBE” LINK IN THE FOOTER OF OUR EMAIL OR CONTACT US AT CONTACT@XION.GLOBAL.
            YOU MAY RAISE SUCH OBJECTIONS WITH REGARD TO INITIAL OR FURTHER PROCESSING FOR PURPOSES OF DIRECT MARKETING,
            AT ANY TIME AND FREE OF CHARGE. DIRECT MARKETING INCLUDES ANY COMMUNICATIONS TO YOU THAT ARE ONLY BASED ON
            ADVERTISING OR PROMOTING PRODUCTS AND SERVICES INDIVIDUAL RIGHTS. RESIDENTS OF DESIGNATED COUNTRIES HAVE THE
            FOLLOWING RIGHTS, WHICH CAN BE EXERCISED BY CONTACTING US AT CONTACT@XION.GLOBAL SO THAT WE MAY CONSIDER
            YOUR REQUEST UNDER APPLICABLE LAW. RIGHT TO WITHDRAW CONSENT. YOU HAVE THE RIGHT TO WITHDRAW YOUR CONSENT TO
            THE PROCESSING OF YOUR PERSONAL INFORMATION COLLECTED ON THE BASIS OF YOUR CONSENT AT ANY TIME. YOUR
            WITHDRAWAL WILL NOT AFFECT THE LAWFULNESS OF XION GLOBAL’S PROCESSING BASED ON CONSENT BEFORE YOUR
            WITHDRAWAL.
            <br />
            RIGHT OF ACCESS TO AND RECTIFICATION OF YOUR PERSONAL INFORMATION. YOU HAVE A RIGHT TO REQUEST THAT WE
            PROVIDE YOU A COPY OF YOUR PERSONAL INFORMATION HELD BY US. THIS INFORMATION WILL BE PROVIDED WITHOUT UNDUE
            DELAY SUBJECT TO SOME FEE ASSOCIATED WITH GATHERING OF THE INFORMATION (AS PERMITTED BY LAW), UNLESS SUCH
            PROVISION ADVERSELY AFFECTS THE RIGHTS AND FREEDOMS OF OTHERS. YOU MAY ALSO REQUEST US TO RECTIFY OR UPDATE
            ANY OF YOUR PERSONAL INFORMATION HELD BY US THAT IS INACCURATE.
            <br />
            RIGHT TO ERASURE. YOU HAVE THE RIGHT TO REQUEST ERASURE OF YOUR PERSONAL INFORMATION THAT: (A) IS NO LONGER
            NECESSARY IN RELATION TO THE PURPOSES FOR WHICH IT WAS COLLECTED OR OTHERWISE PROCESSED; (B) WAS COLLECTED
            IN RELATION TO PROCESSING THAT YOU PREVIOUSLY CONSENTED, BUT LATER WITHDRAW SUCH CONSENT; OR (C) WAS
            COLLECTED IN RELATION TO PROCESSING ACTIVITIES TO WHICH YOU OBJECT, AND THERE ARE NO OVERRIDING LEGITIMATE
            GROUNDS FOR OUR PROCESSING. IF WE HAVE MADE YOUR PERSONAL INFORMATION PUBLIC AND ARE OBLIGED TO ERASE THE
            PERSONAL INFORMATION, WE WILL, TAKING ACCOUNT OF AVAILABLE TECHNOLOGY AND THE COST OF IMPLEMENTATION, TAKE
            REASONABLE STEPS, INCLUDING TECHNICAL MEASURES, TO INFORM OTHER PARTIES THAT ARE PROCESSING YOUR PERSONAL
            INFORMATION THAT YOU HAVE REQUESTED THE ERASURE OF ANY LINKS TO, OR COPY OR REPLICATION OF YOUR PERSONAL
            INFORMATION. THE ABOVE IS SUBJECT TO LIMITATIONS BY RELEVANT DATA PROTECTION LAWS.
            <br />
            RIGHT TO DATA PORTABILITY. IF WE PROCESS YOUR PERSONAL INFORMATION BASED ON A CONTRACT WITH YOU OR BASED ON
            YOUR CONSENT, OR THE PROCESSING IS CARRIED OUT BY AUTOMATED MEANS, YOU MAY REQUEST TO RECEIVE YOUR PERSONAL
            INFORMATION IN A STRUCTURED, COMMONLY USED AND MACHINE-READABLE FORMAT, AND TO HAVE US TRANSFER YOUR
            PERSONAL INFORMATION DIRECTLY TO ANOTHER “CONTROLLER”, WHERE TECHNICALLY FEASIBLE, UNLESS EXERCISE OF THIS
            RIGHT ADVERSELY AFFECTS THE RIGHTS AND FREEDOMS OF OTHERS. A “CONTROLLER” IS A NATURAL OR LEGAL PERSON,
            PUBLIC AUTHORITY, AGENCY OR OTHER BODY WHICH, ALONE OR JOINTLY WITH OTHERS, DETERMINES THE PURPOSES AND
            MEANS OF THE PROCESSING OF YOUR PERSONAL INFORMATION.
            <br />
            RIGHT TO RESTRICTION OF OR PROCESSING. YOU HAVE THE RIGHT TO RESTRICT OR OBJECT TO US PROCESSING YOUR
            PERSONAL INFORMATION WHERE ONE OF THE FOLLOWING APPLIES:
            <br />
            <br />
            YOU CONTEST THE ACCURACY OF YOUR PERSONAL INFORMATION THAT WE PROCESSED. IN SUCH INSTANCES, WE WILL RESTRICT
            PROCESSING DURING THE PERIOD NECESSARY FOR US TO VERIFY THE ACCURACY OF YOUR PERSONAL INFORMATION. THE
            PROCESSING IS UNLAWFUL AND YOU OPPOSE THE ERASURE OF YOUR PERSONAL INFORMATION AND REQUEST THE RESTRICTION
            OF ITS USE INSTEAD. WE NO LONGER NEED YOUR PERSONAL INFORMATION FOR THE PURPOSES OF THE PROCESSING, BUT IT
            IS REQUIRED BY YOU TO ESTABLISH, EXERCISE OR DEFENSE OF LEGAL CLAIMS. YOU HAVE OBJECTED TO PROCESSING,
            PENDING THE VERIFICATION WHETHER THE LEGITIMATE GROUNDS OF OUR PROCESSING OVERRIDE YOUR RIGHTS.
            <br />
            <br />
            RESTRICTED PERSONAL INFORMATION SHALL ONLY BE PROCESSED WITH YOUR CONSENT OR FOR THE ESTABLISHMENT, EXERCISE
            OR DEFENSE OF LEGAL CLAIMS OR FOR THE PROTECTION OF THE RIGHTS OF ANOTHER NATURAL OR LEGAL PERSON OR FOR
            REASONS OF IMPORTANT PUBLIC INTEREST. WE WILL INFORM YOU IF THE RESTRICTION IS LIFTED.
            <br />
            NOTIFICATION OF ERASURE, RECTIFICATION AND RESTRICTION. WE WILL COMMUNICATE ANY RECTIFICATION OR ERASURE OF
            YOUR PERSONAL INFORMATION OR RESTRICTION OF PROCESSING TO EACH RECIPIENT TO WHOM YOUR PERSONAL INFORMATION
            HAS BEEN DISCLOSED, UNLESS THIS PROVES IMPOSSIBLE OR INVOLVES DISPROPORTIONATE EFFORT. WE WILL INFORM YOU
            ABOUT THOSE RECIPIENTS IF YOU REQUEST THIS INFORMATION.
            <br />
            RIGHT TO OBJECT TO PROCESSING. WHERE THE PROCESSING OF YOUR PERSONAL INFORMATION IS BASED ON CONSENT,
            CONTRACT OR LEGITIMATE INTERESTS YOU MAY RESTRICT OR OBJECT, AT ANY TIME, TO THE PROCESSING OF YOUR PERSONAL
            INFORMATION AS PERMITTED BY APPLICABLE LAW. WE CAN CONTINUE TO PROCESS YOUR PERSONAL INFORMATION IF IT IS
            NECESSARY FOR THE DEFENSE OF LEGAL CLAIMS, OR FOR ANY OTHER EXCEPTIONS PERMITTED BY APPLICABLE LAW.
            <br />
            AUTOMATED INDIVIDUAL DECISION-MAKING, INCLUDING PROFILING. YOU HAVE THE RIGHT NOT TO BE SUBJECT TO A
            DECISION BASED SOLELY ON AUTOMATED PROCESSING OF YOUR PERSONAL INFORMATION, INCLUDING PROFILING, WHICH
            PRODUCES LEGAL OR SIMILARLY SIGNIFICANT EFFECTS ON YOU, SAVE FOR THE EXCEPTIONS APPLICABLE UNDER RELEVANT
            DATA PROTECTION LAWS.
            <br />
            RIGHT TO LODGE A COMPLAINT. IF YOU BELIEVE THAT WE HAVE INFRINGED YOUR RIGHTS, WE ENCOURAGE YOU TO CONTACT
            US FIRST AT CONTACT@XION.GLOBAL SO THAT WE CAN TRY TO RESOLVE YOUR ISSUE OR DISPUTE INFORMALLY. YOU ALSO
            HAVE A RIGHT TO LODGE A COMPLAINT WITH A COMPETENT SUPERVISORY AUTHORITY SITUATED IN A MEMBER STATE OF YOUR
            HABITUAL RESIDENCE, PLACE OF WORK, OR PLACE OF ALLEGED INFRINGEMENT.
            <br />
            STORAGE OF YOUR PERSONAL INFORMATION. WE WILL TRY TO LIMIT THE STORAGE OF YOUR PERSONAL INFORMATION TO THE
            EXTENT THAT STORAGE IS NECESSARY TO SERVE THE PURPOSE(S) FOR WHICH THE PERSONAL INFORMATION WAS PROCESSED,
            TO RESOLVE DISPUTES, ENFORCE OUR AGREEMENTS, AND AS REQUIRED OR PERMITTED BY LAW.
            <br />
            DATA PROTECTION OFFICER: YOU MAY REACH OUR DATA PROTECTION OFFICER AT CONTACT@XION.GLOBAL.
            <br />
            <br />
            YOUR RIGHTS TO PERSONAL INFORMATION ARE NOT ABSOLUTE. ACCESS MAY BE DENIED WHEN:
            <br />
            <br />
            DENIAL OF ACCESS IS REQUIRED OR AUTHORIZED BY LAW;
            <br />
            GRANTING ACCESS WOULD HAVE A NEGATIVE IMPACT ON OTHER’S PRIVACY;
            <br />
            TO PROTECT OUR RIGHTS AND PROPERTIES; AND
            <br />
            WHERE THE REQUEST IS FRIVOLOUS OR VEXATIOUS.
          </p>
        </div>
      </div>
      <div className="section-10">
        <div className="frame-24">
          <div className="text-wrapper-33">NOTICE AND DISCLAIMER</div>
          <p className="PLEASE-READ-THE">
            PLEASE READ THE ENTIRETY OF THIS “NOTICE AND DISCLAIMER” SECTION CAREFULLY. NOTHING HEREIN CONSTITUTES
            LEGAL, FINANCIAL, BUSINESS OR TAX ADVICE AND YOU SHOULD CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR OTHER
            PROFESSIONAL ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY IN CONNECTION HEREWITH. NEITHER MERCX GROUP LIMITED
            (THE COMPANY), ANY OF THE PROJECT TEAM MEMBERS (THE XION TEAM) WHO HAVE WORKED ON XION GLOBAL (AS DEFINED
            HEREIN) OR PROJECT TO DEVELOP XION GLOBAL IN ANY WAY WHATSOEVER, ANY DISTRIBUTOR/VENDOR OF XGT TOKENS (THE
            DISTRIBUTOR), NOR ANY SERVICE PROVIDER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR INDIRECT DAMAGE OR LOSS
            WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH ACCESSING THIS WHITEPAPER, THE WEBSITE AT
            HTTPS://WWW.XION.GLOBAL/ (THE WEBSITE) OR ANY OTHER WEBSITES OR MATERIALS PUBLISHED BY THE COMPANY.
            <br />
            PROJECT PURPOSE: YOU AGREE THAT YOU ARE ACQUIRING XGT TO PARTICIPATE IN XION GLOBAL AND TO OBTAIN SERVICES
            ON THE ECOSYSTEM THEREON. THE COMPANY, THE DISTRIBUTOR AND THEIR RESPECTIVE AFFILIATES WOULD DEVELOP AND
            CONTRIBUTE TO THE UNDERLYING SOURCE CODE FOR XION GLOBAL. THE COMPANY IS ACTING SOLELY AS AN ARMS’ LENGTH
            THIRD PARTY IN RELATION TO THE XGT DISTRIBUTION, AND NOT IN THE CAPACITY AS A FINANCIAL ADVISOR OR FIDUCIARY
            OF ANY PERSON WITH REGARD TO THE DISTRIBUTION OF XGT.
            <br />
            <br />
            NATURE OF THE WHITEPAPER: THE WHITEPAPER AND THE WEBSITE ARE INTENDED FOR GENERAL INFORMATIONAL PURPOSES
            ONLY AND DO NOT CONSTITUTE A PROSPECTUS, AN OFFER DOCUMENT, AN OFFER OF SECURITIES, A SOLICITATION FOR
            INVESTMENT, OR ANY OFFER TO SELL ANY PRODUCT, ITEM, OR ASSET (WHETHER DIGITAL OR OTHERWISE). THE INFORMATION
            HEREIN MAY NOT BE EXHAUSTIVE AND DOES NOT IMPLY ANY ELEMENT OF A CONTRACTUAL RELATIONSHIP. THERE IS NO
            ASSURANCE AS TO THE ACCURACY OR COMPLETENESS OF SUCH INFORMATION AND NO REPRESENTATION, WARRANTY OR
            UNDERTAKING IS OR PURPORTED TO BE PROVIDED AS TO THE ACCURACY OR COMPLETENESS OF SUCH INFORMATION. WHERE THE
            WHITEPAPER OR THE WEBSITE INCLUDES INFORMATION THAT HAS BEEN OBTAINED FROM THIRD PARTY SOURCES, THE COMPANY,
            THE DISTRIBUTOR, THEIR RESPECTIVE AFFILIATES AND/OR THE XION TEAM HAVE NOT INDEPENDENTLY VERIFIED THE
            ACCURACY OR COMPLETENESS OF SUCH INFORMATION. FURTHER, YOU ACKNOWLEDGE THAT CIRCUMSTANCES MAY CHANGE AND
            THAT THE WHITEPAPER OR THE WEBSITE MAY BECOME OUTDATED AS A RESULT; AND NEITHER THE COMPANY NOR THE
            DISTRIBUTOR IS UNDER ANY OBLIGATION TO UPDATE OR CORRECT THIS DOCUMENT IN CONNECTION THEREWITH.
            <br />
            <br />
            TOKEN DOCUMENTATION: NOTHING IN THE WHITEPAPER OR THE WEBSITE CONSTITUTES ANY OFFER BY THE COMPANY, THE
            DISTRIBUTOR, OR THE XION TEAM TO SELL ANY XGT (AS DEFINED HEREIN) NOR SHALL IT OR ANY PART OF IT NOR THE
            FACT OF ITS PRESENTATION FORM THE BASIS OF, OR BE RELIED UPON IN CONNECTION WITH, ANY CONTRACT OR INVESTMENT
            DECISION. NOTHING CONTAINED IN THE WHITEPAPER OR THE WEBSITE IS OR MAY BE RELIED UPON AS A PROMISE,
            REPRESENTATION OR UNDERTAKING AS TO THE FUTURE PERFORMANCE OF XION GLOBAL. THE AGREEMENT BETWEEN THE
            DISTRIBUTOR (OR ANY THIRD PARTY) AND YOU, IN RELATION TO ANY DISTRIBUTION OR TRANSFER OF XGT, IS TO BE
            GOVERNED ONLY BY THE SEPARATE TERMS AND CONDITIONS OF SUCH AGREEMENT.
            <br />
            <br />
            THE INFORMATION SET OUT IN THE WHITEPAPER AND THE WEBSITE IS FOR COMMUNITY DISCUSSION ONLY AND IS NOT
            LEGALLY BINDING. NO PERSON IS BOUND TO ENTER INTO ANY CONTRACT OR BINDING LEGAL COMMITMENT IN RELATION TO
            THE ACQUISITION OF XGT, AND NO DIGITAL ASSET OR OTHER FORM OF PAYMENT IS TO BE ACCEPTED ON THE BASIS OF THE
            WHITEPAPER OR THE WEBSITE. THE AGREEMENT FOR DISTRIBUTION OF XGT AND/OR CONTINUED HOLDING OF XGT SHALL BE
            GOVERNED BY A SEPARATE SET OF TERMS AND CONDITIONS OR TOKEN DISTRIBUTION AGREEMENT (AS THE CASE MAY BE)
            SETTING OUT THE TERMS OF SUCH DISTRIBUTION AND/OR CONTINUED HOLDING OF XGT (THE TERMS AND CONDITIONS), WHICH
            SHALL BE SEPARATELY PROVIDED TO YOU OR MADE AVAILABLE ON THE WEBSITE. THE TERMS AND CONDITIONS MUST BE READ
            TOGETHER WITH THE WHITEPAPER. IN THE EVENT OF ANY INCONSISTENCIES BETWEEN THE TERMS AND CONDITIONS AND THE
            WHITEPAPER OR THE WEBSITE, THE TERMS AND CONDITIONS SHALL PREVAIL.
            <br />
            <br />
            XGT TOKEN: XGT ARE DESIGNED TO BE UTILISED, AND THAT IS THE GOAL OF THE XGT DISTRIBUTION. IN FACT, THE
            PROJECT TO DEVELOP XION GLOBAL WOULD FAIL IF ALL XGT HOLDERS SIMPLY HELD ONTO THEIR XGT AND DID NOTHING WITH
            IT. IN PARTICULAR, IT IS HIGHLIGHTED THAT XGT:
            <br />
            <br />
            DOES NOT HAVE ANY TANGIBLE OR PHYSICAL MANIFESTATION, AND DOES NOT HAVE ANY INTRINSIC VALUE (NOR DOES ANY
            PERSON MAKE ANY REPRESENTATION OR GIVE ANY COMMITMENT AS TO ITS VALUE);
            <br />
            IS NON-REFUNDABLE AND CANNOT BE EXCHANGED FOR CASH (OR ITS EQUIVALENT VALUE IN ANY OTHER DIGITAL ASSET) OR
            ANY PAYMENT OBLIGATION BY THE COMPANY, THE DISTRIBUTOR OR ANY OF THEIR RESPECTIVE AFFILIATES;
            <br />
            DOES NOT REPRESENT OR CONFER ON THE TOKEN HOLDER ANY RIGHT OF ANY FORM WITH RESPECT TO THE COMPANY, THE
            DISTRIBUTOR (OR ANY OF THEIR RESPECTIVE AFFILIATES), OR ITS REVENUES OR ASSETS, INCLUDING WITHOUT LIMITATION
            ANY RIGHT TO RECEIVE FUTURE DIVIDENDS, REVENUE, SHARES, OWNERSHIP RIGHT OR STAKE, SHARE OR SECURITY, ANY
            VOTING, DISTRIBUTION, REDEMPTION, LIQUIDATION, PROPRIETARY (INCLUDING ALL FORMS OF INTELLECTUAL PROPERTY OR
            LICENCE RIGHTS), RIGHT TO RECEIVE ACCOUNTS, FINANCIAL STATEMENTS OR OTHER FINANCIAL DATA, THE RIGHT TO
            REQUISITION OR PARTICIPATE IN SHAREHOLDER MEETINGS, THE RIGHT TO NOMINATE A DIRECTOR, OR OTHER FINANCIAL OR
            LEGAL RIGHTS OR EQUIVALENT RIGHTS, OR INTELLECTUAL PROPERTY RIGHTS OR ANY OTHER FORM OF PARTICIPATION IN OR
            RELATING TO XION GLOBAL, THE COMPANY, THE DISTRIBUTOR AND/OR THEIR SERVICE PROVIDERS;
            <br />
            IS NOT INTENDED TO REPRESENT ANY RIGHTS UNDER A CONTRACT FOR DIFFERENCES OR UNDER ANY OTHER CONTRACT THE
            PURPOSE OR PRETENDED PURPOSE OF WHICH IS TO SECURE A PROFIT OR AVOID A LOSS;
            <br />
            IS NOT INTENDED TO BE A REPRESENTATION OF MONEY (INCLUDING ELECTRONIC MONEY), SECURITY, COMMODITY, BOND,
            DEBT INSTRUMENT, UNIT IN A COLLECTIVE INVESTMENT SCHEME OR ANY OTHER KIND OF FINANCIAL INSTRUMENT OR
            INVESTMENT;
            <br />
            IS NOT A LOAN TO THE COMPANY, THE DISTRIBUTOR OR ANY OF THEIR RESPECTIVE AFFILIATES, IS NOT INTENDED TO
            REPRESENT A DEBT OWED BY THE COMPANY, THE DISTRIBUTOR OR ANY OF THEIR RESPECTIVE AFFILIATES, AND THERE IS NO
            EXPECTATION OF PROFIT; AND
            <br />
            DOES NOT PROVIDE THE TOKEN HOLDER WITH ANY OWNERSHIP OR OTHER INTEREST IN THE COMPANY, THE DISTRIBUTOR OR
            ANY OF THEIR RESPECTIVE AFFILIATES.
            <br />
            <br />
            NOTWITHSTANDING THE XGT DISTRIBUTION, USERS HAVE NO ECONOMIC OR LEGAL RIGHT OVER OR BENEFICIAL INTEREST IN
            THE ASSETS OF THE COMPANY, THE DISTRIBUTOR, OR ANY OF THEIR AFFILIATES AFTER THE TOKEN DISTRIBUTION.
            <br />
            TO THE EXTENT A SECONDARY MARKET OR EXCHANGE FOR TRADING XGT DOES DEVELOP, IT WOULD BE RUN AND OPERATED
            WHOLLY INDEPENDENTLY OF THE COMPANY, THE DISTRIBUTOR, THE DISTRIBUTION OF XGT AND XION GLOBAL. NEITHER THE
            COMPANY NOR THE DISTRIBUTOR WILL CREATE SUCH SECONDARY MARKETS NOR WILL EITHER ENTITY ACT AS AN EXCHANGE FOR
            XGT.
            <br />
            <br />
            YOU ACKNOWLEDGE AND AGREE THAT THERE ARE NUMEROUS RISKS ASSOCIATED WITH ACQUIRING XGT, HOLDING XGT, AND
            USING XGT FOR PARTICIPATION IN XION GLOBAL. IN THE WORST SCENARIO, THIS COULD LEAD TO THE LOSS OF ALL OR
            PART OF XGT HELD. IF YOU DECIDE TO ACQUIRE XGT OR PARTICIPATE IN XION GLOBAL, YOU EXPRESSLY ACKNOWLEDGE,
            ACCEPT AND ASSUME THE FOLLOWING RISKS: (I) THE REGULATORY STATUS OF XION GLOBAL, XGT AND DISTRIBUTED LEDGER
            TECHNOLOGY IS UNCLEAR OR UNSETTLED IN MANY JURISDICTIONS. THE REGULATION OF DIGITAL ASSETS HAS BECOME A
            PRIMARY TARGET OF REGULATION IN ALL MAJOR COUNTRIES IN THE WORLD. IT IS IMPOSSIBLE TO PREDICT HOW, WHEN OR
            WHETHER REGULATORY AGENCIES MAY APPLY EXISTING REGULATIONS OR CREATE NEW REGULATIONS WITH RESPECT TO SUCH
            TECHNOLOGY AND ITS APPLICATIONS, INCLUDING XGT AND/OR XION GLOBAL. REGULATORY ACTIONS COULD NEGATIVELY
            IMPACT XGT AND/OR XION GLOBAL IN VARIOUS WAYS. THE COMPANY, THE DISTRIBUTOR (OR THEIR RESPECTIVE AFFILIATES)
            MAY CEASE OPERATIONS IN A JURISDICTION IN THE EVENT THAT REGULATORY ACTIONS, OR CHANGES TO LAW OR
            REGULATION, MAKE IT ILLEGAL TO OPERATE IN SUCH JURISDICTION, OR COMMERCIALLY UNDESIRABLE TO OBTAIN THE
            NECESSARY REGULATORY APPROVAL(S) TO OPERATE IN SUCH JURISDICTION. AFTER CONSULTING WITH A WIDE RANGE OF
            LEGAL ADVISORS TO MITIGATE THE LEGAL RISKS AS MUCH AS POSSIBLE, THE COMPANY AND DISTRIBUTOR HAVE WORKED WITH
            THE SPECIALIST BLOCKCHAIN DEPARTMENT AT BAYFRONT LAW LLC AND OBTAINED A LEGAL OPINION ON THE TOKEN
            DISTRIBUTION, AND WILL BE CONDUCTING BUSINESS IN ACCORDANCE WITH THE PREVAILING MARKET PRACTICE; (II) AS AT
            THE DATE HEREOF, XION GLOBAL IS STILL UNDER DEVELOPMENT AND ITS DESIGN CONCEPTS, CONSENSUS MECHANISMS,
            ALGORITHMS, CODES, AND OTHER TECHNICAL DETAILS AND PARAMETERS MAY BE CONSTANTLY AND FREQUENTLY UPDATED AND
            CHANGED. ALTHOUGH THIS WHITEPAPER CONTAINS THE MOST CURRENT INFORMATION RELATING TO XION GLOBAL, IT IS NOT
            ABSOLUTELY COMPLETE AND MAY STILL BE ADJUSTED AND UPDATED BY THE XION TEAM FROM TIME TO TIME. THE XION TEAM
            HAS NO ABILITY AND OBLIGATION TO KEEP HOLDERS OF XGT INFORMED OF EVERY DETAIL (INCLUDING DEVELOPMENT
            PROGRESS AND EXPECTED MILESTONES) REGARDING THE PROJECT TO DEVELOP XION GLOBAL, HENCE INSUFFICIENT
            INFORMATION DISCLOSURE IS INEVITABLE AND REASONABLE; (III) VARIOUS TYPES OF DECENTRALISED APPLICATIONS AND
            NETWORKS ARE EMERGING AT A RAPID RATE, AND THE INDUSTRY IS INCREASINGLY COMPETITIVE. IT IS POSSIBLE THAT
            ALTERNATIVE NETWORKS COULD BE ESTABLISHED THAT UTILISE THE SAME OR SIMILAR CODE AND PROTOCOL UNDERLYING XGT
            AND/OR XION GLOBAL AND ATTEMPT TO RE-CREATE SIMILAR FACILITIES. XION GLOBAL MAY BE REQUIRED TO COMPETE WITH
            THESE ALTERNATIVE NETWORKS, WHICH COULD NEGATIVELY IMPACT XGT AND/OR XION GLOBAL, (IV) THE DEVELOPMENT OF
            XION GLOBAL GREATLY DEPENDS ON THE CONTINUED CO-OPERATION OF THE EXISTING TECHNICAL TEAM AND EXPERT
            CONSULTANTS, WHO ARE HIGHLY KNOWLEDGEABLE AND EXPERIENCED IN THEIR RESPECTIVE SECTORS. THE LOSS OF ANY
            MEMBER MAY ADVERSELY AFFECT XION GLOBAL OR ITS FUTURE DEVELOPMENT. FURTHER, STABILITY AND COHESION WITHIN
            THE TEAM IS CRITICAL TO THE OVERALL DEVELOPMENT OF XION GLOBAL. THERE IS THE POSSIBILITY THAT CONFLICT
            WITHIN THE TEAM AND/OR DEPARTURE OF CORE PERSONNEL MAY OCCUR, RESULTING IN NEGATIVE INFLUENCE ON THE PROJECT
            IN THE FUTURE; (V) THERE IS THE RISK THAT THE DEVELOPMENT OF XION GLOBAL WILL NOT BE EXECUTED OR IMPLEMENTED
            AS PLANNED, FOR A VARIETY OF REASONS, INCLUDING WITHOUT LIMITATION THE EVENT OF A DECLINE IN THE PRICES OF
            ANY DIGITAL ASSET, VIRTUAL CURRENCY OR XGT, UNFORESEEN TECHNICAL DIFFICULTIES, AND SHORTAGE OF DEVELOPMENT
            FUNDS FOR ACTIVITIES; (VI) HACKERS OR OTHER MALICIOUS GROUPS OR ORGANISATIONS MAY ATTEMPT TO INTERFERE WITH
            XGT AND/OR XION GLOBAL IN A VARIETY OF WAYS, INCLUDING, BUT NOT LIMITED TO, MALWARE ATTACKS, DENIAL OF
            SERVICE ATTACKS, CONSENSUS-BASED ATTACKS, SYBIL ATTACKS, SMURFING AND SPOOFING. FURTHERMORE, THERE IS A RISK
            THAT A THIRD PARTY OR A MEMBER OF THE COMPANY, THE DISTRIBUTOR OR THEIR RESPECTIVE AFFILIATES MAY
            INTENTIONALLY OR UNINTENTIONALLY INTRODUCE WEAKNESSES INTO THE CORE INFRASTRUCTURE OF XGT AND/OR XION
            GLOBAL, WHICH COULD NEGATIVELY AFFECT XGT AND/OR XION GLOBAL. FURTHER, THE FUTURE OF CRYPTOGRAPHY AND
            SECURITY INNOVATIONS ARE HIGHLY UNPREDICTABLE AND ADVANCES IN CRYPTOGRAPHY, OR TECHNICAL ADVANCES (INCLUDING
            WITHOUT LIMITATION DEVELOPMENT OF QUANTUM COMPUTING), COULD PRESENT UNKNOWN RISKS TO XGT AND/OR XION GLOBAL
            BY RENDERING INEFFECTIVE THE CRYPTOGRAPHIC CONSENSUS MECHANISM THAT UNDERPINS THAT BLOCKCHAIN PROTOCOL; AND
            (VII) IN ADDITION, THE POTENTIAL RISKS BRIEFLY MENTIONED HEREIN ARE NOT EXHAUSTIVE AND THERE ARE OTHER RISKS
            (AS MORE PARTICULARLY SET OUT IN THE TERMS AND CONDITIONS) ASSOCIATED WITH YOUR PARTICIPATION IN XION
            GLOBAL, AS WELL AS ACQUISITION OF, HOLDING AND USE OF XGT, INCLUDING THOSE THAT THE COMPANY OR THE
            DISTRIBUTOR CANNOT ANTICIPATE. SUCH RISKS MAY FURTHER MATERIALISE AS UNANTICIPATED VARIATIONS OR
            COMBINATIONS OF THE AFOREMENTIONED RISKS. YOU SHOULD CONDUCT FULL DUE DILIGENCE ON THE COMPANY, THE
            DISTRIBUTOR, THEIR RESPECTIVE AFFILIATES, AND THE XION TEAM, AS WELL AS UNDERSTAND THE OVERALL FRAMEWORK,
            MISSION AND VISION FOR XION GLOBAL PRIOR TO PARTICIPATING IN THE SAME AND/OR ACQUIRING XGT.
            <br />
            <br />
            DEEMED REPRESENTATIONS AND WARRANTIES: BY ACCESSING THE WHITEPAPER OR THE WEBSITE (OR ANY PART THEREOF), YOU
            SHALL BE DEEMED TO REPRESENT AND WARRANT TO THE COMPANY, THE DISTRIBUTOR, THEIR RESPECTIVE AFFILIATES, AND
            THE XION TEAM AS FOLLOWS: <br />
            <br />
            IN ANY DECISION TO ACQUIRE ANY XGT, YOU HAVE SHALL NOT RELY ON ANY STATEMENT SET OUT IN THE WHITEPAPER OR
            THE WEBSITE;
            <br />
            YOU WILL AND SHALL AT YOUR OWN EXPENSE ENSURE COMPLIANCE WITH ALL LAWS, REGULATORY REQUIREMENTS AND
            RESTRICTIONS APPLICABLE TO YOU (AS THE CASE MAY BE);
            <br />
            YOU ACKNOWLEDGE, UNDERSTAND AND AGREE THAT XGT MAY HAVE NO VALUE, THERE IS NO GUARANTEE OR REPRESENTATION OF
            VALUE OR LIQUIDITY FOR XGT, AND XGT IS NOT AN INVESTMENT PRODUCT NOR IS IT INTENDED FOR ANY SPECULATIVE
            INVESTMENT WHATSOEVER;
            <br />
            NONE OF THE COMPANY, THE DISTRIBUTOR, THEIR RESPECTIVE AFFILIATES, AND/OR THE XION TEAM MEMBERS SHALL BE
            RESPONSIBLE FOR OR LIABLE FOR THE VALUE OF XGT, THE TRANSFERABILITY AND/OR LIQUIDITY OF XGT AND/OR THE
            AVAILABILITY OF ANY MARKET FOR XGT THROUGH THIRD PARTIES OR OTHERWISE; AND
            <br />
            YOU ACKNOWLEDGE, UNDERSTAND AND AGREE THAT YOU ARE NOT ELIGIBLE TO PARTICIPATE IN THE DISTRIBUTION OF XGT IF
            YOU ARE A CITIZEN, NATIONAL, RESIDENT (TAX OR OTHERWISE), DOMICILIARY AND/OR GREEN CARD HOLDER OF A
            GEOGRAPHIC AREA OR COUNTRY (I) WHERE IT IS LIKELY THAT THE DISTRIBUTION OF XGT WOULD BE CONSTRUED AS THE
            SALE OF A SECURITY (HOWSOEVER NAMED), FINANCIAL SERVICE OR INVESTMENT PRODUCT AND/OR (II) WHERE
            PARTICIPATION IN TOKEN DISTRIBUTIONS IS PROHIBITED BY APPLICABLE LAW, DECREE, REGULATION, TREATY, OR
            ADMINISTRATIVE ACT (INCLUDING WITHOUT LIMITATION THE UNITED STATES OF AMERICA AND THE PEOPLE’S REPUBLIC OF
            CHINA); AND TO THIS EFFECT YOU AGREE TO PROVIDE ALL SUCH IDENTITY VERIFICATION DOCUMENT WHEN REQUESTED IN
            ORDER FOR THE RELEVANT CHECKS TO BE CARRIED OUT.
            <br />
            <br />
            THE COMPANY, THE DISTRIBUTOR AND THE XION TEAM DO NOT AND DO NOT PURPORT TO MAKE, AND HEREBY DISCLAIMS, ALL
            REPRESENTATIONS, WARRANTIES OR UNDERTAKING TO ANY ENTITY OR PERSON (INCLUDING WITHOUT LIMITATION WARRANTIES
            AS TO THE ACCURACY, COMPLETENESS, TIMELINESS, OR RELIABILITY OF THE CONTENTS OF THE WHITEPAPER OR THE
            WEBSITE, OR ANY OTHER MATERIALS PUBLISHED BY THE COMPANY OR THE DISTRIBUTOR). TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, THE COMPANY, THE DISTRIBUTOR, THEIR RESPECTIVE AFFILIATES AND SERVICE PROVIDERS SHALL NOT
            BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR OTHER LOSSES OF ANY KIND, IN TORT,
            CONTRACT OR OTHERWISE (INCLUDING, WITHOUT LIMITATION, ANY LIABILITY ARISING FROM DEFAULT OR NEGLIGENCE ON
            THE PART OF ANY OF THEM, OR ANY LOSS OF REVENUE, INCOME OR PROFITS, AND LOSS OF USE OR DATA) ARISING FROM
            THE USE OF THE WHITEPAPER OR THE WEBSITE, OR ANY OTHER MATERIALS PUBLISHED, OR ITS CONTENTS (INCLUDING
            WITHOUT LIMITATION ANY ERRORS OR OMISSIONS) OR OTHERWISE ARISING IN CONNECTION WITH THE SAME. PROSPECTIVE
            ACQUIRORS OF XGT SHOULD CAREFULLY CONSIDER AND EVALUATE ALL RISKS AND UNCERTAINTIES (INCLUDING FINANCIAL AND
            LEGAL RISKS AND UNCERTAINTIES) ASSOCIATED WITH THE DISTRIBUTION OF XGT, THE COMPANY, THE DISTRIBUTOR AND THE
            XION TEAM.
            <br />
            <br />
            INFORMATIONAL PURPOSES ONLY: THE INFORMATION SET OUT HEREIN IS ONLY CONCEPTUAL, AND DESCRIBES THE FUTURE
            DEVELOPMENT GOALS FOR XION GLOBAL TO BE DEVELOPED. IN PARTICULAR, THE PROJECT ROADMAP IN THE WHITEPAPER IS
            BEING SHARED IN ORDER TO OUTLINE SOME OF THE PLANS OF THE XION TEAM, AND IS PROVIDED SOLELY FOR
            INFORMATIONAL PURPOSES AND DOES NOT CONSTITUTE ANY BINDING COMMITMENT. PLEASE DO NOT RELY ON THIS
            INFORMATION IN DECIDING WHETHER TO PARTICIPATE IN THE TOKEN DISTRIBUTION BECAUSE ULTIMATELY, THE
            DEVELOPMENT, RELEASE, AND TIMING OF ANY PRODUCTS, FEATURES OR FUNCTIONALITY REMAINS AT THE SOLE DISCRETION
            OF THE COMPANY, THE DISTRIBUTOR OR THEIR RESPECTIVE AFFILIATES, AND IS SUBJECT TO CHANGE. FURTHER, THE
            WHITEPAPER OR THE WEBSITE MAY BE AMENDED OR REPLACED FROM TIME TO TIME. THERE ARE NO OBLIGATIONS TO UPDATE
            THE WHITEPAPER OR THE WEBSITE, OR TO PROVIDE RECIPIENTS WITH ACCESS TO ANY INFORMATION BEYOND WHAT IS
            PROVIDED HEREIN. REGULATORY APPROVAL: NO REGULATORY AUTHORITY HAS EXAMINED OR APPROVED, WHETHER FORMALLY OR
            INFORMALLY, ANY OF THE INFORMATION SET OUT IN THE WHITEPAPER OR THE WEBSITE. NO SUCH ACTION OR ASSURANCE HAS
            BEEN OR WILL BE TAKEN UNDER THE LAWS, REGULATORY REQUIREMENTS OR RULES OF ANY JURISDICTION. THE PUBLICATION,
            DISTRIBUTION OR DISSEMINATION OF THE WHITEPAPER OR THE WEBSITE DOES NOT IMPLY THAT THE APPLICABLE LAWS,
            REGULATORY REQUIREMENTS OR RULES HAVE BEEN COMPLIED WITH.
            <br />
            <br />
            CAUTIONARY NOTE ON FORWARD-LOOKING STATEMENTS: ALL STATEMENTS CONTAINED HEREIN, STATEMENTS MADE IN PRESS
            RELEASES OR IN ANY PLACE ACCESSIBLE BY THE PUBLIC AND ORAL STATEMENTS THAT MAY BE MADE BY THE COMPANY, THE
            DISTRIBUTOR AND/OR THE XION TEAM, MAY CONSTITUTE FORWARD-LOOKING STATEMENTS (INCLUDING STATEMENTS REGARDING
            THE INTENT, BELIEF OR CURRENT EXPECTATIONS WITH RESPECT TO MARKET CONDITIONS, BUSINESS STRATEGY AND PLANS,
            FINANCIAL CONDITION, SPECIFIC PROVISIONS AND RISK MANAGEMENT PRACTICES). YOU ARE CAUTIONED NOT TO PLACE
            UNDUE RELIANCE ON THESE FORWARD-LOOKING STATEMENTS GIVEN THAT THESE STATEMENTS INVOLVE KNOWN AND UNKNOWN
            RISKS, UNCERTAINTIES AND OTHER FACTORS THAT MAY CAUSE THE ACTUAL FUTURE RESULTS TO BE MATERIALLY DIFFERENT
            FROM THAT DESCRIBED BY SUCH FORWARD-LOOKING STATEMENTS, AND NO INDEPENDENT THIRD PARTY HAS REVIEWED THE
            REASONABLENESS OF ANY SUCH STATEMENTS OR ASSUMPTIONS. THESE FORWARD-LOOKING STATEMENTS ARE APPLICABLE ONLY
            AS OF THE DATE INDICATED IN THE WHITEPAPER, AND THE COMPANY, THE DISTRIBUTOR AS WELL AS THE XION TEAM
            EXPRESSLY DISCLAIM ANY RESPONSIBILITY (WHETHER EXPRESS OR IMPLIED) TO RELEASE ANY REVISIONS TO THESE
            FORWARD-LOOKING STATEMENTS TO REFLECT EVENTS AFTER SUCH DATE.
            <br />
            <br />
            REFERENCES TO COMPANIES AND PLATFORMS: THE USE OF ANY COMPANY AND/OR PLATFORM NAMES OR TRADEMARKS HEREIN
            (SAVE FOR THOSE WHICH RELATE TO THE COMPANY, THE DISTRIBUTOR OR THEIR RESPECTIVE AFFILIATES) DOES NOT IMPLY
            ANY AFFILIATION WITH, OR ENDORSEMENT BY, ANY THIRD PARTY. REFERENCES IN THE WHITEPAPER OR THE WEBSITE TO
            SPECIFIC COMPANIES AND PLATFORMS ARE FOR ILLUSTRATIVE PURPOSES ONLY.
            <br />
            ENGLISH LANGUAGE: THE WHITEPAPER AND THE WEBSITE MAY BE TRANSLATED INTO A LANGUAGE OTHER THAN ENGLISH FOR
            REFERENCE PURPOSE ONLY AND IN THE EVENT OF CONFLICT OR AMBIGUITY BETWEEN THE ENGLISH LANGUAGE VERSION AND
            TRANSLATED VERSIONS OF THE WHITEPAPER OR THE WEBSITE, THE ENGLISH LANGUAGE VERSIONS SHALL PREVAIL. YOU
            ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THE ENGLISH LANGUAGE VERSION OF THE WHITEPAPER AND THE
            WEBSITE.
            <br />
            <br />
            NO DISTRIBUTION: NO PART OF THE WHITEPAPER OR THE WEBSITE IS TO BE COPIED, REPRODUCED, DISTRIBUTED OR
            DISSEMINATED IN ANY WAY WITHOUT THE PRIOR WRITTEN CONSENT OF THE COMPANY OR THE DISTRIBUTOR. BY ATTENDING
            ANY PRESENTATION ON THIS WHITEPAPER OR BY ACCEPTING ANY HARD OR SOFT COPY OF THE WHITEPAPER, YOU AGREE TO BE
            BOUND BY THE FOREGOING LIMITATIONS.
          </p>
        </div>
      </div>
      <FooterIpad onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
