import React, { useState, useCallback } from "react";
import { FooterDesktop } from "../../../desktopcomponents/FooterDesktopMain/FooterDesktop";
import { NavbarDesktop } from "../../../desktopcomponents/NavBarDesktopMain";
import "./style.css";

export const CUD = () => {
  const [buttonText, setButtonText] = useState("SUBMIT");

  const handleOpenURL = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const handleGetStartedClick = useCallback(() => {
    window.open("https://xionpay.app", "_blank");
  }, []);

  const handleSubmit = useCallback(() => {
    // Fetch the data from the form using unique class names
    const formData = {
      firstName: document.querySelector(".input-first-name").value,
      lastName: document.querySelector(".input-last-name").value,
      email: document.querySelector(".input-email").value,
      website: document.querySelector(".input-website").value,
      country: document.querySelector(".input-country").value,
      additionalInfo: document.querySelector(".input-additional-info").value,
    };

    // Update this URL to point to your Vercel serverless function
    const serverlessFunctionURL = "/api/sendToZapier";

    fetch(serverlessFunctionURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setButtonText("Submitted");
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(
          "There was a problem with the fetch operation:",
          error.message
        );
      });
  }, []);

  return (
    <div className="contact-d">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img
                className="rectangle-9"
                alt="Rectangle"
                src="/img/dmenu-bg-elements.webp"
              />
            </div>
          </div>
        </div>
        <img className="noize" alt="Noize" src="/img/noize.png" />
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarDesktop onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="ABOUT-US">REACH OUT</div>
      </div>
      <div className="section">
        <div className="overlap-2">
          <div className="rectangle-2" />
          <div className="rectangle-3" />
          <div className="contact-block">
            <div className="frame">
              <div className="div-2">First Name</div>
              <input
                className="rectangle-4 input-first-name"
                type="text"
                placeholder=" Enter First Name"
                required
              />
            </div>
            <div className="frame">
              <div className="div-2">Last Name</div>
              <input
                className="rectangle-4 input-last-name"
                type="text"
                placeholder=" Enter Last Name"
                required
              />
            </div>
            <div className="frame">
              <div className="div-2">E-mail</div>
              <input
                className="rectangle-4 input-email"
                type="email"
                placeholder=" Enter E-mail"
                required
              />
            </div>
            <div className="frame">
              <div className="div-2">Website</div>
              <input
                className="rectangle-4 input-website"
                type="url"
                placeholder=" Enter Website"
                required
              />
            </div>
            <div className="frame">
              <div className="div-2">Country</div>
              <input
                className="rectangle-4 input-country"
                type="text"
                placeholder=" Enter Country"
                required
              />
            </div>
            <div className="frame-2">
              <p className="div-2">
                <span className="text-wrapper">
                  Anything else?
                  <br />
                </span>
              </p>
              <textarea
                className="rectangle-5 input-additional-info"
                placeholder=" Enter additional information"
                required
              ></textarea>
            </div>
            <button className="read-button" onClick={handleSubmit}>
              <div className="read-the-docs-wrapper">
                <div className="read-the-docs">{buttonText}</div>
              </div>
            </button>
          </div>
        </div>
        <div className="TEXT-wrapper">
          <div className="TEXT">
            <div className="text-wrapper-2">CONNECT WITH US</div>
            <p className="our-team-can-help">
              Our team is here to assist you with:
              <ul className="custom-margin">
                <li> Tailored product demonstrations.</li>
                <li> Efficient payment solutions and financial automation.</li>
                <li> Custom pricing, volume discounts, and more.</li>
                <li> Seamless transition to Xion with dedicated support.</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="complaints-section">
          <div className="TEXTcomplaints">
            <div className="text-wrapper-2">COMPLAINTS</div>
            <p className="complaints-text">
              Should you have any complaints regarding transactions or services,
              they will be directed to the relevant provider for resolution. For
              formal submissions, please reach out to your provider directly using
              the relevant channels provided.
            </p>
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">
                Explore guides and integration examples in Xion{" "}
              </span>
              <span
                className="text-wrapper-20"
                onClick={() =>
                  handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")
                }
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">
                We’re here to help! If you have technical issues,{" "}
              </span>
              <span
                className="text-wrapper-20"
                onClick={() =>
                  handleOpenURL("https://intercom.help/xionpay/en/")
                }
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-4" alt="Line" src="/img/desktop-line-23.svg" />
      </div>
      <FooterDesktop onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
