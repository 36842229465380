import React from "react";
import "./style.css";

export const Article6 = () => {
  return (
    <div className="Article6-d">
      <div className="article-container">
        <div className="article-header">
          <img
            src="/img/xion-axelar-squid.png"
            alt="Article Header"
            className="article-image"
          />
          <h1 className="article-title">
            Xion Global Forges Strategic Integration with Axelar and Squid to
            Unify Cross-Chain Payments{" "}
          </h1>
          <p className="article-meta">
            <span className="article-date">July 31, 2024</span> |
            <span className="article-author"> By Xion Global</span>
          </p>
        </div>
        <div className="article-content">
          <p>
            Johannesburg, South Africa — 30 July 2024 –Xion Global PTY LTD, a
            leading multi-chain Web3 crypto payments gateway, today announced a
            groundbreaking strategic integration with Axelar, the Web3
            interoperability platform, and Squid, the provider of seamless
            cross-chain solutions. This strategic integration aims to
            revolutionize the South African and global payments landscape by
            enabling users to make secure and instant crypto payments across
            multiple blockchains.
          </p>
          <br></br>
          <br></br>
          <p className="highlighted-text">
            Unlocking Seamless Cross-Chain Transactions
          </p>
          <br></br>
          <br></br>
          <p>
            This strategic integration positions Xion Global at the forefront of
            cross-chain payments innovation. By integrating Squid’s APIs into
            their multi-chain payments infrastructure, Xion Global has
            significantly expanded its crypto payment options, settlement
            liquidity, and overall blockchain network support. Squid’s
            technology leverages the power of Axelar, the leading cross-chain
            layer and interoperability platform.
          </p>{" "}
          <br></br>
          <br></br>
          <p>
            Together, Squid and Axelar act as the bridge between traditional
            finance and blockchain payment rails, enabling Xion Global to
            facilitate seamless and secure crypto payments across various
            blockchains. This integration paves the way for a more unified and
            secure financial ecosystem, empowering businesses to tap into a
            wider customer base and offering users unparalleled flexibility in
            their crypto payments.
          </p>
          <br></br>
          <br></br>
          <p>
            The next phases in the roadmap are mapped out for multi-chain
            payment rollouts at all associated payment service providers:
          </p>
          <br></br>
          <br></br>
          <p>
            Phase 1 — corporate client exchange API. This provides Xion Global’s
            PSP clients with the ability to off-ramp via an associated exchange
            provider using a stablecoin of choice Phase 2 — integrating Squid’s
            APIs into Xion Global’s multi-chain payments API to expand both evm
            and non-evm networks.
          </p>
          <br></br>
          <br></br>
          <p>
            Forget about interacting with bridging solutions via frontend
            platforms and now focus on using Xion Global APIs that can do the
            work for you. Gasless, fast and interoperability at its finest.
          </p>
          <br></br>
          <br></br>
          <p className="highlighted-text">Benefits for Businesses and Users</p>
          <br></br>
          <br></br>
          <p>
            This collaboration unlocks a multitude of benefits for both
            businesses and users:
          </p>
          <br></br>
          <br></br>
          <p>
            Enhanced User Experience: Users will gain the flexibility to pay
            with their preferred cryptocurrencies, irrespective of the
            blockchain they utilize. Squid’s user-centric interface ensures a
            streamlined cross-chain transaction process.
          </p>
          <br></br>
          <br></br>
          <p>
            Expanded Market Reach: Businesses can tap into a wider customer base
            by accepting payments across various blockchains. This fosters
            greater market exposure and eliminates limitations associated with
            single-chain restrictions.
          </p>
          <br></br>
          <br></br>
          <p>
            Increased Efficiency: Transactions are facilitated swiftly and
            securely through Axelar’s robust interoperability infrastructure.
            This translates to faster settlement times and a more efficient
            payment experience for all parties involved.
          </p>
          <br></br>
          <br></br>
          <p>
            Simplified Multi-Chain Payments: The phased rollout of multi-chain
            payment options for all associated payment service providers (PSPs)
            signifies Xion Global’s commitment to providing businesses and users
            with a future-proof payment solution that embraces the full
            potential of blockchain technology.
          </p>
          <br></br>
          <br></br>
          <p className="highlighted-text">A Vision for a Unified Future</p>
          <br></br>
          <br></br>
          <p>
            “We are thrilled to integrate Axelar and Squid to usher in a new era
            of borderless crypto payments,” said Aeryn Quarmby, COO of Xion
            Global. “By connecting these technologies, we are paving the way for
            a more inclusive and interconnected financial ecosystem.”
          </p>
          <br></br>
          <br></br>
          <p>
            “This integration represents a significant step forward in global
            access to financial technology by combining the best of traditional
            payments and blockchain infrastructure,” said Fig, Co-founder of
            Squid “We are confident that our integration with Xion Global will
            propel the widespread adoption of Web3 payments.”
          </p>
          <br></br>
          <br></br>
          <p>
            Xion Global, Axelar, and Squid are built with a shared vision: to
            create a future where crypto payments are seamless, secure, and
            universally accessible. We are committed to empowering businesses to
            unlock a world of borderless transactions while equipping users with
            unparalleled flexibility and control over their crypto finances.
          </p>
          <br></br>
          <br></br>
          <p>
            These experiences have imparted valuable lessons in resilience,
            About Axelar Axelar is the Web3 interoperability platform,
            delivering the shortest path to scale: an open stack to connect all
            blockchains. Adopters include Uniswap, Microsoft and dozens of
            natively multichain startups, building applications to reach all
            blockchain users at once — 10X as many active users as the leading
            Web3 application environment. Axelar supports smart contracts on a
            cross-chain layer that is open, scalable and secure. Backers include
            Binance, Coinbase, Dragonfly, Galaxy and Polychain. Learn more:
            axelar.network.
          </p>
          <br></br>
          <br></br>
          <p className="highlighted-text">About Squid</p>
          <br></br>
          <br></br>
          <p>
            Squid creates a seamless and efficient way to access anything in
            crypto. We offer the products and the tools to empower both
            developers and end-users from onboarding and integration to ongoing
            participation across chains. Squid users can seamlessly move, swap,
            buy, stake, mint, and deposit their assets across all major chains
            in 1-click, all from one place. Through our API, SDK, and front-end
            tools, developers can easily use Squid to onboard users from any
            ecosystem and create scalable, seamless user experiences for anyone
            with a crypto wallet.
          </p>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};
