import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const IframeContainer = styled.div`
  display: flex;
  overflow: hidden; // Ensure no scrolling in the container
  padding-left: 10px;
  padding-top: 7px;

  iframe {
    height: ${(props) => (props.isDesktop ? "992px" : "813px")};
    width: ${(props) => (props.isDesktop ? "512px" : "388px")};
    border-radius: ${(props) => (props.isDesktop ? "60px" : "53px")};
    overflow: hidden;
    transform-origin: top left;
    ${(props) =>
      props.isDesktop &&
      `
      margin-top: 1px; // Example value, adjust as needed
      margin-bottom: 1px; // Example value, adjust as needed
      // Add other styles specific to desktop here
    `}
    // Adjustments for iPad
      ${(props) =>
      !props.isDesktop &&
      `
      margin-left: 1px; // Example value, adjust as needed
    `};
  }
`;

const XionPayIframe = ({ isDesktop = true }) => {
  const iframeContainerRef = useRef(null);

  useEffect(() => {
    const iframe = document.createElement("iframe");
    iframe.src =
      "https://checkout.xionpay.app?token=b57c4d78-478e-44b9-ac4c-38bb012803e0";
    iframe.style.scrolling = "no";
    iframe.style.transform = "scale(0.6)";
    iframe.style.zIndex = "9";

    if (iframeContainerRef.current) {
      iframeContainerRef.current.appendChild(iframe);
    }

    return () => {
      if (iframeContainerRef.current) {
        iframeContainerRef.current.removeChild(iframe);
      }
    };
  }, [isDesktop]);

  return (
    <IframeContainer
      isDesktop={isDesktop}
      ref={iframeContainerRef}
    ></IframeContainer>
  );
};

export default XionPayIframe;
