import React, { useCallback } from "react";
import { FooterDesktop } from "../../../desktopcomponents/FooterDesktopMain/FooterDesktop";
import { NavbarDesktop } from "../../../desktopcomponents/NavBarDesktopMain";
import "./style.css";

export const AUD = () => {

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);


  return (
    <div className="about-d">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/aboutUS-desktop.webp" />
            </div>
          </div>
        </div>
        <img className="noize" alt="Noize" src="/img/noize.png" />
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarDesktop onGetStartedClick={handleGetStartedClick} />
      <div className="main-3">
        <div className="overlap-21">
          <div className="ABOUT-US">WHO WE ARE</div>
        </div>
      </div>
      <div className="xion-global-was-wrapper">
        <p className="xion-global-was-2">
          <span className="text-wrapper-102">
            In the intricate tapestry of global finance, Xion Global emerged from a pressing need to revolutionize payment solutions for businesses, from budding SMEs to established multinationals.
            <br />
            <br />
            Our journey began with a realization: the world of payments was fragmented, with no unified platform that seamlessly bridged traditional and Web3 systems.
            This observation was not just a challenge but an opportunity waiting to be seized.
            <br />
          </span>
          <span className="text-wrapper-103">
            <br />
            Determined to redefine the status quo, Xion Global embarked on a mission to simplify payments and rewards, merging the reliability of traditional systems with the innovation of Web3.
            Our vision was clear: to create a platform that transcended boundaries, eliminated intermediaries, and put power back into the hands of businesses and their customers.
            <br />
            <br />
            Our platform empowers entities of all sizes, allowing them to manage their financial transactions with unparalleled ease and security.
            By eliminating the need for third-party providers, we've ensured that businesses can focus on what truly matters: growth, customer satisfaction, and achieving their vision.
            <br />
            <br />
            With a global footprint. our team of dedicated professionals, passionate about blockchain and payment innovations, work tirelessly to refine our offerings.
            We're not just a company; we're a movement.
            A movement that constantly evolves, reaches new horizons, and is committed to helping our clients unlock their limitless potential in the world of payments.
            <br />
            <br />
            At Xion Global, we believe in a future where payments are not just transactions but experiences. Experiences that are seamless, secure, and transformative.
            Join us as we lead the way in this exciting journey from traditional to Web3 payments.
            <br />
          </span>
        </p>
      </div>
      <div className="section-8">
        <p className="our-e-commerce-2">
          Xion Global's platform is tailored for businesses across all industries, whether they're offering products or services through one-time payments or subscriptions.
          With our platform, customers enjoy a seamless experience, having full control and transparency over their transactions.
          <br />
          <br />
          At the core of our platform is the robust blockchain technology, ensuring every transaction is secure and decentralized.
          This not only guarantees the safety of data but also eliminates the need for middlemen, making transactions more direct and efficient.
          <br />
          <br />
          Businesses can significantly reduce their transaction fees, making even the smallest transactions cost-effective.
          Our platform boasts a 0% transaction fee, with no hidden charges or risks of chargebacks.
          <br />
          <br />
          We've supercharged the crypto payment experience with our powerful API, allowing for custom workflows and payment types suitable for diverse tech stacks.
          Additionally, our platform integrates effortlessly with various eCommerce systems, providing businesses with detailed transaction reports for better management.
          <br />
          <br />
          One of the standout features is Xion's subscription solution. It's designed to foster customer loyalty over extended periods, offering cost savings the longer they stay.
          This feature, combined with the ability to accept over eight crypto payment options across multiple chains, positions Xion Global as a leader in the digital payment realm.
          <br />
          <br />
          Moreover, businesses can incentivize their customers with up to 100% cashback in crypto on purchases from any Xion Merchant.
          This unique reward system, combined with the ease of integrating Web3 checkout buttons, makes the platform a go-to solution for modern businesses.
          <br />
          <br />
          In essence, Xion Global is not just a payment platform; it's a comprehensive solution designed for the new age of online businesses,
          ensuring they thrive in the evolving digital ecosystem.
        </p>
        <div className="FREE-SIMPLE-7">THE PLATFORM</div>
      </div>
      <div className="section-9">
        <div className="FREE-SIMPLE-8">PARTNERS</div>
        <div className="frame-23">
          <div className="group-13">
            <img className="vector-20" alt="ZK.ME" src="/img/ZK.ME_Logo.svg" />
          </div>
          <div className="group-13">
            <img className="vector-21" alt="Vector" src="/img/18.png" />
          </div>
          <div className="group-13">
            <img className="vector-22" alt="Vector" src="/img/6.png" />
          </div>
          <div className="group-13">
            <img className="vector-23" alt="API Matic" src="/img/apimatic.png" />
          </div>
          <div className="group-13">
            <img className="vector-24" alt="Vector" src="/img/16.png" />
          </div>
          <div className="group-13">
            <img className="vector-25" alt="Vector" src="/img/14.png" />
          </div>
          <div className="group-13">
            <img className="vector-26" alt="Vector" src="/img/12.png" />
          </div>
          <div className="group-13">
            <img className="vector-27" alt="Vector" src="/img/1.png" />
          </div>
          <div className="group-13">
            <img className="vector-28" alt="Vector" src="/img/7.png" />
          </div>
          <div className="group-13">
            <img className="vector-29" alt="Vector" src="/img/17.png" />
          </div>
          <div className="group-13">
            <img className="vector-30" alt="Vector" src="/img/4.png" />
          </div>
          <div className="group-13">
            <img className="vector-31" alt="Vector" src="/img/5.png" />
          </div>
          <div className="group-13">
            <img className="vector-32" alt="Callpay" src="/img/callpay.png" />
          </div>
          <div className="group-13">
            <img className="vector-33" alt="Metamask" src="/img/13.png" />
          </div>
          
        </div>
      </div>
      <div className="section-10">
        <div className="FREE-SIMPLE-9">AUDITED BY</div>
        <img className="frame-24" alt="Img" src="/img/certik_dit.png" />
      </div>
      <FooterDesktop onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
