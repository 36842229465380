import React, { useEffect, useState, useCallback } from "react";
import { FooterIpad } from "../../../ipadcomponents/FooterIpadMain";
import { NavbarIpad } from "../../../ipadcomponents/NavBarIpadMain";
import "./style.css";

export const CBI = () => {
  const [mediaPlayed, setMediaPlayed] = useState(false); // <-- Define mediaPlayed using useState

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);

  useEffect(() => {
    // Array of class names for the videos
    const videoClassNames = [".frame-4", ".group-9", ".overlap-7", ".group-2", ".frame-lock", ".component-4"];

    videoClassNames.forEach(className => {
      const videoPlayer = document.querySelector(className);

      function loadMedia() {
        if (mediaPlayed) return;
        videoPlayer.style.display = "inline";
        videoPlayer.play();
        setMediaPlayed(true);
      }

      videoPlayer.oncanplay = loadMedia;

      videoPlayer.addEventListener('ended', function () {
        // Handle what happens when the video ends, if needed
      });
    });

  }, [mediaPlayed]);

  return (
    <div className="CB-i">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/ipadhero.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarIpad onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="overlap-6">
          <div className="text-block">
            <div className="HT">
              <div className="a-SIMPLE">DISCOVER THE</div>
              <div className="FREE-SIMPLE" >POWER OF CASHBACK</div>
              <div className="payment-solution-you">FOR CUSTOMERS</div>
            </div>
            <p className="p">
              Step into the future of Web3 shopping!
              Embrace the visionary cashback approach, elevating rewards and ensuring unparalleled shopping excellence for customers'.
            </p>
          </div>
          <img className="element-of" alt="Element of" src="/img/TP6.webp" />
        </div>
      </div>
      <div className="section">
        <div className="TEXT-BLOCK">
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">ONE</div>
              <div className="text-wrapper-16">ELEVATE WITH WEB3-POWERED REWARDS</div>
            </div>
            <p className="text-wrapper-17">
              Fuel customer satisfaction to new heights! Explore Web3-powered purchases that offer up to an incredible 100% cashback.
              Transforming the shopping experience.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">TWO</div>
              <p className="text-wrapper-16">EXTENDED CASHBACK BRILLIANCE</p>
            </div>
            <p className="text-wrapper-17">
              Sustain loyalty through extended cashback brilliance! Dive into XGT rewards that span 12 months, steadily enhancing accounts with each passing minute.
              Elevate customer allegiance, encourage ongoing engagement, and amplify your business's growth.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">THREE</div>
              <div className="text-wrapper-16">HARNESS THE POWER OF VERSATILE REWARDS</div>
            </div>
            <p className="text-wrapper-17">
              Drive repeat purchases with the power of versatile Web3 rewards! Allowing customers to leverage cashback to fuel more product acquisitions whenever needed.
              Alternatively, customers' can save up to access special subscription loyalty coupons, nurturing savings and fostering customer loyalty even further.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">FOUR</div>
              <p className="text-wrapper-16">THE MAGIC OF DYNAMIC REWARD LEVELS</p>
            </div>
            <p className="text-wrapper-17">
              Leverage growing rewards through the magic of dynamic levels! As the platform prospers, the cashback potential expands. With increasing funds in the XGT pools, their rewards skyrocket.
              From $300k TVL offering up to $3000 in XGT cashback to $1.5m TVL unveiling up to $100,000 worth of XGT cashback—their rewards tale knows no boundaries.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">FIVE</div>
              <p className="text-wrapper-16">EMBRACE THE ADAPTIVE CASHBACK BONDING BRILLIANCE</p>
            </div>
            <p className="text-wrapper-17">
              Securing rewards continuity through the brilliance of the adaptive bonding curve!
              Once 100% cashback levels are surpassed, embrace Xion's strategic curve designed for 10 years plus of lasting rewards.
              Watch cashback percentages seamlessly adjust to demand and network growth.
              Elevate customer engagement, foster loyalty, and establish a foundation for ongoing success.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">SIX</div>
              <p className="text-wrapper-16">SEE THE MAGIC UNFOLD - DIVE IN AND EXPERIENCE IT!</p>
            </div>
            <p className="text-wrapper-17">
              See the magic unfold by diving in and experiencing it for yourself! Deliver unparalleled cashback to customers and witness the profound impact on your business.
              With Xion pay, it's more than just shopping—it's an immersive experience that cultivates loyalty, elevates customer engagement, and propels your business forward.
            </p>
          </div>
        </div>
        <video className="frame-4" autoplay muted loop src="/img/Flow7w.webm" type="video/webm">
        </video>

        <div className="group-9">
          <video className="group-2" autoplay muted loop src="/img/Flow8w.webm" type="video/webm">
          </video>
        </div>
        <div className="overlap-wrapper">
          <video className="overlap-7" autoplay muted loop src="/img/Flow9w.webm" type="video/webm">
          </video>
        </div>

        <video className="frame-lock" autoplay muted loop src="/img/Flow10w.webm" type="video/webm">
        </video>

        <video className="component-4" autoplay muted loop src="/img/Flow11w.webm" type="video/webm">
        </video>

        <div className="GS-button" onClick={handleGetStartedClick}>
          <div className="GS-button-text">GET STARTED</div>
        </div>

      </div>
      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">Explore guides and integration examples in Xion </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")}
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">We’re here to help! If you have technical issues, </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://intercom.help/xionpay/en/")}
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-4" alt="Line" src="/img/line-23-ipad.svg" />
      </div>
      <FooterIpad onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
