import React, { useRef, useState } from "react";
import "./style.css";

export const ReferralD = () => {
  const [isClicked, setIsClicked] = useState(false);
  const howToInviteSectionRef = useRef(null);
  const getReferralCodeButtonRef = useRef(null);

  const scrollToInviteSection = () => {
    if (howToInviteSectionRef.current) {
      howToInviteSectionRef.current.scrollIntoView({ behavior: "smooth" });
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 2000);
    }
  };

  const scrollToGetReferralCodeButton = () => {
    if (getReferralCodeButtonRef.current) {
      getReferralCodeButtonRef.current.scrollIntoView({ behavior: "smooth" });
      getReferralCodeButtonRef.current.classList.add("highlight");
      setTimeout(() => {
        getReferralCodeButtonRef.current.classList.remove("highlight");
      }, 2000);
    }
  };

  return (
    <div className="Refer-d">
      <div className="header">
        <div className="banner">
          <p className="refer-earn-for-your">
            <span className="text-wrapper">Refer &amp; Earn </span>
            <span className="span">to WIN Your SHARE of R30,000!</span>
            <span className="text-wrapper"> T&amp;Cs Apply</span>
          </p>
        </div>
        <div className="nav">
          <div className="container">
            <div
              className={`div ${isClicked ? "clicked" : ""}`}
              onClick={scrollToInviteSection}
              style={{ cursor: "pointer" }}
            >
              How to Play
            </div>
            <button className="button" onClick={scrollToGetReferralCodeButton}>
              <div className="text-wrapper-2">Start Referring</div>
            </button>
            <a href="https://xion.global/refer-and-earn" className="logo">
              <div className="overlap-group">
                <img className="vector" alt="Vector" src="/img/vector-1r.svg" />
                <img className="img" alt="Vector" src="/img/vector-2r.svg" />
                <img
                  className="vector-2"
                  alt="Vector"
                  src="/img/vector-3r.svg"
                />
              </div>
              <p className="REFER-EARN">
                <span className="text-wrapper-3">REFER </span>
                <span className="text-wrapper-4">&amp; EARN</span>
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className="main">
        <div className="image">
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <img
                className="bright-cartoon"
                alt="Bright cartoon"
                src="/img/bright-3d-cartoon-lgbtq-characters-dancing-together-pride-parade.png"
              />
              <img
                className="refer-earn"
                alt="Refer earn"
                src="/img/refer-earn.svg"
              />
            </div>
          </div>
        </div>
        <p className="invite-a-friend-to">
          <span className="text-wrapper-5">
            Invite Friends to Download Scan to Pay &
          </span>
          <span className="text-wrapper-6">&nbsp;</span>
          <span className="text-wrapper-7">EARN R50 IN USDT EACH</span>
        </p>
        <p ref={howToInviteSectionRef} className="p">
          HOW TO INVITE FRIENDS
        </p>
        <div className="how-to">
          <div className="element">
            <div className="div-wrapper">
              <div className="text-wrapper-8">1</div>
            </div>
            <p className="text-wrapper-9">Download Scan to Pay App</p>
          </div>
          <div className="element-2">
            <div className="div-wrapper">
              <div className="text-wrapper-8">2</div>
            </div>
            <p className="text-wrapper-10">
              Head Back Here & Click "Get My Referral Code"
            </p>
          </div>
          <div className="element-3">
            <div className="div-wrapper">
              <div className="text-wrapper-8">3</div>
            </div>
            <p className="text-wrapper-9">
              Click "Connect Wallet" & Enter Mobile Number
            </p>
          </div>
          <div className="element-4">
            <div className="div-wrapper">
              <div className="text-wrapper-8">4</div>
            </div>
            <p className="text-wrapper-10">
              A Unique Referral Link Will Be Generated, Copy The Unique Link And
              Start Sharing With Friends
            </p>
          </div>
          <div className="element-5">
            <div className="element-6">
              <div className="text-wrapper-8">5</div>
            </div>
            <p className="your-friend-has-to">
              Once Shared, Referred Friends Need To Download Scan to Pay And
              Then Head To The Unique Link Shared To Claim R50.
            </p>
          </div>
          <div className="element-7">
            <div className="div-wrapper">
              <div className="text-wrapper-8">6</div>
            </div>
            <p className="text-wrapper-11">
              After Every Succesful Referral Valid Accounts Will Be Credited
              With R50.
            </p>
          </div>
          <div className="element-8">
            <div className="div-wrapper">
              <div className="text-wrapper-8">7</div>
            </div>
            <p className="text-wrapper-9">
              Make Sure To Use The Same Mobile Number In Scan To Pay When Adding
              a Crypto Wallet. Funds Can Only Be Used With Scan to Pay. Start
              Referring Today To Earn!{" "}
            </p>
          </div>
        </div>
        <button
          ref={getReferralCodeButtonRef}
          className="button-2"
          onClick={() => (window.location.href = "https://referx.io/")}
        >
          <div className="text-wrapper-12">GET MY REFERRAL LINK</div>
        </button>

        <div className="rules">
          <div className="text-wrapper-13">Rules</div>
          <div className="frame">
            <div className="background">
              <p className="the-person-you-refer">
                The individual you refer must use your unique referral link
                during the registration process on Xion Global. This ensures
                that your referral is properly tracked and credited.
              </p>
            </div>
            <div className="background">
              <p className="your-referred-friend">
                Each person is permitted to create only one account. Multiple
                accounts for a single individual are not allowed and will result
                in disqualification from the referral program.
              </p>
            </div>
            <div className="background">
              <p className="the-you-and-your">
                Participation in this refer and earn campaign is limited to
                South African residents. Verification of citizenship may be
                required to qualify for referral rewards.
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="group">
          <div className="frame-2">
            <div className="frame-3">
              <a href="https://xion.global/refer-and-earn" className="logo-2">
                <div className="overlap-group-3">
                  <img
                    className="vector"
                    alt="Vector"
                    src="/img/vector-1r.svg"
                  />
                  <img className="img" alt="Vector" src="/img/vector-2r.svg" />
                  <img
                    className="vector-2"
                    alt="Vector"
                    src="/img/vector-3r.svg"
                  />
                </div>
                <p className="REFER-EARN-2">
                  <span className="text-wrapper-3">REFER </span>
                  <span className="text-wrapper-4">&amp; EARN</span>
                </p>
              </a>
              <div className="frame-4">
                <div className="frame-5">
                  <div
                    className="text-wrapper-16"
                    onClick={scrollToInviteSection}
                    style={{ cursor: "pointer" }}
                  >
                    How to Play
                  </div>
                  <a
                    href="https://www.xion.global/privacy-terms"
                    className="text-wrapper-17"
                    style={{ textDecoration: "none" }}
                  >
                    Terms And Conditions
                  </a>
                </div>
                <div className="frame-6">
                  <a
                    href="https://www.xion.global/privacy-terms"
                    className="text-wrapper-16"
                    style={{ textDecoration: "none" }}
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="mailto:referrals@xion.global"
                    className="text-wrapper-17"
                    style={{ textDecoration: "none" }}
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            {/* <img className="frame-7" alt="Frame" src="/img/frame-39528.svg" /> */}
          </div>
        </div>
        <div className="frame-8">
          <p className="fantasy-go-pty-ltd">
            All Rights Reserved @ Xion Global
            <br />
          </p>
          <div className="frame-9">
            <div className="link">
              <div className="national-responsible">POWERED BY REFERX</div>
            </div>
            <div className="national-gambling-wrapper">
              <div className="national-gambling">SPEND NATIONWIDE</div>
            </div>
            <div className="financial-wrapper">
              <div className="financial">ONLY AVAILABLE ON SCAN TO PAY</div>
            </div>
            <div className="fantasygo-self-wrapper">
              <div className="fantasygo-self">LIMITED AVAILABILITY</div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
