import React, { useCallback } from "react";
import { NavbarMobile } from "../../../mobilecomponents/NavBarMobileMain";
import "./style.css";

export const ErrorPageMobile = () => {


  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);

  const handleGoBackClick = useCallback(() => {
    // Navigate back to the previous page
    window.history.back();
  }, []);


  return (
    <div className="error-m">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/404-Mobile.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer-1.png" />
      <NavbarMobile onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <img className="FREE-SIMPLE" alt="Free SIMPLE" src="/img/free-simple-oops.png" />
        <div className="text-wrapper">404 ERROR</div>
        <p className="div">The page you are looking for seems to have been lost.</p>
        <div className="go-back-instance" onClick={handleGoBackClick}>
          <div className="go-back-2-text">GO BACK</div>
        </div>
        <div className="group">
          <div className="gummy-rayman-wrapper">
            <img
              className="gummy-rayman"
              alt="Gummy rayman"
              src="/img/gummy-rayman-adventures-sebastien-camrrubi-removebg-preview-2.webp"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
