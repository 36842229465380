import React from 'react';
import CookieConsent from "react-cookie-consent";
import styled from 'styled-components';
import Cookies from 'js-cookie'; // You'll need to install this package

const Button = styled.button`
  border: 1px solid;
  border-radius: 10px;
  height: 35px;
  position: relative;
  width: 150px;
  cursor: pointer;
  margin: 0; // Reset margin here
  background: transparent;
  color: #14a5ed;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  text-align: center;

  &:hover {
    border-color: green;
    color: green;
  }

  @media (max-width: 768px) { // iPad and below
    width: 125px; // Adjust as needed
    font-size: 12px; // Adjust as needed
  }

  @media (max-width: 480px) { // Mobile devices
    width: 100px; // Adjust as needed
    font-size: 10px; // Adjust as needed
  }
`;


const DeclineButton = styled(Button)`
  border-color: #7f64fb;
  color: #7f64fb;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  text-align: center;

  &:hover {
    border-color: red;
    color: red;
  }
  @media (max-width: 768px) {
    width: 125px;
    font-size: 12px;
  }

  @media (max-width: 480px) {
    width: 100px;
    font-size: 10px;
  }
`;

const ConsentComponent = () => {

  const handleAccept = () => {
    // Logic for when the user accepts.
    // For instance, you can enable tracking scripts here.
  };

  const handleDecline = () => {
    // Logic for when the user declines.
    // For instance, you can disable certain tracking scripts or functionalities that rely on cookies.
    Cookies.set('userConsent', 'declined', { expires: 365 });
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText={<Button onClick={handleAccept}>I Agree</Button>}
      declineButtonText={<DeclineButton onClick={handleDecline}>I Decline</DeclineButton>}
      cookieName="userConsent"
      expires={150}
      enableDeclineButton={true}
      onDecline={handleDecline}
      style={{
        background: '#ffffff',
        padding: '5px',
        color: 'rgb(0, 0, 0)',
        display: 'flex',
        fontSize: '12px',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }}
      buttonStyle={{
        background: 'transparent',
        border: 'none',
        padding: '0',
        margin: '5px 75px 5px 5px',
        boxShadow: 'none'
      }}
      declineButtonStyle={{
        background: 'transparent',
        border: 'none', 
        padding: '0',
        margin: '5px 5px 5px 5px',
        boxShadow: 'none'
      }}
    >
      This website uses cookies to enhance your experience in accordance with our <a href="/privacy-terms">Privacy Policy</a>. 
      By clicking "I Agree", you consent to the use of cookies. You can always opt-out if you wish.
    </CookieConsent>
  );
}

export default ConsentComponent;
