import React from "react";
import "./style.css";

export const Article2 = () => {
  return (
    <div className="Article2-d">
      <div className="article-container">
        <div className="article-header">
          <img
            src="/img/articlecover5.png"
            alt="Article Header"
            className="article-image"
          />
          <h1 className="article-title">
            South Africans can now Scan to Pay with Crypto — Powered by Xion
            Global & Ukheshe
          </h1>
          <p className="article-meta">
            <span className="article-date">September 28, 2023</span> |
            <span className="article-author">
              {" "}
              By Scan to Pay, Powered by Ukheshe
            </span>
          </p>
        </div>
        <div className="article-content">
          <p>
            Scan to Pay, Powered by Ukheshe, has partnered with Xion Global to
            offer crypto payments on the Scan to Pay app.
          </p>
          <br></br>
          <br></br>
          <p>
            Our mission is to revolutionize the way businesses and consumers
            transact in the digital world, and we are committed to making this
            vision a reality through our innovative Web3 payment solutions.
          </p>
          <br></br>
          <br></br>
          <p>
            Scan to Pay, powered by Ukheshe, is the largest QR ecosystem in
            South Africa and is used by more than 600,000 vendors, 14 banks and
            fintech companies and 94 payment service providers. Now, this new
            partnership will seamlessly introduce more crypto payment options to
            mainstream South Africa through the Scan to Pay app.
          </p>{" "}
          <br></br>
          <br></br>
          <p className="highlighted-text">
            Tracy-Lee Schoeman, Vice President of Loyalty and Rewards at
            Ukheshe, says the rise of cryptocurrencies has created a new wave of
            opportunities for performing financial transactions. “Cryptocurrency
            payments have the potential to transform the way people transact
            with lower fees, faster settlement times, and greater financial
            sovereignty — something Ukheshe is excited to offer through Scan to
            Pay.”
          </p>
          <br></br>
          <br></br>
          <p>
            A Deloitte survey revealed that almost 75% of retailers in the USA
            plan to accept either cryptocurrency or stablecoin payments in the
            near future. And with crypto already available as a payment option
            at major South African retailers and even spaza shops, it’s clear
            that the local market is heading in the same direction.
          </p>
          <br></br>
          <br></br>
          <p className="highlighted-text">
            Ronan Quarmby, CEO at Xion, says approximately 12.5% of South
            Africa’s population owns cryptocurrency. “With 83% of these owners
            falling within the 18–44 age group and 52% using crypto for
            purchases, it’s evident that web3 payments represent the future of
            online transactions in the country.
          </p>
          <br></br>
          <br></br>
          <p>
            With increasing cryptocurrency adoption, South African businesses
            recognise its potential and 94% of business owners already believe
            that integrating cryptocurrency payments would be advantageous. As
            web3 payments continue to evolve rapidly, especially in emerging
            markets, South Africa is poised to lead the charge in Africa.”
          </p>
          <br></br>
          <br></br>
          <p>
            Users can expect a seamless payment process akin to traditional
            payment methods. After linking their Metamask wallet, the customer
            will simply scan a QR code on their receipt, check the amount in the
            Scan to Pay app, select ‘pay with crypto voucher’, and complete the
            transaction in one click.
          </p>
          <br></br>
          <br></br>
          <p>
            In addition to 1-click multi-chain payments, Xion Global’s
            infrastructure also incorporates robust security features such as
            AML, KYC, KYB, and KYT to protect against unauthorized funds and
            deter malicious merchant actions. Furthermore, with their strategic
            collaboration with Polygon Labs, Xion Global is poised to spearhead
            the Web3 payments movement in South Africa.
          </p>
          <br></br>
          <br></br>
          <p>
            And it’s only the beginning. Says Quarmby: “Xion Global recognised
            the expansive reach and potential of Scan to Pay, powered by
            Ukheshe, and this new partnership made absolute sense. The solution
            will be rolled out in phases. Consumers can benefit from gasless
            USDT (Polygon) transactions during phase 1, expanding to multi-chain
            payments in phase 2. Phase 3 will offer cashback rewards, loyalty
            programs, and discounts at specific Scan to Pay merchants,
            motivating users to incorporate crypto payments into their daily
            routines.
          </p>
          <br></br>
          <br></br>
          <p className="highlighted-text">
            ” Schoeman says the partnership is another step towards Ukheshe’s
            ultimate vision of financial inclusion for all South Africans. “As a
            decentralised form of finance, cryptocurrencies are being embraced
            by consumers in emerging markets as they help overcome many of the
            challenges commonly faced when engaging with traditional banks. This
            partnership merges traditional payment methods with the
            decentralised world of web3 to reduce transaction fees, enhance
            incentives, and ultimately increase financial inclusion.”
          </p>
          <br></br>
          <br></br>
          <p>
            About Scan to Pay Scan to Pay powered by Ukheshe, is the transformed
            Masterpass app offering the same level of security and efficiency.
            Scan to Pay offers a fresh look and feel, bringing a seamless
            payment experience to users. As the largest QR ecosystem in South
            Africa, Scan to Pay serves six acquirers, 94 PSPs, 14 banks and
            fintechs, three MNOS, and over 500,000 merchants. This expansive
            network ensures widespread acceptance and accessibility for users
            across the country. Scan to Pay’s mission is to provide a simple,
            secure, and convenient payment method that unlocks new possibilities
            for QR payments in South Africa. We empower users to make payments
            effortlessly across all channels and devices, enabling greater
            convenience and enhancing the overall payment experience. Visit our
            website: https://www.scantopay.io/
          </p>
          <br></br>
          <br></br>
          <p className="highlighted-text">About Ukheshe Technologies</p>
          <p>
            Ukheshe Technologies is a leading fintech enablement partner. With a
            legacy in enterprise platform delivery of embedded finance, it
            connects businesses with payment technology that changes lives and
            uplifts emerging markets. Ukheshe has created an extensive range of
            microservices that enable rapid digital transaction propositions for
            clients. Striving for transformation and innovation in the payment
            industry, Ukheshe adapts and creates components that are scalable,
            secure and ready to deploy in market leading digital-first
            propositions. Visit our website https://www.ukheshe.com/.
          </p>
          <br></br>
          <br></br>
          <p>
            For more information about Scan to Pay, powered by Ukheshe contact
            GinjaNinja: Amanda Chikara, amanda@ginjaninja.co.za, +2711 568 4338
          </p>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};
