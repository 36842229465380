import React from "react";
import "./style.css";

export const Article4 = () => {
  return (
    <div className="Article4-d">
      <div className="article-container">
        <div className="article-header">
          <img
            src="/img/articlecover4.png"
            alt="Article Header"
            className="article-image"
          />
          <h1 className="article-title">
            Xion Global bridges the gap between blockchain and traditional
            financial systems with G+D’s Filia solution
          </h1>
          <p className="article-meta">
            <span className="article-date">November 23, 2023</span> |
            <span className="article-author"> By G+D x Xion Global</span>
          </p>
        </div>
        <div className="article-content">
          <p>
            Johannesburg, 23 November 2023— Xion Global uses technology from
            Giesecke+Devrient (G+D) for an innovative holistic financial
            solution.
          </p>
          <br></br>
          <br></br>
          <p>
            By combining it’s web3 multi-chain payments infrastructure and G+D’s
            CBDC solution Filia for interbank deposit token transfer, Xion is
            creating a seamless financial ecosystem for digital asses as well as
            public currencies. Xion will provide customized solutions for the
            Asia Pacific (APAC) and Europe, Middle East and Africa (EMEA)
            regions.
          </p>
          <br></br>
          <br></br>
          <p>
            The Proof of Concept (POC) of Xion serves as a cornerstone for the
            new financial architecture. Leveraging the infrastructures of both
            companies, this solution enables instant transactions across a
            variety of blockchain networks and traditional financial channels.
            Designed for seamless integration with existing financial platforms,
            the solution offers scalability and customization to meet the
            nuanced needs of financial service providers. It optimizes their
            operational efficiency, enhances the user experience and ensures the
            protection of sensitive financial data through multiple layers of
            security protocols.
          </p>{" "}
          <br></br>
          <br></br>
          <p>
            The initiative of Xion targets the APAC and EMEA regions and is
            particularly geared towards meeting the financial needs of both
            individual and institutional clients. Pilot programs are under
            active development to deliver inclusive, efficient, and secure
            financial services that address the unique characteristics of these
            diverse markets. The future roadmap also includes the integration of
            offline payment capabilities. This feature will facilitate
            transactions using deposit tokens and smart cards without the
            necessity for internet connectivity, thereby democratizing access to
            financial services.
          </p>
          <br></br>
          <br></br>
          <p>
            “In the fast-paced landscape of digital transformation, Xion Global
            and G+D are at the forefront of a financial paradigm shift,”
            explains Aeryn Quarmby, COO at Xion Global. “Our approach aims to
            combine the robustness of traditional financial systems with
            cutting-edge fintech solutions, targeting a range of financial
            service providers and stakeholders across diverse markets.”
          </p>
          <br></br>
          <br></br>
          <p>
            “We very much welcome the initiative of Xion Global. It once again
            demonstrates one of the key strengths of our Filia infrastructure:
            its interoperability,” adds Raoul Herborg, Managing Director Digital
            Currencies at G+D. “It can flexibly interoperate with existing and
            new systems, enabling private sector players to integrate public
            digital currencies and develop new applications and innovations.”
          </p>
          <br></br>
          <br></br>
          <p>
            About Giesecke+Devrient (G+D) Giesecke+Devrient (G+D) is a global
            SecurityTech company headquartered in Munich, Germany. G+D makes the
            lives of billions of people more secure. The company shapes trust in
            the digital age, with built-in security technology in three
            segments: Digital Security, Financial Platforms and Currency
            Technology.
          </p>
          <br></br>
          <br></br>
          <p>
            G+D was founded in 1852 and today has a workforce of more than
            14,000 employees. In the fiscal year 2022, the company generated a
            turnover of 2.53 billion euros. G+D is represented by 123
            subsidiaries and joint ventures in 40 countries. Further
            information: www.gi-de.com.
          </p>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};
