import React, { useEffect, useState, useCallback } from "react";
import { FooterDesktop } from "../../../desktopcomponents/FooterDesktopMain/FooterDesktop";
import { NavbarDesktop } from "../../../desktopcomponents/NavBarDesktopMain";
import "./style.css";

export const HCD = () => {
  const [mediaPlayed, setMediaPlayed] = useState(false); // <-- Define mediaPlayed using useState

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);

  const handleCheckoutButtonsClick = () => {
    // Add the logic to perform when the button is clicked
    // For example, you can use window.location.href to navigate to a URL
    window.location.href = '/checkout-buttons'; // Replace with the actual URL
  };

  const handlePaymentLinksClick = () => {
    // Add the logic to perform when the button is clicked
    // For example, you can use window.location.href to navigate to a URL
    window.location.href = '/payment-links'; // Replace with the actual URL
  };

  useEffect(() => {
    // Array of class names for the videos
    const videoClassNames = [".frame-4", ".group-9", ".overlap-7", ".component-3", ".group-2", ".frame-lock", ".component-4"];

    videoClassNames.forEach(className => {
      const videoPlayer = document.querySelector(className);

      function loadMedia() {
        if (mediaPlayed) return;
        videoPlayer.style.display = "inline";
        videoPlayer.play();
        setMediaPlayed(true);
      }

      videoPlayer.oncanplay = loadMedia;

      videoPlayer.addEventListener('ended', function () {
        // Handle what happens when the video ends, if needed
      });
    });

  }, [mediaPlayed]);


  return (
    <div className="HC-d">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/desktophero.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarDesktop onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="overlap-6">
          <div className="text-block">
            <div className="HT">
              <div className="a-SIMPLE">A SIMPLE &amp;</div>
              <img className="FREE-SIMPLE" alt="Free SIMPLE" src="/img/free-simple.png" />
              <div className="payment-solution-you">FOR CUSTOMERS</div>
            </div>
            <p className="p">
              It's time to redefine customers' shopping experience with a trailblazing global Web3 checkout,
              offering effortless access through any Web3 wallet, Google or email while enhancing loyalty and satisfaction.
              Be part of the Web3 payments revolution, catering to customers' convenience like never before.
            </p>
          </div>
          <img className="element-of" alt="Element of" src="/img/2020-01-04-3-of-23-1.png" />
        </div>
      </div>
      <div className="section">
        <div className="TEXT-BLOCK">
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">ONE</div>
              <div className="text-wrapper-16">ACCELERATED ACCOUNT CREATION</div>
            </div>
            <p className="text-wrapper-17">
              Elevate customers' shopping journey with Xion Pay! In just seconds, customers can set up a universal Web3 shopping account using any existing Web3 wallet, Google or Email.
              Ensuring a seamless and swift checkout process across all stores and devices using the Xion checkout. Redifining and streamlining global accessibility to Web3.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">TWO</div>
              <p className="text-wrapper-16">ONE-TIME SETUP, ENDLESS CONVENIENCE</p>
            </div>
            <p className="text-wrapper-17">
              Unlock the magic of streamlined checkout! No more redundant shipping, contact, or card inputs.
              Details are saved for every purchase, accessible across all online stores. Elevate conversions and unlock more time for what matters!
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">THREE</div>
              <div className="text-wrapper-16">INTEROPERABLE WEB3 PAYMENTS - MULTI-CHAIN TOKEN SUPPORT</div>
            </div>
            <p className="text-wrapper-17">
              Step into the Web3 revolution with confidence. Xion Pay's hosted checkout welcomes payments from an array of tokens and networks.
              From major players to hidden gems, Xion Pay is primed to cater to them all. Secure swift, hassle-free payments globally.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">FOUR</div>
              <p className="text-wrapper-16">RELIABLE & SECURE TRANSACTIONS</p>
            </div>
            <p className="text-wrapper-17">
              Witness the magic of instant settlement, all in USDT stablecoin, wired directly to your Web3 wallet. Security, stability, and clarity reign supreme!
              Wave goodbye to volatility, as 1 USDT maintains a steadfast 1 USD value. With no room for chargebacks or intermediaries, you’re the captain of your financial ship!
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">FIVE</div>
              <p className="text-wrapper-16">SMOOTH WEB 2.0 TO WEB 3.0 TRANSITION</p>
            </div>
            <p className="text-wrapper-17">
              Navigate the shift from Web 2.0 to Web 3.0 effortlessly, offering customers crypto payments as intuitive as conventional checkouts!
              Embrace the future with Xion Pay, supporting a diverse array of account creation methods.
            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">SIX</div>
              <p className="text-wrapper-16">UPSELLING MASTERY - ELEVATE CART SIZE, AMPLIFY YOUR EARNINGS!</p>
            </div>
            <p className="text-wrapper-17">
              Seize the opportunity to skyrocket profits with one click upselling Web3 payments. Strategically enhance cart size throughout the customer journey.
              Watch as casual shoppers turn into high-value customers, growing revenue beyond expectations. Embrace the upselling approach and unlock your business's full potential.
            </p>
          </div>

          <div className="TEXT-a">
            <div className="frame-3">
              <div className="text-wrapper-15">PICK YOUR PATH</div>
              <p className="text-wrapper-16">OTHER OPTIONS TO START ACCEPTING PAYMENTS</p>
            </div>
            <p className="text-wrapper-17-n">
              All of Xion's payment UIs give you instant access to over 27+ networks and 100+ tokens.
              Choose the integration that best suits your business.
            </p>
          </div>

        </div>
        <video className="frame-4" autoplay muted loop src="/img/Flow1w.webm" type="video/webm">
        </video>

        <div className="group-9">
          <video className="group-2" autoplay muted loop src="/img/Flow2w.webm" type="video/webm">
          </video>
        </div>
        <div className="overlap-wrapper">
          <video className="overlap-7" autoplay muted loop src="/img/chain_token_v2.webm" type="video/webm">
          </video>
        </div>

        <video className="frame-lock" autoplay muted loop src="/img/Flow5w.webm" type="video/webm">
        </video>

        <video className="component-4" autoplay muted loop src="/img/stablev2.webm" type="video/webm">
        </video>

        <video className="component-3" autoplay muted loop src="/img/Flow6w.webm" type="video/webm">
        </video>

        <img className="HCC" alt="hcc" src="/img/paymentlinks.png" />
        <div className="text-container-b">
          <div className="TEXT-b">
            <div className="frame-3b">
              <div className="text-wrapper-15b">CHECKOUT BUTTONS</div>
              <div className="text-wrapper-16b">Accept payments in minutes</div>
              <div className="text-wrapper-44-2">The fastest way to accept payments - no code required</div>

            </div>
            <div className="text-wrapper-17b">
              <ul className="bullet-list">
              <li>Embed checkout buttons with just 2 lines of code</li>
    <li>Tailor the look and feel of the buttons to match your brand</li>
    <li>Robust security measures, ensuring safe & reliable transactions</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="GS-button-2" onClick={handleCheckoutButtonsClick}>
          <div className="GS-button-2-text">Explore Buttons</div>
        </div>


        <img className="PL" alt="pl" src="/img/checkoutbuttons.png" />
       <div className="text-containera">
       <div className="TEXTa">
            <div className="frame-3a">
              <div className="text-wrapper-15a">PAYMENT LINKS</div>
              <div className="text-wrapper-16a">Shareable payment links</div>
              <div className="text-wrapper-44">The fastest way to accept payments - no code required </div>
            </div>
            <div className="text-wrapper-17a">
  <ul className="bullet-list">
    <li>Share links over email, SMS or any other channel</li>
    <li>Accept one-time, recurring, or pay-what-you-want payments</li>
    <li>Turn your link into a QR code or an embeddable buy button</li>
  </ul>
</div>
          </div>
          </div>

          <div className="GS-button" onClick={handlePaymentLinksClick}>
          <div className="GS-button-text">Explore Payment Links</div>
        </div>
      </div>
      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">Explore guides and integration examples in Xion </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")}
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">We’re here to help! If you have technical issues, </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://intercom.help/xionpay/en/")}
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-4" alt="Line" src="/img/desktop-line-23.svg" />
      </div>
      <FooterDesktop onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
