import React, { useEffect, useState, useCallback } from "react";
import { FooterIpad } from "../../../ipadcomponents/FooterIpadMain";
import { NavbarIpad } from "../../../ipadcomponents/NavBarIpadMain";
import "./style.css";

export const PaymentLI = () => {
  const [mediaPlayed, setMediaPlayed] = useState(false); // <-- Define mediaPlayed using useState

  // Define the onClick event handler function for opening URLs
  const handleOpenURL = useCallback((url) => {
    // Open a new tab with the specified URL
    window.open(url, "_blank");
  }, []);

  // Define the onClick event handler function
  const handleGetStartedClick = useCallback(() => {
    // Open a new tab with the specified URL
    window.open("https://xionpay.app", "_blank");
  }, []);


  const handleCheckoutButtonsClick = () => {
    // Add the logic to perform when the button is clicked
    // For example, you can use window.location.href to navigate to a URL
    window.location.href = '/checkout-buttons'; // Replace with the actual URL
  };

  const handleHostedCheckoutClick = () => {
    // Add the logic to perform when the button is clicked
    // For example, you can use window.location.href to navigate to a URL
    window.location.href = '/hosted-checkout'; // Replace with the actual URL
  };

  return (
    <div className="PaymentL-i">
      <div className="BG-elements">
        <div className="overlap-4">
          <div className="hero">
            <div className="overlap-5">
              <img className="rectangle-9" alt="Rectangle" src="/img/ipadhero.webp" />
            </div>
          </div>
          <img className="noize" alt="Noize" src="/img/noize-1.png" />
        </div>
      </div>
      <img className="nav-spacer" alt="Nav spacer" src="/img/nav-spacer.png" />
      <NavbarIpad onGetStartedClick={handleGetStartedClick} />
      <div className="main">
        <div className="overlap-6">
          <div className="text-block">
            <div className="HT">
            <div className="a-SIMPLE">CREATE A</div>
              <div className="FREE-SIMPLE" >PAYMENT LINK, AND</div>
              <div className="payment-solution-you">SELL ANYWHERE</div>
            </div>
            <p className="p">
                Xion Global's Payment Links offer a quick and efficient way to accept crypto payments without the
                need for any coding. Whether you're in a rush or prefer a no-code solution, Xion's payment links
                provide a seamless experience for both merchants and customers.
            </p>
          </div>
          <div className="GS-button-3" onClick={handleGetStartedClick}>
          <div className="GS-button-3-text">Start Now</div>
        </div>
          <img className="element-of" alt="Element of" src="/img/paymentlinksphone.png" />
        </div>
      </div>
      <div className="section">
        <div className="TEXT-BLOCK">
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-16">ACCEPT PAYMENTS IN MINUTES, WITH ZERO CODE</div>
            </div>
            <p className="text-wrapper-17">

            </p>
          </div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">PAYMENT LINK</div>
              <p className="text-wrapper-16">FEATURES</p>
            </div>
            <div>
  <p className="text-wrapper-17">
    Generate payment links directly from your dashboard.<br />
    Multi-Currency Support: Accept payments in USDT (Polygon), XGT, ETH, BTC, LTC, XRP, BCH, DASH, and USDT (Eth Mainnet).<br />
    Enjoy the security of crypto transactions without the worry of chargebacks.<br />
    Share your payment link via email, SMS, QR Code, or social media to start accepting payments immediately.
  </p>
</div>
</div>
          <img className="line-3" alt="Line" src="/line-18.png" />
          <div className="TEXT">
            <div className="frame-3">
              <div className="text-wrapper-15">PAYMENT LINK</div>
              <div className="text-wrapper-16">GETTING STARTED</div>
            </div>
  <p className="text-wrapper-17">
    Create an account with Xion Global.<br />
    Navigate to the 'Payment Links' section.<br />
    Generate your unique payment link.<br />
    Share and start accepting crypto payments!
  </p>
</div>
          <div className="TEXT-a">
            <div className="frame-3">
              <div className="text-wrapper-15">PICK YOUR PATH</div>
              <p className="text-wrapper-16">OTHER OPTIONS TO START ACCEPTING PAYMENTS</p>
            </div>
            <p className="text-wrapper-17-n">
              All of Xion's payment UIs give you instant access to over 27+ networks and 100+ tokens.
              Choose the integration that best suits your business.
            </p>
          </div>
          </div>

        <img className="frame-4" alt="frame4" src="/img/asset-73-8-1.png" />
        <img className="group-9" alt="Asset" src="/img/asset-73-8-2.png" />
        <div className="overlap-wrapper">
        <img className="overlap-7" alt="Asset" src="/img/asset-73-8-3.png" />
        </div>

        <img className="HCC" alt="hcc" src="/img/checkoutbuttons.png" />
        <div className="text-container-b">
          <div className="TEXT-b">
            <div className="frame-3b">
              <div className="text-wrapper-15b">CHECKOUT</div>
              <div className="text-wrapper-16b">Hosted checkout</div>
              <div className="text-wrapper-44-2">A pre-built checkout optimised for conversions</div>

            </div>
            <div className="text-wrapper-17b">
              <ul className="bullet-list">
                <li>Xion-hosted checkout page for payments & subscriptions</li>
                <li>Pre-built features to maximize revenue on your website</li>
                <li>Available globally, no limits</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="GS-button-2" onClick={handleHostedCheckoutClick}>
          <div className="GS-button-2-text">Explore Checkout</div>
        </div>


        <img className="PL" alt="pl" src="/img/hostedcheckout.png" />
        <div className="text-containera">
          <div className="TEXTa">
            <div className="frame-3a">
              <div className="text-wrapper-15a">CHECKOUT BUTTONS</div>
              <div className="text-wrapper-16a">Accept payments in minutes</div>
              <div className="text-wrapper-44">The fastest way to accept payments - no code required </div>
            </div>
            <div className="text-wrapper-17a">
              <ul className="bullet-list">
                <li>Embed checkout buttons with just 2 lines of code</li>
                <li>Tailor the look and feel of the buttons to match your brand</li>
                <li>Robust security measures, ensuring safe & reliable transactions</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="GS-button" onClick={handleCheckoutButtonsClick}>
          <div className="GS-button-text">Explore Buttons</div>
        </div>

      </div>
      <div className="section-2">
        <div className="TEXT-wrapper">
          <div className="TEXT-2">
            <p className="div-4">
              <span className="span">X</span>
              <span className="span">ion Docs</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">Explore guides and integration examples in Xion </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://www.apimatic.io/api-docs/xionglobal")}
              >
                documentation
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <div className="frame-7">
          <div className="TEXT-3">
            <p className="div-4">
              <span className="span">Technical</span>
              <span className="span">&nbsp;</span>
              <span className="span">and</span>
              <span className="span">&nbsp;</span>
              <span className="span">Support</span>
            </p>
            <p className="div-5">
              <span className="text-wrapper-19">We’re here to help! If you have technical issues, </span>
              <span
                className="text-wrapper-20"
                onClick={() => handleOpenURL("https://intercom.help/xionpay/en/")}
              >
                help guide
              </span>
              <span className="text-wrapper-19">.</span>
            </p>
          </div>
        </div>
        <img className="line-4" alt="Line" src="/img/line-23-ipad.svg" />
      </div>
      <FooterIpad onGetStartedClick={handleGetStartedClick} />
    </div>
  );
};
